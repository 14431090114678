import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { cloneDeep } from "lodash";

import { Typography } from "@material-ui/core";

import { refinancePingApi, submitRefinanceLeadApi } from "src/apiService";
import RateQuoteTemplate, {
  commonInputDefinitions,
} from "src/layouts/RateQuoteTemplate";
import { getProfessions } from "src/store/profileBuild/selector";

const neoLogo = "/assets/images/png/logo_neohomeloans.png";
const movementLogo = "/assets/images/png/logo_movementmortgage_alt.png";
const celebrityLogo = "/assets/images/png/logo_celebrityhomeloans.png";

const RefinanceRates = () => {
  const professions: any = useSelector(getProfessions);

  useEffect(() => {
    refinancePingApi();
  }, []);

  const inputDefinitions = cloneDeep(commonInputDefinitions);
  inputDefinitions[0].push(
    {
      label: "Estimated Home Value",
      name: "home_value",
      type: "dollars",
    },
    {
      label: "Current Mortgage Balance",
      name: "mortgage_balance",
      type: "dollars",
    },
    {
      label: "Your Estimated Credit Score",
      name: "credit_score",
      type: "number",
    },
    {
      label: "Your Profession",
      name: "profession",
      type: "select",
      values: professions.map((item: { name: string; id: string }) => ({
        label: item.name,
        value: item.id,
      })),
    }
  );

  return (
    <RateQuoteTemplate
      pageTitle="Check Your Rates &amp; Refinance Your Mortgage"
      introTitle="In 4 Easy Steps:"
      introText={
        <>
          <ol>
            <li>Verify your information.</li>
            <li>
              Select the lending partner(s) you would like refinance rates from.
            </li>
            <li>Discuss your options by phone, with a real person.</li>
            <li>Finalize your mortgage documents, and you're done!</li>
          </ol>
          <Typography variant="body2" paragraph>
            Feel free to reach out to your FitBUX Coach throughout the process
            as needed. We are here to help!
          </Typography>
        </>
      }
      mortgageDetailsInputs={inputDefinitions}
      availableLenders={[
        {
          name: "Neo Home Loans",
          id: "neo",
          logo: neoLogo,
          text: (
            <>
              <Typography variant="body2" paragraph>
                FitBUX Members who have used Neo Home Loans only have good
                things to say about their experience.
              </Typography>
              <Typography variant="body2" paragraph>
                Neo Home Loans offers first time home buyers loans, VA loans,
                medical professional loans, HELOCs, investment properties loans,
                and more.
              </Typography>
            </>
          ),
        },
        {
          name: "Movement Mortgage",
          id: "movement",
          logo: movementLogo,
          text: (
            <>
              <Typography variant="body2" paragraph>
                Want to know how good Movement is? FitBUX’s founder Joseph
                Reinke has been using them for over 20 years!
              </Typography>
              <Typography variant="body2" paragraph>
                Movement offers first time home buyers loans, VA loans, medical
                professional loans, HELOCs, investment properties loans, and
                more.
              </Typography>
            </>
          ),
        },
        {
          name: "Celebrity Home Loans",
          id: "celebrity",
          logo: celebrityLogo,
          text: (
            <>
              <Typography variant="body2" paragraph>
                Celebrity is a new partner of FitBUX. We’ve heard good things
                about them so please provide feedback if you use them.
              </Typography>
              <Typography variant="body2" paragraph>
                Celebrity offers first time home buyers loans, VA loans, medical
                professional loans, investment properties loans, and
                more.
              </Typography>
            </>
          ),
        },
      ]}
      endText={
        <>
          <Typography variant="body2" paragraph>
            Next, a loan officer from the selected lender(s) will contact you to
            set up a call to gather more details and review your offers.
          </Typography>
          <Typography variant="body2" paragraph>
            If you do not hear from them within 1 business day, please contact
            your FitBUX Coach, who will be happy to help.
          </Typography>
        </>
      }
      submitApi={submitRefinanceLeadApi}
    />
  );
};

export default RefinanceRates;
