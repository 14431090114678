import React from "react";
import { Grid } from "@material-ui/core";

import { GeneralPageData } from "src/interfaces";

import GeneralCard from "./GeneralCard";

export interface GeneralPageContentProps {
  data: GeneralPageData;
}

export const Content = ({ data }: GeneralPageContentProps) => {
  return (
    <Grid container spacing={2}>
      <Grid item xs={12} sm={6} md={4}>
        <GeneralCard title="Calculators" {...data.calculators} />
      </Grid>
      <Grid item xs={12} sm={6} md={4}>
        <GeneralCard title="Premium Add Ons" {...data.answers} />
      </Grid>
      <Grid item xs={12} sm={6} md={4}>
        <GeneralCard title="Products" {...data.products} />
      </Grid>
    </Grid>
  );
};
