import produce from "immer";
import { handleActions } from "redux-actions";

import {
  Cashflow,
  DashboardState,
  FbAction,
  ScoreRanks,
  SidebarContent,
  TransactionSummary,
  WholePlanSummary,
} from "../../interfaces";
import { FAIL, START, SUCCESS } from "../common";
import { errorReducer, setLoading } from "../common/reducers";
import * as actions from "./actions";
import {
  ADD_ACCOUNT,
  UPDATE_ACCOUNT,
  DELETE_ACCOUNT,
} from "../account/actions";
import {
  ADD_CASHFLOW_ITEM,
  EDIT_CASHFLOW_ITEM,
  REMOVE_CASHFLOW_ITEM,
} from "../cashflow/actions";
import {
  GET_SAVED_PLAN_PROJECTIONS,
  GET_LIVE_PLAN_PROJECTION,
  IMPLEMENT_PLAN,
} from "../planBuild/actions";
import {
  ADD_TRANSACTION,
  CONFIRM_TRANSACTIONS,
  UPDATE_TRANSACTION,
} from "../transaction/actions";
import { LOG_OUT } from "../system/actions";
import { HELP, TUTORIALS } from "./helpTopics";

const initialState: DashboardState = {
  activePage: "management",
  loaded: false,
  loading: false,
  loadedTransactions: false,
  loadingTransactions: false,
  loadedSummaries: false,
  loadingSummaries: false,
  loadedUnconfirmedTransactions: false,
  loadingUnconfirmedTransactions: false,
  loadedMiscState: false,
  loadingMiscState: false,
  refreshNeeded: {
    summaries: false,
    projection: false,
    score: false,
  },
  myIdrAccounts: [],
  spouseIdrAccounts: [],
  myIdrAssumptions: {
    earnings_growth: 0,
    tax_rate: 0,
    savings_rate: 0,
  },
  spouseIdrAssumptions: {
    earnings_growth: 0,
    tax_rate: 0,
    savings_rate: 0,
  },
  myIdrOverview: {
    result: {
      monthlyamount: 0,
      forgiven: 0,
      taxrate: 0,
      taxliability: 0,
      remainingterm: 0,
    },
    target: [],
    actual: [],
  },
  spouseIdrOverview: {
    result: {
      monthlyamount: 0,
      forgiven: 0,
      taxrate: 0,
      taxliability: 0,
      remainingterm: 0,
    },
    target: [],
    actual: [],
  },
  transactionError: null,
  message: "",
  messages: [],
  help: null,
  tutorial: null,
  video: null,
  popup: null,
  headerClicked: 0,
  popupActive: false,
  popupPlan: null,
  calls: "none",
  planNotifications: [],
  score: 0,
  hcScore: 0,
  scoreRanks: {
    applicant: {
      humanCapital: 0,
      riskToIncome: 0,
      education: 0,
      workExperience: 0,
      careerEnhancements: 0,
      extraCurricular: 0,
      financial: 0,
      leverage: 0,
      creditCard: 0,
      loanToValue: 0,
      emergencyFund: 0,
      discretionaryIncome: 0,
    },
    average: {
      humanCapital: 0,
      riskToIncome: 0,
      education: 0,
      workExperience: 0,
      careerEnhancements: 0,
      extraCurricular: 0,
      financial: 0,
      leverage: 0,
      creditCard: 0,
      loanToValue: 0,
      emergencyFund: 0,
      discretionaryIncome: 0,
    },
  },
  lifeInsuranceLiabilities: null,
  idealPrivateLoanRate: null,
  emergencyFundTarget: null,
  transactions: [],
  unconfirmedTransactions: [],
  marketingDeals: [],
  incomeSummary: {
    annual: [],
    monthly: [],
    current: [],
  },
  expenseSummary: {
    annual: [],
    monthly: [],
    current: [],
  },
  riskSummary: {
    annual: [],
    monthly: [],
    current: [],
  },
  studentIncomeSummary: [],
  studentExpenseSummary: [],
  studentRiskSummary: [],
  transactionSummary: null,
  wholePlanSummary: null,
  studentPlanSummary: null,
  studentEducationSummary: null,
  miscState: {},
  isSidebarEnabled: false,
  sidebarContent: null,
};

const reducerDefinitions: any = {
  [actions.SET_ACTIVE_PAGE]: (
    state: DashboardState,
    { payload }: FbAction<string>
  ) =>
    produce(state, (draft) => {
      draft.activePage = payload;
    }),
  [actions.LOAD_DASHBOARD_DATA + SUCCESS]: (
    state: DashboardState,
    { payload }: any
  ) =>
    produce(state, (draft) => {
      draft.lifeInsuranceLiabilities = payload.lifeInsuranceLiabilities;
      // draft.idealPrivateLoanRate = payload.idealLoanRate;
      draft.emergencyFundTarget = payload.emergencyFundTarget;
      draft.marketingDeals = payload.marketingDeals;
    }),
  [actions.GET_SCORE + SUCCESS]: (
    state: DashboardState,
    { payload }: FbAction<{ score: number; hc: number }>
  ) =>
    produce(state, (draft) => {
      draft.score = payload.score;
      draft.hcScore = payload.hc;
      draft.refreshNeeded.score = false;
    }),
  [actions.GET_SCORE_RANKS + SUCCESS]: (
    state: DashboardState,
    { payload }: FbAction<{ applicant: ScoreRanks; average: ScoreRanks }>
  ) =>
    produce(state, (draft) => {
      draft.scoreRanks = payload;
      if (!payload.average) {
        draft.scoreRanks.average = payload.applicant;
      }
    }),
  [actions.GET_SCORE_RANKS + FAIL]: (
    state: DashboardState,
    { payload }: FbAction<any>
  ) =>
    produce(state, (draft) => {
      draft.message = payload;
    }),
  [ADD_ACCOUNT + SUCCESS]: (state: DashboardState) =>
    produce(state, (draft) => {
      draft.refreshNeeded.score = true;
      draft.refreshNeeded.projection = true;
    }),
  [UPDATE_ACCOUNT + SUCCESS]: (state: DashboardState) =>
    produce(state, (draft) => {
      draft.refreshNeeded.score = true;
      draft.refreshNeeded.projection = true;
    }),
  [DELETE_ACCOUNT + SUCCESS]: (state: DashboardState) =>
    produce(state, (draft) => {
      draft.refreshNeeded.score = true;
      draft.refreshNeeded.projection = true;
    }),
  [actions.GET_IDR_DATA + SUCCESS]: (
    state: DashboardState,
    { payload }: FbAction<any>
  ) =>
    produce(state, (draft) => {
      draft.myIdrAccounts = payload.myAccounts.accounts || [];
      draft.myIdrAssumptions = payload.myAssumptions;
      draft.myIdrOverview = payload.myOverview;
      if (payload.spouseAssumptions) {
        draft.spouseIdrAccounts = payload.spouseAccounts.accounts || [];
        draft.spouseIdrAssumptions = payload.spouseAssumptions;
        draft.spouseIdrOverview = payload.spouseOverview;
      }
    }),
  [actions.SET_IDR_DETAILS + SUCCESS]: (
    state: DashboardState,
    { payload }: FbAction<actions.IdrDetailsPayload>
  ) =>
    produce(state, (draft) => {
      const accounts =
        payload.who === "spouse"
          ? draft.spouseIdrAccounts
          : draft.myIdrAccounts;
      accounts.forEach((account) => {
        if (payload.accountIds.indexOf(account.id) >= 0) {
          account.selected = true;
        } else {
          account.selected = false;
        }
      });
      if (payload.who === "spouse") {
        draft.spouseIdrAssumptions = payload.assumptions;
      } else {
        draft.myIdrAssumptions = payload.assumptions;
      }
    }),
  [actions.GET_PLAN_NOTIFICATIONS + SUCCESS]: (
    state: DashboardState,
    { payload }: FbAction<any[]>
  ) =>
    produce(state, (draft) => {
      draft.planNotifications = payload;
    }),
  [actions.DISMISS_PLAN_NOTIFICATION + SUCCESS]: (
    state: DashboardState,
    { payload }: FbAction<number>
  ) =>
    produce(state, (draft) => {
      draft.planNotifications = state.planNotifications.filter(
        (item: any) => item.id !== payload
      );
    }),
  [actions.DISMISS_ALL_PLAN_NOTIFICATIONS + SUCCESS]: (state: DashboardState) =>
    produce(state, (draft) => {
      draft.planNotifications = [];
    }),
  [ADD_CASHFLOW_ITEM + SUCCESS]: (
    state: DashboardState,
    { payload }: FbAction<{ data: Cashflow }>
  ) =>
    produce(state, (draft) => {
      draft.refreshNeeded.summaries = true;
      draft.refreshNeeded.score = true;
      if (payload.data.type !== "vehicle_lease") {
        return;
      }
    }),
  [EDIT_CASHFLOW_ITEM + SUCCESS]: (state: DashboardState) =>
    produce(state, (draft) => {
      draft.refreshNeeded.summaries = true;
      draft.refreshNeeded.score = true;
    }),
  [REMOVE_CASHFLOW_ITEM + SUCCESS]: (state: DashboardState) =>
    produce(state, (draft) => {
      draft.refreshNeeded.summaries = true;
      draft.refreshNeeded.score = true;
    }),
  [actions.GET_MESSAGES + SUCCESS]: (
    state: DashboardState,
    { payload }: FbAction<any[]>
  ) =>
    produce(state, (draft) => {
      draft.messages = payload;
    }),
  [actions.SEND_MESSAGE + SUCCESS]: (
    state: DashboardState,
    { payload }: FbAction<string>
  ) =>
    produce(state, (draft) => {
      draft.messages.push({
        body: payload,
        id: -999,
        direction: "user",
        date: "Now",
        new: false,
      });
    }),
  [actions.MARK_MESSAGE_READ + SUCCESS]: (
    state: DashboardState,
    { payload }: FbAction<number>
  ) =>
    produce(state, (draft) => {
      draft.messages.forEach((message) => {
        if (message.id === payload) {
          message.new = false;
        }
      });
    }),
  [actions.GET_CALLS + SUCCESS]: (
    state: DashboardState,
    { payload }: FbAction<any>
  ) =>
    produce(state, (draft) => {
      draft.calls = payload;
    }),
  [CONFIRM_TRANSACTIONS + SUCCESS]: (state: DashboardState) =>
    produce(state, (draft) => {
      draft.refreshNeeded.summaries = true;
    }),
  [ADD_TRANSACTION + SUCCESS]: (state: DashboardState) =>
    produce(state, (draft) => {
      draft.refreshNeeded.summaries = true;
    }),
  [UPDATE_TRANSACTION]: (state: DashboardState) =>
    produce(state, (draft) => {
      draft.refreshNeeded.summaries = true;
    }),
  [actions.GET_INCOME_AND_EXPENSE_SUMMARIES + SUCCESS]: (
    state: DashboardState,
    { payload }: FbAction<any>
  ) =>
    produce(state, (draft) => {
      draft.incomeSummary = payload.incomeSummary;
      draft.expenseSummary = payload.expenseSummary;
      draft.riskSummary = payload.riskSummary;
      draft.loadedSummaries = true;
      draft.refreshNeeded.summaries = false;
    }),
  [actions.GET_STUDENT_INCOME_AND_EXPENSE_SUMMARIES + SUCCESS]: (
    state: DashboardState,
    { payload }: FbAction<any>
  ) =>
    produce(state, (draft) => {
      draft.studentIncomeSummary = payload.incomeSummary;
      draft.studentExpenseSummary = payload.expenseSummary;
      draft.studentRiskSummary = payload.riskSummary;
    }),
  [actions.GET_INCOME_AND_EXPENSE_SUMMARIES + FAIL]: (state: DashboardState) =>
    produce(state, (draft) => {
      draft.refreshNeeded.summaries = false;
    }),
  [actions.LOAD_PLAN_SUMMARY + SUCCESS]: (
    state: DashboardState,
    {
      payload,
    }: FbAction<{
      plan: WholePlanSummary;
      transactions: TransactionSummary;
    }>
  ) =>
    produce(state, (draft) => {
      draft.wholePlanSummary = payload.plan;
      draft.transactionSummary = payload.transactions;
    }),
  [actions.LOAD_STUDENT_PLAN_SUMMARY + SUCCESS]: (
    state: DashboardState,
    { payload }: FbAction<any>
  ) =>
    produce(state, (draft) => {
      draft.studentPlanSummary = payload.transactionSummary;
      draft.studentEducationSummary = payload.educationSummary;
    }),
  [GET_LIVE_PLAN_PROJECTION + SUCCESS]: (state: DashboardState) =>
    produce(state, (draft) => {
      draft.refreshNeeded.projection = false;
    }),
  [GET_LIVE_PLAN_PROJECTION + FAIL]: (state: DashboardState) =>
    produce(state, (draft) => {
      draft.refreshNeeded.projection = false;
    }),
  [GET_SAVED_PLAN_PROJECTIONS + SUCCESS]: (state: DashboardState) =>
    produce(state, (draft) => {
      draft.refreshNeeded.projection = false;
    }),
  [GET_SAVED_PLAN_PROJECTIONS + FAIL]: (state: DashboardState) =>
    produce(state, (draft) => {
      draft.refreshNeeded.projection = false;
    }),
  [actions.GET_MISC_STATE + START]: (state: DashboardState) =>
    produce(state, (draft) => {
      draft.loadingMiscState = true;
    }),
  [actions.GET_MISC_STATE + SUCCESS]: (
    state: DashboardState,
    { payload }: FbAction<any>
  ) =>
    produce(state, (draft) => {
      draft.loadingMiscState = false;
      draft.loadedMiscState = true;
      draft.miscState = payload;
    }),
  [actions.GET_MISC_STATE + FAIL]: (state: DashboardState) =>
    produce(state, (draft) => {
      draft.loadingMiscState = false;
    }),
  [actions.SET_MISC_STATE + START]: (state: DashboardState) =>
    produce(state, (draft) => {
      draft.loadingMiscState = true;
    }),
  [actions.SET_MISC_STATE + SUCCESS]: (
    state: DashboardState,
    { payload }: FbAction<any>
  ) =>
    produce(state, (draft) => {
      draft.loadingMiscState = false;
      draft.miscState = payload;
    }),
  [actions.SET_MISC_STATE + FAIL]: (state: DashboardState) =>
    produce(state, (draft) => {
      draft.loadingMiscState = false;
    }),
  [actions.SET_HELP]: (state: DashboardState, { payload }: FbAction<string>) =>
    produce(state, (draft) => {
      draft.help = HELP[payload] || HELP.PLACEHOLDER;
    }),
  [actions.SET_TUTORIAL]: (
    state: DashboardState,
    { payload }: FbAction<string>
  ) =>
    produce(state, (draft) => {
      draft.tutorial = TUTORIALS[payload] || TUTORIALS.PLACEHOLDER;
    }),
  [actions.SET_VIDEO]: (state: DashboardState, { payload }: FbAction<string>) =>
    produce(state, (draft) => {
      draft.video = payload || "PLACEHOLDER";
    }),
  [actions.CLEAR_HELP]: (state: DashboardState) =>
    produce(state, (draft) => {
      draft.help = null;
    }),
  [actions.CLEAR_TUTORIAL]: (state: DashboardState) =>
    produce(state, (draft) => {
      draft.tutorial = null;
    }),
  [actions.CLEAR_VIDEO]: (state: DashboardState) =>
    produce(state, (draft) => {
      draft.video = null;
    }),
  [actions.SET_POPUP]: (state: DashboardState, { payload }: FbAction<string>) =>
    produce(state, (draft) => {
      draft.popup = payload || null;
    }),
  [actions.CLEAR_POPUP]: (state: DashboardState) =>
    produce(state, (draft) => {
      draft.popupActive = false;
    }),
  [actions.SET_POPUP_ACTIVE]: (
    state: DashboardState,
    { payload }: FbAction<boolean>
  ) =>
    produce(state, (draft) => {
      draft.popupActive = payload || false;
    }),
  [actions.SET_POPUP_PLAN]: (
    state: DashboardState,
    { payload }: FbAction<number>
  ) =>
    produce(state, (draft) => {
      draft.popupPlan = payload || null;
    }),
  [actions.SET_HEADER_CLICKED]: (state: DashboardState) =>
    produce(state, (draft) => {
      draft.headerClicked = state.headerClicked + 1;
    }),
  [actions.SET_REFRESH_NEEDED]: (
    state: DashboardState,
    {
      payload,
    }: FbAction<{
      summaries: boolean;
      projection: boolean;
      score: boolean;
    }>
  ) =>
    produce(state, (draft) => {
      draft.refreshNeeded = { ...state.refreshNeeded, ...payload };
    }),
  [actions.ENABLE_SIDEBAR]: (state: DashboardState) =>
    produce(state, (draft) => {
      draft.isSidebarEnabled = true;
    }),
  [actions.DISABLE_SIDEBAR]: (state: DashboardState) =>
    produce(state, (draft) => {
      draft.isSidebarEnabled = false;
    }),
  [actions.TOGGLE_SIDEBAR]: (state: DashboardState) =>
    produce(state, (draft) => {
      draft.isSidebarEnabled = !state.isSidebarEnabled;
    }),
  [actions.SET_SIDEBAR_CONTENT]: (
    state: DashboardState,
    { payload }: FbAction<SidebarContent[]>
  ) =>
    produce(state, (draft) => {
      draft.sidebarContent = payload;
    }),
  [IMPLEMENT_PLAN + SUCCESS]: (state: DashboardState) =>
    produce(state, (draft) => {
      draft.refreshNeeded.projection = true;
      draft.refreshNeeded.summaries = true;
      draft.refreshNeeded.score = true;
    }),
  [LOG_OUT]: () => initialState,
};

[actions.LOAD_DASHBOARD_DATA, actions.GET_SCORE].forEach((actionType) => {
  reducerDefinitions[actionType + START] = setLoading;
  reducerDefinitions[actionType + FAIL] = errorReducer;
});

const dashboardReducer = handleActions<DashboardState, any>(
  reducerDefinitions,
  initialState
);

export default dashboardReducer;
