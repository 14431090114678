import { createSelector } from "reselect";

import { AppState } from "..";
import {
  Cashflow,
  EXPENSE_TYPES,
  IncomeExpenses,
  INCOME_TYPES,
  MappedCashflow,
  PROFILE_EXPENSE_TYPES,
  RISK_MANAGEMENT_TYPES,
  WHO_TYPES,
} from "src/interfaces";
import { getHousehold } from "../profileBuild/selector";
import { getIsCurrentStudent, getIsSubscribed } from "../system/selector";

export const getCashflowState = (state: AppState) => state.cashflow;

export const getLoadedCashflow = createSelector(
  getCashflowState,
  (state) => state.loaded
);
export const getLoadingCashflow = createSelector(
  getCashflowState,
  (state) => state.loading
);
export const getRawCashflows = createSelector(
  getCashflowState,
  (state) => state.items
);

export const getCashflows = createSelector<
  AppState,
  Cashflow[],
  IncomeExpenses
>(getRawCashflows, (rawCashflows) => {
  const income: MappedCashflow[] = [];
  const expenses: MappedCashflow[] = [];
  rawCashflows.forEach((cashflow) => {
    if (cashflow.type === "vehicle_lease") {
      return true;
    }
    const mapped: MappedCashflow = {
      ...cashflow,
      monthly: cashflow.amount ? Math.round(cashflow.amount / 12) : 0,
      annual: cashflow.amount || 0,
      typeLabel: "",
    };
    if (mapped.type in PROFILE_EXPENSE_TYPES) {
      mapped.typeLabel =
        PROFILE_EXPENSE_TYPES[
          mapped.type as keyof typeof PROFILE_EXPENSE_TYPES
        ];
      expenses.push(mapped);
    } else if (INCOME_TYPES[mapped.type]) {
      mapped.typeLabel = INCOME_TYPES[mapped.type];
      mapped.whoseLabel = WHO_TYPES[mapped.whose || mapped.who || ""];
      income.push(mapped);
    }
  });
  return { income, expenses };
});

export const getMyAGI = createSelector(
  [getRawCashflows, getIsCurrentStudent],
  (cashflows, isStudent) =>
    cashflows.reduce((total: number, item: Cashflow) => {
      if (
        item.whose !== "spouse" &&
        ((isStudent && item.inschool) || (!isStudent && !item.inschool)) &&
        [
          "salary",
          "bonus",
          "hourly",
          "side_hustle",
          "commission",
          "stipend",
        ].indexOf(item.type as string) >= 0
      ) {
        return total + (item.amount || 0);
      }
      return total;
    }, 0)
);

export const getSpouseAGI = createSelector(
  [getRawCashflows, getIsCurrentStudent],
  (cashflows, isStudent) =>
    cashflows.reduce((total: number, item: Cashflow) => {
      if (
        item.whose === "spouse" &&
        ((isStudent && item.inschool) || (!isStudent && !item.inschool)) &&
        [
          "salary",
          "bonus",
          "hourly",
          "side_hustle",
          "commission",
          "stipend",
        ].indexOf(item.type as string) >= 0
      ) {
        return total + (item.amount || 0);
      }
      return total;
    }, 0)
);

export const getMyGI = createSelector(
  [getRawCashflows, getIsCurrentStudent],
  (cashflows, isStudent) =>
    cashflows.reduce((total: number, item: Cashflow) => {
      if (
        item.whose !== "spouse" &&
        ((isStudent && item.inschool) || (!isStudent && !item.inschool)) &&
        item.type in INCOME_TYPES
      ) {
        return total + (item.amount || 0);
      }
      return total;
    }, 0)
);

export const getSpouseGI = createSelector(
  [getRawCashflows, getIsCurrentStudent],
  (cashflows, isStudent) =>
    cashflows.reduce((total: number, item: Cashflow) => {
      if (
        item.whose === "spouse" &&
        ((isStudent && item.inschool) || (!isStudent && !item.inschool)) &&
        item.type in INCOME_TYPES
      ) {
        return total + (item.amount || 0);
      }
      return total;
    }, 0)
);

export const getHouseholdGI = createSelector(
  [getMyGI, getSpouseGI],
  (myIncome, spouseIncome) => myIncome + spouseIncome
);

export const getDashboardCashflows = createSelector(
  [getRawCashflows, getIsCurrentStudent, getIsSubscribed],
  (rawCashflows, isStudent, isSubscribed) => {
    let incomeOtherMine = false;
    let incomeOtherSpouse = false;
    let expenseOther = false;
    const income: MappedCashflow[] = [];
    const expenses: MappedCashflow[] = [];
    rawCashflows.forEach((cashflow) => {
      const mapped: MappedCashflow = {
        ...cashflow,
        monthly: cashflow.amount ? Math.round(cashflow.amount / 12) : 0,
        annual: cashflow.amount || 0,
        typeLabel: "",
      };
      if (mapped.type in EXPENSE_TYPES) {
        if (mapped.type === "other_expense") {
          expenseOther = true;
        }
        mapped.typeLabel =
          EXPENSE_TYPES[mapped.type as keyof typeof EXPENSE_TYPES];
        expenses.push(mapped);
      } else if (INCOME_TYPES[mapped.type]) {
        if (
          (!isStudent && cashflow.inschool) ||
          (isStudent && !cashflow.inschool)
        ) {
          return true;
        }
        if (mapped.type === "other_income") {
          if (mapped.whose === "spouse") {
            incomeOtherSpouse = true;
          } else {
            incomeOtherMine = true;
          }
        }
        mapped.typeLabel = INCOME_TYPES[mapped.type];
        mapped.whoseLabel = WHO_TYPES[mapped.whose || mapped.who || ""];
        income.push(mapped);
      }
    });
    if (isSubscribed && !incomeOtherMine) {
      income.push({
        id: -996,
        type: "other_income",
        monthly: 0,
        annual: 0,
        typeLabel: "Other",
        whoseLabel: "Mine",
        whose: "mine",
      });
    }
    if (isSubscribed && !incomeOtherSpouse) {
      income.push({
        id: -997,
        type: "other_income",
        monthly: 0,
        annual: 0,
        typeLabel: "Other",
        whoseLabel: "Spouse",
        whose: "spouse",
      });
    }
    if (isSubscribed && !expenseOther) {
      expenses.push({
        id: -998,
        type: "other_expense",
        monthly: 0,
        annual: 0,
        typeLabel: "Other",
      });
    }
    return { income, expenses };
  }
);

export const getIncomeAndExpenses = createSelector(
  [getRawCashflows, getIsCurrentStudent],
  (cashflows: Cashflow[], isStudent) => {
    let income = 0;
    let expenses = 0;
    cashflows.forEach((item: Cashflow) => {
      if (item.type in EXPENSE_TYPES) {
        expenses += item.amount || 0;
      } else if (item.type in INCOME_TYPES) {
        if ((isStudent && item.inschool) || (!isStudent && !item.inschool)) {
          income += item.amount || 0;
        }
      }
    });
    return {
      income: Math.round(income / 12),
      expenses: Math.round(expenses / 12),
    };
  }
);

export const getRiskCashflows = createSelector<
  AppState,
  Cashflow[],
  MappedCashflow[]
>([getRawCashflows], (rawCashflows) => {
  const risks: MappedCashflow[] = [];
  rawCashflows.forEach((cashflow) => {
    if (cashflow.type in RISK_MANAGEMENT_TYPES) {
      const mapped: MappedCashflow = {
        ...cashflow,
        monthly: cashflow.amount ? Math.round(cashflow.amount / 12) : 0,
        annual: cashflow.amount || 0,
        typeLabel: RISK_MANAGEMENT_TYPES[cashflow.type],
      };
      risks.push(mapped);
    }
  });
  return risks;
});

export const getRiskSummary = createSelector(
  [getRiskCashflows, getHousehold],
  (riskCashflows, household) => {
    const result: any = {
      life_insurance: "n",
      health_insurance: "n",
      health_insurance_id: 0,
      disability_insurance: "n",
      disability_insurance_id: 0,
      accidental_death: "n",
      accidental_death_id: 0,
      dental_insurance: "n",
      dental_insurance_id: 0,
      vision_insurance: "n",
      vision_insurance_id: 0,
      rental_insurance: "n",
      rental_insurance_id: 0,
      home_insurance: "n",
      home_insurance_id: 0,
      vehicle_insurance: "n",
      vehicle_insurance_id: 0,
      property_insurance: "n",
      property_insurance_id: 0,
      qual_will: household.qual_will || "n",
      qual_trust: household.qual_trust || "n",
      my_death_benefit: 0,
      spouse_death_benefit: 0,
      spouse_life_insure: "n",
      my_life_insurance_id: 0,
      spouse_life_insurance_id: 0,
      my_life_insurance_premium: 0,
      spouse_life_insurance_premium: 0,
    };
    riskCashflows.forEach((cashflow) => {
      if (cashflow.type === "life_insurance") {
        if (cashflow.whose === "spouse") {
          result.spouse_death_benefit = cashflow.death_benefit || 0;
          result.spouse_life_insure = "y";
          result.spouse_life_insurance_id = cashflow.id;
          result.spouse_life_insurance_premium = cashflow.annual;
        } else {
          result.my_death_benefit = cashflow.death_benefit || 0;
          result.life_insurance = "y";
          result.my_life_insurance_id = cashflow.id;
          result.my_life_insurance_premium = cashflow.annual;
        }
      } else if (cashflow.type in RISK_MANAGEMENT_TYPES) {
        result[cashflow.type] = "y";
        result[`${cashflow.type as string}_id`] = cashflow.id;
      }
    });
    return result;
  }
);
