import React from "react";
import { useSelector } from "react-redux";

import { Grid } from "@material-ui/core";

import DebtSummary from "../Components/DebtSummary";
import Chart from "src/components/Chart";
import {
  getDashboardDebtProjection,
  getLiveProjection,
} from "src/store/planBuild/selector";
import { getIsSubscribed } from "src/store/system/selector";
import { chartData } from "../data";

const Summary = () => {
  const subscribed = useSelector(getIsSubscribed);
  const debtProjection = useSelector(getDashboardDebtProjection);
  const liveProjection = useSelector(getLiveProjection);
  return (
    <Grid container spacing={2}>
      <Grid item xs={12} md={6}>
        <DebtSummary />
      </Grid>
      <Grid item xs={12} md={6}>
        {!subscribed && (
          <Chart
            title="Projected Debt"
            isLocked
            data={chartData}
            xKey="name"
            keys={["uv", "pv", "amt"]}
            btnLabel="Build and Implement Your Plan"
            width="100%"
          />
        )}
        {subscribed && (
          <Chart
            dollars
            title="Projected Debt"
            data={debtProjection}
            xKey="month"
            keys={["debt", "min"]}
            keyLabels={{
              min: "Plan Estimated",
              debt: "Current Projected",
            }}
            startMonth={
              liveProjection ? +liveProjection.start.slice(5) : undefined
            }
            startYear={
              liveProjection ? +liveProjection.start.slice(0, 4) : undefined
            }
            width="100%"
          />
        )}
      </Grid>
    </Grid>
  );
};

export default Summary;
