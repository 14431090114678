export interface Breakout {
  id: number;
  type: number;
  amount: number;
}

export const TYPE_LIST_ORDER = {
  "3102": 1,
  "3105": 2,
  "3101": 3,
  "3104": 4,
  "3106": 5,
  "3103": 6,
  "3108": 7,
  "3107": 8,
  "3100": 9,
  "3109": 10,
  "3000": 11,
  "3001": 12,
  "6001": 13,
  "6002": 14,
  "6000": 15,
  "2027": 16,
  "2010": 17,
  "2037": 18,
  "2036": 19,
};

export const INCOME_BREAKOUTS = {
  "1001": "Salary",
  "1002": "Hourly Income",
  "1003": "Commission",
  "1004": "Bonus",
  "1008": "Real Estate Rental Income",
  "1009": "1099/Self-Employed/Side Hustle",
  "1010": "Tax Deferred Retirement Withdrawal",
  "1011": "Other Income",
  "1100": "Stipend Income",
};

export const DEDUCTION_BREAKOUTS = {
  "12302": "Income/Medicare/SS Taxes",
  "13102": "401(k)/457/403(b) Contrib.",
  "13103": "HSA Contrib.",
  "13104": "Roth IRA Contrib.",
  "13105": "Roth 401(k) Contrib.",
  "13106": "Traditional IRA Contrib.",
  "13108": "Other Retirement Contrib.",
  "15000": "Health Insurance.",
  "15001": "Disability/Accident Ins.",
  "15002": "Life Insurance",
  "15005": "FSA Contrib.",
  "15006": "Dental Insurance",
  "15007": "Vision Insurance",
  "15008": "Accidental Death Insurance",
  "12303": "Property Tax",
  "12034": "Rental Property Mgmt",
  "12009": "HOA",
  "12033": "Rental Property Maint.",
  "12029": "Other Expenses",
};

export const DEDUCTION_BREAKOUTS_2 = {
  "2302": "Income/Medicare/SS Taxes",
  "3102": "401(k)/457/403(b) Contrib.",
  "3103": "HSA Contrib.",
  "3104": "Roth IRA Contrib.",
  "3105": "Roth 401(k) Contrib.",
  "3106": "Traditional IRA Contrib.",
  "3108": "Other Retirement Contrib.",
  "5000": "Health Insurance.",
  "5001": "Disability/Accident Ins.",
  "5002": "Life Insurance",
  "5005": "FSA Contrib.",
  "5006": "Dental Insurance",
  "5007": "Vision Insurance",
  "5008": "Accidental Death Insurance",
  "2303": "Property Tax",
  "2034": "Rental Property Mgmt",
  "2009": "HOA",
  "2033": "Rental Property Maint.",
  "2029": "Other Expenses",
};

export const BREAKOUT_TYPES = {
  ...INCOME_BREAKOUTS,
  ...DEDUCTION_BREAKOUTS,
  ...DEDUCTION_BREAKOUTS_2,
};

export const TOP_LEVEL_CATEGORIES = [
  {
    label: "Income",
    start: 0,
    end: 4,
  },
  {
    label: "Expenses",
    start: 8,
    end: 21,
  },
  {
    label: "Assets (Deposit/Withdrawal/Transfer)",
    start: 21,
    end: 22,
  },
  {
    label: "Debt (Payments)",
    start: 22,
    end: 24,
  },
  {
    label: "Risk Management",
    start: 24,
    end: 25,
  },
];

export const STUDENT_TOP_LEVEL_CATEGORIES = [
  {
    label: "Income",
    start: 0,
    end: 4,
  },
  {
    label: "Education Funding",
    start: 4,
    end: 7,
  },
  {
    label: "Education Expenses",
    start: 7,
    end: 8,
  },
  {
    label: "Expenses",
    start: 8,
    end: 21,
  },
  {
    label: "Assets (Deposit/Withdrawal/Transfer)",
    start: 21,
    end: 22,
  },
  {
    label: "Debt (Payments)",
    start: 22,
    end: 24,
  },
  {
    label: "Risk Management",
    start: 24,
    end: 25,
  },
];

export const TRANSACTION_CATEGORIES = [
  {
    label: "Income",
    types: {
      "7000": "Gross Income",
      "7001": "Take Home Pay",
    },
  },
  {
    label: "Investment Income",
    types: {
      "1006": "Interest Income",
      "1007": "Dividend Income",
    },
  },
  {
    label: "Rental Income",
    types: {
      "7002": "Real Estate Rental Income",
    },
  },
  {
    label: "Retirement Income",
    types: {
      "7003": "Tax Deferred Retirement Withdrawal",
      "1101": "Tax Exempt Retirement Withdrawal",
    },
    // 4
  },
  {
    label: "Loans",
    types: {
      "8000": "Federal Student Loan Disbursement",
      "8001": "Private Student Loan Disbursement",
      "8002": "Parent Plus Loan Disbursement",
    },
  },
  {
    label: "Grants/Gifts",
    types: {
      "8003": "Scholarship/Grant",
      "8004": "Family Aid/Gift",
      "8005": "529/Coverdell ESA",
    },
  },
  {
    label: "Other Funding",
    types: {
      "8006": "Savings/Investments for College",
      "8007": "Other",
    },
    // 7
  },
  {
    label: "Education Costs",
    types: {
      "9000": "Tuition",
      "9001": "Textbooks and Supplies",
      "9002": "Fees",
      "9003": "Other Educational Costs",
    },
    // 8
  },
  {
    label: "Child/Dependent Costs",
    types: {
      "2027": "Child Care/Day Care",
      "2010": "Child's Education",
      "2037": "Child's Extracurricular",
      "2036": "Dependent Care",
    },
  },
  {
    label: "Ongoing Learning",
    types: {
      "2002": "Books/Magazines/Newspapers",
      "2024": "Continuing Education",
    },
  },
  {
    label: "Food and Drinks",
    types: {
      "2000": "Groceries",
      "2001": "Restaurants and Bars",
    },
  },
  {
    label: "Health and Medical Services",
    types: {
      "2016": "Health and Medical Services",
    },
  },
  {
    label: "Housing",
    types: {
      "2009": "HOA",
      "2004": "Home Improvement/Maintenance",
      "2303": "Property Tax",
      "2003": "Rent/Boarding",
    },
  },
  {
    label: "Leisure",
    types: {
      "2017": "Entertainment",
      "2018": "Hobbies/Sports",
      "2020": "Online/Streaming/Media",
      "2019": "Travel",
    },
  },
  {
    label: "Pets",
    types: {
      "2022": "Pet Related",
    },
  },
  {
    label: "Self Improvement",
    types: {
      "2015": "Gym/Self Improvement",
      "2021": "Personal Care/Hygiene",
    },
  },
  {
    label: "Shopping",
    types: {
      "2012": "Clothing/Shoes",
      "2013": "Electronics",
      "2014": "General Merchandise",
    },
  },
  {
    label: "Transportation",
    types: {
      "2011": "Auto Lease",
      "2031": "Auto Service and Parts",
      "2030": "Gasoline and Fuel",
      "2032": "Taxi/Tolls/Parking/Public Transportation",
    },
  },
  {
    label: "Utilities",
    types: {
      "2008": "Cable/Satellite/Internet",
      "2005": "Electric/Gas",
      "2007": "Phone",
      "2006": "Water/Trash",
    },
  },
  {
    label: "Other Expenses",
    types: {
      "2023": "Advisory Services",
      "2025": "Charitable Giving",
      "2300": "Federal Tax",
      "2026": "Organizational Dues",
      "2029": "Other Expenses",
      "2028": "Professional Services",
      "2033": "Rental Property Maintenance",
      "2034": "Rental Property Management",
      "2301": "State Tax",
    },
  },
  {
    label: "Adjustments and Fees",
    types: {
      "2400": "Bank fee",
      "2200": "Credit/Refund",
      "2401": "Interest Charge",
      "2402": "Management Fee",
      "2201": "Reward/Cashback",
      "2403": "Transaction Fee",
    },
  },
  {
    label: "This Contribution is A(n)",
    types: {
      "3102": "401(k)/457/403(b) Contribution",
      "3105": "Roth 401(k) Contribution",
      "3101": "Retirement Contribution (Match) From Employer",
      "3104": "Roth IRA Contribution",
      "3106": "Traditional IRA Contribution",
      "3103": "Health Savings Account Contribution",
      "3108": "Other Retirement Contribution",
      "3107": "Taxable Investment Contribution",
      "3100": "Savings/Checking Contribution",
      "3109": "Other Asset Contribution",
      "3000": "Expense Reimbursement",
      "3001": "Tax Refund",
      "6001": "Transfer In",
      "6002": "Transfer Out",
      "6000": "Transfer",
    },
  },
  {
    label: "Loan Payments",
    types: {
      "4000": "Federal Loan Payment",
      "4001": "Perkins Loan Payment",
      "4002": "Private Loan Payment",
      "4003": "Credit Card Payment",
      "4004": "Auto Loan Payment",
      "4005": "Mortgage Payment",
      "4006": "Investment Mortgage Payment",
      "4007": "Personal Loan Payment",
      "4008": "Other Debt Payment",
      "4300": "Employer Loan Payment",
    },
  },
  {
    label: "Other",
    types: {
      "4200": "Disbursement",
      "4201": "Cash Advance",
      "4100": "Interest Adjustment",
    },
  },
  {
    label: "Insurance",
    types: {
      "5000": "Health Insurance",
      "5001": "Disability/Accident Insurance",
      "5002": "Life Insurance",
      "5003": "Property Insurance",
      "5004": "Other Insurance",
      "5005": "FSA Contribution",
      "5006": "Dental Insurance",
      "5007": "Vision Insurance",
      "5008": "Accidental Death Insurance",
      "5100": "Auto Insurance",
      "5101": "Home Insurance",
      "5102": "Rental Insurance",
    },
  },
];

export const TRANSACTION_ACCOUNT_TYPES = {
  "7000": ["cash_value"],
  "7001": ["cash_value"],
  "1006": [
    "cash_value",
    "other_investments_value",
    "401k_value",
    "roth_401k_value",
    "ira_value",
    "roth_ira_value",
    "other_assets_value",
    "other_retirement_value",
    "hsa_value",
  ],
  "1007": [
    "cash_value",
    "other_investments_value",
    "401k_value",
    "roth_401k_value",
    "ira_value",
    "roth_ira_value",
    "other_assets_value",
    "other_retirement_value",
    "hsa_value",
  ],
  "7002": ["cash_value"],
  "7003": [
    "cash_value",
    "401k_value",
    "ira_value",
    "other_retirement_value",
    "hsa_value",
  ],
  "8000": ["bursar"],
  "8001": ["bursar"],
  "8002": ["bursar"],
  "8003": ["bursar"],
  "8004": ["bursar", "cash_value"],
  "8005": ["bursar", "other_investments_value", "other_assets_value"],
  "8006": ["cash_value"],
  "8007": ["bursar"],
  "9000": ["bursar", "cash_value", "credit_card"],
  "9001": ["bursar", "cash_value", "credit_card"],
  "9002": ["bursar", "cash_value", "credit_card"],
  "9003": ["bursar", "cash_value", "credit_card"],
  "1101": ["cash_value", "roth_401k_value", "roth_ira_value"],
  "2027": ["cash_value", "credit_card"],
  "2002": ["cash_value", "credit_card"],
  "2024": ["cash_value", "credit_card"],
  "2010": ["cash_value", "credit_card"],
  "2037": ["cash_value", "credit_card"],
  "2036": ["cash_value", "credit_card"],
  "2000": ["cash_value", "credit_card"],
  "2001": ["cash_value", "credit_card"],
  "2016": ["cash_value", "credit_card", "hsa_value"],
  "2009": ["cash_value", "credit_card"],
  "2101": ["cash_value", "credit_card"],
  "2004": ["cash_value", "credit_card"],
  "2303": ["cash_value", "credit_card"],
  "2003": ["cash_value", "credit_card"],
  "2102": ["cash_value", "credit_card"],
  "2017": ["cash_value", "credit_card"],
  "2018": ["cash_value", "credit_card"],
  "2020": ["cash_value", "credit_card"],
  "2019": ["cash_value", "credit_card"],
  "2022": ["cash_value", "credit_card"],
  "2015": ["cash_value", "credit_card"],
  "2021": ["cash_value", "credit_card"],
  "2012": ["cash_value", "credit_card"],
  "2013": ["cash_value", "credit_card"],
  "2014": ["cash_value", "credit_card"],
  "2100": ["cash_value", "credit_card"],
  "2011": ["cash_value", "credit_card"],
  "2031": ["cash_value", "credit_card"],
  "2030": ["cash_value", "credit_card"],
  "2032": ["cash_value", "credit_card"],
  "2008": ["cash_value", "credit_card"],
  "2005": ["cash_value", "credit_card"],
  "2007": ["cash_value", "credit_card"],
  "2006": ["cash_value", "credit_card"],
  "2023": ["cash_value", "credit_card"],
  "2025": ["cash_value", "credit_card"],
  "2300": [
    "cash_value",
    "credit_card",
    "other_investments_value",
    "401k_value",
    "roth_401k_value",
    "ira_value",
    "roth_ira_value",
    "other_assets_value",
    "other_retirement_value",
  ],
  "2026": ["cash_value", "credit_card"],
  "2029": ["cash_value", "credit_card", "hsa_value"],
  "2028": ["cash_value", "credit_card"],
  "2033": ["cash_value", "credit_card"],
  "2034": ["cash_value", "credit_card"],
  "2301": [
    "cash_value",
    "credit_card",
    "other_investments_value",
    "401k_value",
    "roth_401k_value",
    "ira_value",
    "roth_ira_value",
    "other_assets_value",
    "other_retirement_value",
  ],
  "2400": [
    "cash_value",
    "other_investments_value",
    "401k_value",
    "roth_401k_value",
    "ira_value",
    "roth_ira_value",
    "other_assets_value",
    "other_retirement_value",
    "hsa_value",
    "fed_loan",
    "priv_loan",
    "perkins_loan",
    "credit_card",
    "auto_loan",
    "home_loan",
    "property_loan",
    "personal_loan",
    "other_debt",
  ],
  "2200": ["cash_value", "credit_card"],
  "2401": [
    "cash_value",
    "credit_card",
    "auto_loan",
    "home_loan",
    "property_loan",
    "personal_loan",
    "other_debt",
  ],
  "2402": [
    "cash_value",
    "credit_card",
    "other_investments_value",
    "401k_value",
    "roth_401k_value",
    "ira_value",
    "roth_ira_value",
    "other_assets_value",
    "other_retirement_value",
    "hsa_value",
  ],
  "2201": ["cash_value", "credit_card"],
  "2403": [
    "cash_value",
    "credit_card",
    "other_investments_value",
    "401k_value",
    "roth_401k_value",
    "ira_value",
    "roth_ira_value",
    "other_assets_value",
    "other_retirement_value",
    "hsa_value",
  ],
  "3100": ["cash_value"],
  "3107": ["cash_value", "other_investments_value"],
  "3102": ["cash_value", "401k_value"],
  "3105": ["cash_value", "roth_401k_value"],
  "3104": ["cash_value", "roth_ira_value"],
  "3106": ["cash_value", "ira_value"],
  "3103": ["cash_value", "hsa_value"],
  "3108": ["cash_value", "other_retirement_value"],
  "3109": ["cash_value", "other_assets_value"],
  "3101": [
    "401k_value",
    "roth_401k_value",
    "ira_value",
    "other_retirement_value",
    "hsa_value",
  ],
  "6000": [
    "cash_value",
    "other_investments_value",
    "401k_value",
    "roth_401k_value",
    "ira_value",
    "roth_ira_value",
    "other_assets_value",
    "other_retirement_value",
    "hsa_value",
    "fed_loan",
    "priv_loan",
    "perkins_loan",
    "credit_card",
    "auto_loan",
    "home_loan",
    "property_loan",
    "personal_loan",
    "other_debt",
  ],
  "6001": [
    "cash_value",
    "other_investments_value",
    "401k_value",
    "roth_401k_value",
    "ira_value",
    "roth_ira_value",
    "other_assets_value",
    "other_retirement_value",
    "hsa_value",
    "fed_loan",
    "priv_loan",
    "perkins_loan",
    "credit_card",
    "auto_loan",
    "home_loan",
    "property_loan",
    "personal_loan",
    "other_debt",
  ],
  "6002": [
    "cash_value",
    "other_investments_value",
    "401k_value",
    "roth_401k_value",
    "ira_value",
    "roth_ira_value",
    "other_assets_value",
    "other_retirement_value",
    "hsa_value",
    "fed_loan",
    "priv_loan",
    "perkins_loan",
    "credit_card",
    "auto_loan",
    "home_loan",
    "property_loan",
    "personal_loan",
    "other_debt",
  ],
  "3000": ["cash_value"],
  "3001": ["cash_value"],
  "3600": ["cash_value"],
  "3300": ["cash_value"],
  "3301": ["cash_value"],
  "3302": ["cash_value"],
  "3303": ["cash_value"],
  "3304": ["cash_value"],
  "3305": ["cash_value"],
  "3400": ["cash_value"],
  "3401": ["cash_value"],
  "3402": ["cash_value"],
  "3500": ["cash_value"],
  "3501": ["cash_value"],
  "3601": ["cash_value"],
  "3602": ["cash_value"],
  "3603": ["cash_value"],
  "3604": ["cash_value"],
  "3605": ["cash_value"],
  "4000": ["cash_value", "fed_loan"],
  "4001": ["cash_value", "perkins_loan"],
  "4002": ["cash_value", "priv_loan"],
  "4003": ["cash_value", "credit_card"],
  "4004": ["cash_value", "auto_loan"],
  "4005": ["cash_value", "home_loan"],
  "4006": ["cash_value", "property_loan"],
  "4007": ["cash_value", "personal_loan"],
  "4008": ["cash_value", "other_debt"],
  "4300": ["fed_loan", "priv_loan", "perkins_loan"],
  "4200": [
    "fed_loan",
    "priv_loan",
    "perkins_loan",
    "auto_loan",
    "home_loan",
    "property_loan",
    "personal_loan",
    "other_debt",
  ],
  "4201": ["credit_card"],
  "4100": [
    "credit_card",
    "fed_loan",
    "priv_loan",
    "perkins_loan",
    "auto_loan",
    "home_loan",
    "property_loan",
    "personal_loan",
    "other_debt",
  ],
  "5000": ["cash_value", "credit_card"],
  "5001": ["cash_value", "credit_card"],
  "5002": ["cash_value", "credit_card"],
  "5003": ["cash_value", "credit_card"],
  "5004": ["cash_value", "credit_card"],
  "5005": ["cash_value"],
  "5006": ["cash_value", "credit_card"],
  "5007": ["cash_value", "credit_card"],
  "5008": ["cash_value", "credit_card"],
  "5100": ["cash_value", "credit_card"],
  "5101": ["cash_value", "credit_card"],
  "5102": ["cash_value", "credit_card"],
};

export const TRANSACTION_TYPE_IDS = TRANSACTION_CATEGORIES.reduce(
  (result, category) => ({
    ...result,
    ...category.types,
  }),
  {}
);

// UNSUPPORTED
// "3600": "Capital Gain",
// "3300": "Buy",
// "3301": "Buy to cover",
// "3302": "Dividend Reinvestment",
// "3303": "Interest Reinvestment",
// "3304": "Capital Gain Reinvestment",
// "3305": "Rebalancing Purchase",
// "3400": "Sell",
// "3401": "Sell Short",
// "3402": "Rebalancing Sale",
// "3500": "Option Exercise",
// "3501": "Option Expire",
// "3601": "Spin Off",
// "3602": "Merger",
// "3603": "Split",
// "3604": "Stock Distribution",
// "3605": "Return of Principal",

export const getCategoryForType = (
  type: number,
  topLevelCategories?: any[]
) => {
  const categories = topLevelCategories || TOP_LEVEL_CATEGORIES;
  const categoryIndex = categories.findIndex(
    (top) =>
      TRANSACTION_CATEGORIES.slice(top.start, top.end).findIndex(
        (item) => "" + type in item.types
      ) >= 0
  );
  if (categoryIndex >= 0) {
    return categoryIndex;
  }
  return undefined;
};

export const isIncomeType = (type: number) =>
  type < 2000 || (type >= 7000 && type < 8000);
export const isIncomeTypeToConfirm = (type: number) =>
  type >= 7000 && type < 8000;
export const isExpenseType = (type: number) =>
  (type >= 2000 && type < 3000) || (type >= 12000 && type < 13000);
export const isAssetType = (type: number) =>
  (type >= 3000 && type < 4000) ||
  (type >= 6000 && type < 7000) ||
  (type >= 13000 && type < 14000);
export const isDebtType = (type: number) => type >= 4000 && type < 5000;
export const isRiskType = (type: number) =>
  (type >= 5000 && type < 6000) || (type >= 15000 && type < 16000);
export const isEducationCost = (type: number) => type >= 9000 && type < 10000;
export const isEducationFunding = (type: number) => type >= 8000 && type < 9000;
export const isBreakoutType = (type: number) => "" + type in BREAKOUT_TYPES;

export interface NewTransaction {
  account: number;
  date: string;
  amount: number;
  type: number;
  description: string;
  reverse?: boolean;
}

export interface Transaction extends NewTransaction {
  id: number;
  valid?: boolean;
  done?: boolean;
  isManual?: boolean;
}

export interface TransactionState {
  loadingTransactions: boolean;
  loadedTransactions: boolean;
  loadingUnconfirmedTransactions: boolean;
  loadedUnconfirmedTransactions: boolean;
  transactionError: any;
  transactions: Transaction[];
  unconfirmedTransactions: Transaction[];
}
