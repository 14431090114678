import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { Box, Typography } from "@material-ui/core";

import { useStyles } from "../styles";

import Button from "src/components/Button";
import { PlanViewComponent, HouseholdMinLiabilities } from "src/interfaces";
import { savePlan, updateAllocations } from "src/store/planBuild/actions";
import {
  currentPlanIncomeTotal,
  getCurrentPlan,
  getLiabilities,
} from "src/store/planBuild/selector";

import CenterContainer from "../../../Components/CenterContainer";
import PercentBreakdown from "src/components/PercentBreakdown";

export const ShortTermGoals = {
  marriage: "Save for a wedding",
  house: "Save to purchase a house",
  vehicle: "Save to purchase an investment",
  property: "Save to purchase a vehicle",
};

const PrivateLoan: PlanViewComponent = ({
  onClose,
  onSave,
  render,
  curatedIndex,
}) => {
  const dispatch = useDispatch();
  const styles = useStyles();
  const type = "priv_loan";

  const minLiabilities = useSelector(getLiabilities).min;
  const totalIncome = useSelector(currentPlanIncomeTotal);
  const plan = useSelector(getCurrentPlan);

  const liabilities = useSelector(getLiabilities);
  const annually = liabilities.min[type];
  const myMinAllocation = (annually / totalIncome) * 100;
  const [formState, setFormState] = useState<any>({
    type,
    percent: myMinAllocation,
  });

  const save = () => {
    if (formState.percent) {
      const newAllocations = {
        ...plan.allocations[0],
        [formState.type]: formState.percent,
      };
      dispatch(updateAllocations(newAllocations));
      dispatch(savePlan(null));
    }
    onSave();
  };

  // let max = 0;

  let min = 0;
  if (Object.prototype.hasOwnProperty.call(minLiabilities, formState.type)) {
    min =
      minLiabilities[formState.type as keyof HouseholdMinLiabilities];
  }
  const nextDisabled = formState.percent / 100 * totalIncome < min;

  return render({
    component: (
      <CenterContainer
        scrollable
        title={"Private Student Loan"}
        iconName="fb-scales-tripped"
      >
        <Box className="mt-5 flex items-center">
          <Typography style={{ fontSize: "13px" }}>
            What % of your income would you like to use towards this payment?
          </Typography>
        </Box>
        <Box className="mt-5">
          <PercentBreakdown
            min={min}
            income={totalIncome}
            onChange={setFormState}
            values={formState}
            label={"Private Student Loan"}
            isDebt
          />
        </Box>
        {!curatedIndex && (
          <Button
            variant="outlined"
            fbColor="primary"
            onClick={onClose}
            className={styles.btnCancel}
          >
            Cancel
          </Button>
        )}
      </CenterContainer>
    ),
    nextLabel: "Save",
    onNext: save,
    nextDisabled,
  });
};

export default PrivateLoan;
