import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { forOwn } from "lodash";

import { Grid } from "@material-ui/core";

import { tabLabels } from "../data";
import Tabs from "src/components/Tabs";
import { MappedCashflow, RISK_MANAGEMENT_TYPES } from "src/interfaces";
import BudgetCard from "../../DayToDay/Components/BudgetCard";
import Dialog from "../../DayToDay/Components/Dialog";
import ReviewTransactionsDialog from "src/pages/Dashboard/MyProfile/Transactions/Dialogs/ReviewTransactionsDialog";
import { getIncomeAndExpenseSummaries } from "src/store/dashboard/actions";
import {
  getLoadedSummaries,
  getRefreshNeeded,
  getRiskSummary,
} from "src/store/dashboard/selector";
import {
  addCashflow,
  editCashflow,
  removeCashflow,
} from "src/store/cashflow/actions";
import { getRiskCashflows } from "src/store/cashflow/selector";
import { getIsSubscribed } from "src/store/system/selector";

const Insurance = () => {
  const dispatch = useDispatch();
  const subscribed = useSelector(getIsSubscribed);
  const cashflows: MappedCashflow[] = useSelector(getRiskCashflows);
  const riskSummary = useSelector(getRiskSummary);
  const loadedSummaries = useSelector(getLoadedSummaries);
  const refreshNeeded = useSelector(getRefreshNeeded);
  const [currentTab, setCurrentTab] = useState(0);
  const [isEditing, setEditing] = useState(false);
  const [addDialogVisible, setAddDialogVisible] = useState(false);
  const [showTransactionsDialog, setShowTransactionsDialog] = useState(false);
  const [transactionQuery, setTransactionQuery] = useState({});
  const _handleTabs = (value: number) => setCurrentTab(value);

  useEffect(() => {
    if (subscribed && (!loadedSummaries || refreshNeeded.summaries)) {
      dispatch(getIncomeAndExpenseSummaries());
    }
  }, [subscribed, loadedSummaries, refreshNeeded.summaries]);

  const handleCardEditClick = (
    changes: { [stringId: string]: number },
    deletedIds: Set<number>
  ) => {
    if (isEditing) {
      forOwn(changes, (amount: number, stringId: string) => {
        const cashflow = cashflows.find(
          (item: MappedCashflow) => item.id === +stringId
        );
        if (cashflow) {
          const newAmount = currentTab === 2 ? amount : amount * 12;
          const update: any = { id: +stringId, amount: newAmount };
          dispatch(editCashflow(update));
        }
      });
      Array.from(deletedIds).forEach((id: number) =>
        dispatch(removeCashflow(id))
      );
    }
    setEditing(!isEditing);
  };

  const openAddDialog = () => setAddDialogVisible(true);
  const closeAddDialog = () => setAddDialogVisible(false);
  const addItem = (values: { type: string; annual: string; who?: string }) => {
    const cashflow: any = { type: values.type, amount: +values.annual || 0 };
    if (
      values.type === "life_insurance" ||
      values.type === "disability_insurance"
    ) {
      if (values?.who === "spouse") {
        cashflow.who = "spouse";
      }
    }
    dispatch(addCashflow({ cashflow }));
    closeAddDialog();
  };

  const availableTypeList: Array<{ label: string; value: string }> = [];
  forOwn(RISK_MANAGEMENT_TYPES, (label, cashflowType) => {
    availableTypeList.push({
      label,
      value: cashflowType,
    });
  });

  const getActual = () => {
    if (currentTab === 0) {
      return riskSummary.current;
    } else if (currentTab === 1) {
      return riskSummary.monthly;
    }
    return riskSummary.annual;
  };

  const openTransactionsDialog = (key: string) => {
    const timeframes = ["current", "monthly", "annual"];
    const timeframe = timeframes[currentTab];
    const query: any = {
      category: "risk",
      period: timeframe,
      type: key,
    };
    setShowTransactionsDialog(true);
    setTransactionQuery(query);
  };

  const closeTransactionsDialog = () => {
    setShowTransactionsDialog(false);
    setTransactionQuery({});
  };

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Tabs
          disabled={!subscribed}
          tooltip={
            !subscribed ? (
              <div style={{ fontSize: 14, lineHeight: "16px", padding: 2 }}>
                Build and implement a plan to see your cashflow history.
              </div>
            ) : (
              ""
            )
          }
          list={tabLabels}
          value={currentTab}
          _handleChange={_handleTabs}
        />
      </Grid>
      <Grid item xs={12}>
        <BudgetCard
          title="Insurance Premiums"
          budget={cashflows}
          actual={subscribed ? getActual() : undefined}
          onSave={handleCardEditClick}
          onAdd={openAddDialog}
          addLabel="Add Premium"
          editing={isEditing}
          openTransactionsDialog={
            subscribed ? openTransactionsDialog : undefined
          }
          annual={currentTab === 2}
        />
      </Grid>
      <Dialog
        title="Insurance"
        list={availableTypeList}
        open={addDialogVisible}
        onAdd={addItem}
        onClose={closeAddDialog}
        showOwner
      />
      <ReviewTransactionsDialog
        onClose={closeTransactionsDialog}
        open={showTransactionsDialog}
        query={transactionQuery}
      />
    </Grid>
  );
};

export default Insurance;
