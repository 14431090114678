import { Message } from "./message.interface";
import { Transaction } from "./transaction.interface";

export interface DashboardMessage {
  id: number;
  message: string;
  action?: string;
  actionLabel?: string;
  title: string;
  complete?: boolean;
}

export interface InsuranceLiabilities {
  household: { [field: string]: number };
  applic: { [field: string]: number };
  spouse: { [field: string]: number };
}

export interface HelpItem {
  topic: string;
  content: string[];
  image?: string;
  tutorialKey?: string;
  videoKey?: string;
  extraVideoKeys?: string[];
}

export interface TutorialItem {
  topic?: string;
  content?: string[];
  images?: string[];
  nImages?: number;
  name: string;
}

export const SCORE_CATEGORIES = {
  humanCapital: "Human Capital",
  riskToIncome: "Risk to Income",
  education: "Education",
  workExperience: "Work Experience",
  careerEnhancements: "Career Enhancements",
  extraCurricular: "Extracurriculars",
  financial: "Financial",
  leverage: "Leverage",
  creditCard: "Credit Card",
  loanToValue: "Loan to Value",
  emergencyFund: "Emergency Fund",
  discretionaryIncome: "Discretionary Income",
};

export const HC_SCORE_CATEGORIES = [
  "humanCapital",
  "riskToIncome",
  "education",
  "workExperience",
  "careerEnhancements",
  "extraCurricular",
];

export const FINANCIAL_SCORE_CATEGORIES = [
  "financial",
  "leverage",
  "creditCard",
  "loanToValue",
  "emergencyFund",
  "discretionaryIncome",
];

export interface ScoreRanks {
  humanCapital: number;
  riskToIncome: number;
  education: number;
  workExperience: number;
  careerEnhancements: number;
  extraCurricular: number;
  financial: number;
  leverage: number;
  creditCard: number;
  loanToValue: number;
  emergencyFund: number;
  discretionaryIncome: number;
}

export interface TypeAmount {
  type: string;
  amount: number;
}

export interface CashflowSummary {
  annual: TypeAmount[];
  monthly: TypeAmount[];
  current: TypeAmount[];
}

export interface CashflowYearSummary {
  year: number;
  summary: TypeAmount[];
}

export interface EducationYearSummary {
  year: number;
  cost: TypeAmount[];
}

export interface AllocationSummary {
  year?: number;
  income: number;
  asset: TypeAmount[];
  debt: TypeAmount[];
  risk: TypeAmount[];
  cost?: TypeAmount[];
}

export interface TransactionSummary {
  actual: {
    current: AllocationSummary;
    month: AllocationSummary;
    annual: AllocationSummary;
  };
  plan: {
    current: TypeAmount[];
    month: TypeAmount[];
    annual: TypeAmount[];
  };
}

export interface WholePlanSummary {
  date: {
    start: string;
    end: string;
  };
  actual: {
    whole: AllocationSummary;
  };
  plan: {
    whole: {
      plan: TypeAmount[];
    };
  };
}

export interface StudentPlanSummary {
  actual: AllocationSummary[];
}

export interface IDRData {
  result: {
    monthlyamount: number;
    forgiven: number;
    taxrate: number;
    taxliability: number;
    remainingterm: number;
  };
  target: Array<[string, number]>;
  actual: Array<[string, number]>;
}

export interface SidebarContent {
  header: string;
  body: (string | React.ReactNode)[];
  videoKey?: string;
  lists?: Array<{
    title: string;
    list: Array<string>;
  }>;
}

export interface DashboardState {
  activePage: string;
  loading: boolean;
  loaded: boolean;
  refreshNeeded: {
    summaries: boolean;
    projection: boolean;
    score: boolean;
  };
  help: HelpItem | null;
  tutorial: TutorialItem | null;
  video: string | null;
  popup: string | null;
  popupActive: boolean;
  popupPlan: number | null;
  headerClicked: number;
  loadingSummaries: boolean;
  loadedSummaries: boolean;
  loadingTransactions: boolean;
  loadedTransactions: boolean;
  loadingUnconfirmedTransactions: boolean;
  loadedUnconfirmedTransactions: boolean;
  loadingMiscState: boolean;
  loadedMiscState: boolean;
  myIdrAccounts: any[];
  spouseIdrAccounts: any[];
  myIdrAssumptions: {
    earnings_growth: number;
    tax_rate: number;
    savings_rate: number;
  };
  spouseIdrAssumptions: {
    earnings_growth: number;
    tax_rate: number;
    savings_rate: number;
  };
  myIdrOverview: IDRData;
  spouseIdrOverview: IDRData;
  transactionError: any;
  calls: "none" | "scheduled" | "held";
  planNotifications: any;
  message: string;
  messages: Message[];
  score: number;
  hcScore: number;
  scoreRanks: {
    applicant: ScoreRanks;
    average: ScoreRanks;
  };
  transactions: Transaction[];
  unconfirmedTransactions: Transaction[];
  lifeInsuranceLiabilities: InsuranceLiabilities | null;
  emergencyFundTarget: any;
  idealPrivateLoanRate: any;
  marketingDeals: any;
  studentIncomeSummary: CashflowYearSummary[];
  studentExpenseSummary: CashflowYearSummary[];
  studentRiskSummary: CashflowYearSummary[];
  incomeSummary: CashflowSummary;
  expenseSummary: CashflowSummary;
  riskSummary: CashflowSummary;
  transactionSummary: TransactionSummary | null;
  wholePlanSummary: WholePlanSummary | null;
  studentEducationSummary: EducationYearSummary[] | null;
  studentPlanSummary: StudentPlanSummary | null;
  miscState: any;
  isSidebarEnabled: boolean;
  sidebarContent: SidebarContent[] | null;
}
