import { all, call, put, takeEvery, takeLatest } from "redux-saga/effects";
import {
  deleteCashflowApi,
  addCashflowApi,
  getCashflowDetailApi,
  getCashflowApi,
  updateCashflowApi,
} from "src/apiService/modules/cashflow";
import { Cashflow, FbAction } from "src/interfaces";

import { FAIL, START, SUCCESS } from "../common";
import * as actions from "./actions";

function* fetchCashflowItems() {
  try {
    const data: Cashflow[] = yield call(getCashflowApi);
    for (let i = 0; i < data.length; i++) {
      const item = data[i];
      if (item.type === "life_insurance") {
        const itemDetail: Cashflow = yield call(getCashflowDetailApi, item.id);
        Object.assign(item, itemDetail);
      }
    }
    yield put({ type: actions.FETCH_CASHFLOW_ITEMS + SUCCESS, payload: data });
  } catch (error) {
    yield put({ type: actions.FETCH_CASHFLOW_ITEMS + FAIL, payload: error });
  }
}

function* addCashflowItem({
  payload,
}: FbAction<actions.AddCashflowItemPayload>) {
  try {
    const data: Cashflow = yield call<any>(addCashflowApi, payload.cashflow);
    yield put({
      type: actions.ADD_CASHFLOW_ITEM + SUCCESS,
      payload: { data, originalId: payload.temporaryId || 0 },
    });
  } catch (error) {
    yield put({ type: actions.ADD_CASHFLOW_ITEM + FAIL, payload: error });
  }
}

function* editCashflowItem({
  payload,
}: FbAction<actions.EditCashflowItemPayload>) {
  try {
    yield call(updateCashflowApi, payload);
    yield put({ type: actions.EDIT_CASHFLOW_ITEM + SUCCESS, payload });
  } catch (error) {
    yield put({ type: actions.EDIT_CASHFLOW_ITEM + FAIL, payload: error });
  }
}

function* replaceCashflowItem({
  payload,
}: FbAction<actions.ReplaceCashflowItemPayload>) {
  yield all([
    call<any>(addCashflowItem, {
      payload: { temporaryId: payload.id, cashflow: payload.newItem },
    }),
    call<any>(editCashflowItem, { payload: { id: payload.id, amount: 0 } }),
  ]);
}

function* removeCashflowItem({ payload }: FbAction<number>) {
  try {
    const success: boolean = yield call(deleteCashflowApi, payload);
    if (success) {
      yield put({ type: actions.REMOVE_CASHFLOW_ITEM + SUCCESS, payload });
    } else {
      throw new Error("Failed to delete cashflow.");
    }
  } catch (error) {
    yield put({ type: actions.REMOVE_CASHFLOW_ITEM + FAIL, payload: error });
  }
}
export function* cashflowSagas() {
  yield all([
    takeLatest(actions.FETCH_CASHFLOW_ITEMS + START, fetchCashflowItems),
    takeEvery(actions.ADD_CASHFLOW_ITEM + START, addCashflowItem),
    takeEvery(actions.EDIT_CASHFLOW_ITEM + START, editCashflowItem),
    takeEvery(actions.REPLACE_CASHFLOW_ITEM + START, replaceCashflowItem),
    takeEvery(actions.REMOVE_CASHFLOW_ITEM + START, removeCashflowItem),
  ]);
}
