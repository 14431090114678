import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import {
  Box,
  // makeStyles,
} from "@material-ui/core";

// import { profileBuilderStyles } from "src/theme";
import { formatAnnually, formatMonthly, formatPercent } from "src/utils";

import {
  addEarning,
  addExpense,
  editEarning,
  editExpense,
  estimateCurrentPlanTaxes,
  removeEarning,
  removeExpense,
  savePlanAndCashflows,
} from "src/store/planBuild/actions";
import { PLAN_BUILD_STEPS } from "src/store/planBuild/constants";
import {
  currentPlanExpenseTotal,
  currentPlanIncomeTotal,
  getCurrentPlanCashflows,
} from "src/store/planBuild/selector";
import { getIsMarried } from "src/store/system/selector";
import {
  PLAN_INCOME_TYPES,
  PLAN_EXPENSE_TYPES,
  IncomeExpenses,
  MappedCashflow,
  Earning,
  Expense,
  PlanViewComponent,
} from "src/interfaces";
import BasicCard from "src/components/BasicCard";
import { expenseBlowout, incomeBlowout } from "./blowoutContent";
import CenterContainer from "../../Components/CenterContainer";

// const useStyles = makeStyles(profileBuilderStyles);
const expenseColumns = [
  {
    label: "Type",
    field: "type",
    type: "select",
    width: "40%",
    items: PLAN_EXPENSE_TYPES,
  },
  {
    label: "% of Total Income",
    field: "percent",
    type: "percent",
    width: "20%",
    formatter: formatPercent,
  },
  {
    label: "Monthly",
    field: "monthly",
    type: "number",
    width: "20%",
    formatter: formatMonthly,
  },
  {
    label: "Annual",
    field: "annual",
    type: "number",
    width: "20%",
    formatter: formatAnnually,
  },
];

const MainForm: PlanViewComponent = ({ render }) => {
  const dispatch = useDispatch();
  const isMarried = useSelector(getIsMarried);
  const { income, expenses, tax }: IncomeExpenses = useSelector(
    getCurrentPlanCashflows
  );
  const incomeTotal = useSelector(currentPlanIncomeTotal);
  const expenseTotal = useSelector(currentPlanExpenseTotal);
  const [newExpense, setNewExpense] = useState<any>(null);
  const [newIncome, setNewIncome] = useState<any>(null);

  const saveItem = (
    item: MappedCashflow,
    updateFields: Partial<MappedCashflow>,
    index: number,
    isExpense: boolean
  ) => {
    const listToUpdate = isExpense ? [tax, ...expenses] : income;
    const isNew = index === listToUpdate.length;
    let action;
    let update: Expense | Earning;
    if (isExpense) {
      update = {
        payment: Math.abs(updateFields.annual || item.annual || 0),
        type: updateFields.type || item.type,
      };
      if (isNew) {
        setNewExpense(null);
        if (!update.type) {
          return;
        }
        action = addExpense(update);
      } else {
        action = editExpense({ index: index - 1, update });
      }
    } else {
      update = {
        earning: Math.abs(updateFields.annual || item.annual || 0),
        type: updateFields.type || item.type,
        who: updateFields.who || item.who || "applicant",
      };
      if (isNew) {
        setNewIncome(null);
        if (!update.type) {
          return;
        }
        action = addEarning(update);
      } else {
        action = editEarning({ index, update });
      }
    }
    dispatch(action);
    if (!isExpense) {
      dispatch(estimateCurrentPlanTaxes());
    }
    dispatch(savePlanAndCashflows(null));
  };

  const saveEarning = (
    item: MappedCashflow,
    updateFields: Partial<MappedCashflow>,
    index: number
  ) => saveItem(item, updateFields, index, false);

  const saveExpense = (
    item: MappedCashflow,
    updateFields: Partial<MappedCashflow>,
    index: number
  ) => saveItem(item, updateFields, index, true);

  const onNext = () =>
    dispatch(savePlanAndCashflows(PLAN_BUILD_STEPS.ASSETS_AND_DEBTS));

  const defaultNewExpense = {
    payment: "",
    type: "",
  };
  const defaultNewIncome = {
    earning: "",
    type: "",
    who: "applicant",
  };
  const addNewExpense = () => setNewExpense(defaultNewExpense);
  const addNewIncome = () => setNewIncome(defaultNewIncome);

  const removeEarningItem = (item: Earning | Expense, index: number) =>
    removeItem(item, index, false);

  const removeExpenseItem = (item: Earning | Expense, index: number) =>
    removeItem(item, index, true);

  const removeItem = (
    item: Earning | Expense,
    index: number,
    isExpense: boolean
  ) => {
    if (isExpense && index === expenses.length + 1) {
      setNewExpense(null);
    } else if (!isExpense && index === income.length) {
      setNewIncome(null);
    } else {
      dispatch(isExpense ? removeExpense(index - 1) : removeEarning(index));
      if (!isExpense) {
        dispatch(estimateCurrentPlanTaxes());
      }
      dispatch(savePlanAndCashflows(null));
    }
  };

  const incomeColumns = [
    {
      label: "Type",
      field: "type",
      type: "select",
      width: "60%",
      items: PLAN_INCOME_TYPES,
    },
    {
      label: "Monthly",
      field: "monthly",
      type: "number",
      width: "20%",
      formatter: formatMonthly,
    },
    {
      label: "Annual",
      field: "annual",
      type: "number",
      width: "20%",
      formatter: formatAnnually,
    },
  ];
  if (isMarried) {
    incomeColumns.push({
      label: "Owner",
      field: "who",
      type: "select",
      width: "30%",
      items: {
        applicant: "Mine",
        spouse: "Spouse",
      },
    });
    incomeColumns[0].width = "30%";
  }
  const showIncome: any[] = [...income];
  if (newIncome) {
    showIncome.push(newIncome);
  }
  const showExpense: any[] = [tax, ...expenses];
  if (newExpense) {
    showExpense.push(newExpense);
  }

  return render({
    component: (
      <CenterContainer title="Day-To-Day Money" scrollable iconName="fb-wallet">
        <Box className="mt-5">
          <BasicCard
            help={incomeBlowout}
            iconName="fb-money"
            title="Income"
            // secondaryStyle
            additionalText="Enter your income."
            columns={incomeColumns}
            data={showIncome}
            allowSelect
            defaultNewItem={defaultNewIncome}
            onAdd={addNewIncome}
            onCancelEdit={() => setNewIncome(null)}
            onDelete={removeEarningItem}
            onSave={saveEarning}
            total={incomeTotal}
            summaryRow={
              !showIncome.length
                ? undefined
                : {
                    type: "Total",
                    annual: incomeTotal,
                    monthly: Math.round(incomeTotal / 12),
                  }
            }
          />
          <BasicCard
            help={expenseBlowout}
            iconName="fb-wallet"
            title="Day-To-Day Expenses"
            additionalText="Enter your day-to-day expenses."
            columns={expenseColumns}
            data={showExpense}
            allowSelect
            defaultNewItem={defaultNewExpense}
            fixedRows={[0]}
            // secondaryStyle
            onAdd={addNewExpense}
            onCancelEdit={() => setNewExpense(null)}
            onDelete={removeExpenseItem}
            onSave={saveExpense}
            total={incomeTotal}
            summaryRow={
              !showIncome.length
                ? undefined
                : {
                    type: "Total",
                    annual: expenseTotal,
                    monthly: Math.round(expenseTotal / 12),
                    percent: (expenseTotal / incomeTotal) * 100,
                  }
            }
          />
        </Box>
      </CenterContainer>
    ),
    onNext,
    nextLabel: "Next Section",
  });
};

export default MainForm;
