import React from "react";
import { Box, Typography, Grid } from "@material-ui/core";

import { AdapterLink } from "src/utils";
import Button from "src/components/Button";

interface CompareProps {
  close: VoidFunction;
  transitionPopup: VoidFunction;
}

const ComparePopup = ({ close, transitionPopup }: CompareProps) => {
  return (
    <>
      <Grid item>
        <Box>
          <Typography variant="h1">Implement Your Plan</Typography>
        </Box>
      </Grid>
      <br />
      <Grid item>
        <Box>
          <Typography variant="h6">
            Would you like to implement one of the plans you built and begin
            tracking your financial plan or close this comparison screen?
          </Typography>
        </Box>
      </Grid>
      <br />
      <Grid item>
        <Grid container spacing={10}>
          <Grid item>
            <Button
              // className={styles.actionButton}
              fbColor="primary"
              onClick={() => {
                transitionPopup();
              }}
            >
              Yes, I Want To Implement My Plan
            </Button>
          </Grid>

          <Grid item>
            <Button
              component={AdapterLink}
              to="/plan-list"
              fbColor="primary"
              onClick={() => {
                close();
              }}
            >
              Not At This Time
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};

export default ComparePopup;
