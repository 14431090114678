import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { push } from "connected-react-router";

import Button from "src/components/Button";
import NewOrRefinanceInterstitial from "src/pages/Dashboard/ToolsAndProducts/HomeBuying/NewOrRefinanceInterstitial";
import StickyFooter from "src/components/StickyFooter";
import CalculatorLayout from "src/layouts/CalculatorLayout";

import { POINTS_CALCULATOR_DISCLAIMER, STEP } from "./CONSTANTS";
import MortgageDetailsScreen from "./MortgageDetailsScreen";
import PointsCalculatorIntroScreen from "./PointsCalculatorIntroScreen";
import PointsInterestRateDetailsScreen from "./PointsInterestRateDetailsScreen";
import ResultScreen from "./ResultScreen";
import { usePointsCalculator } from "./UsePointsCalculator";

const Content = () => {
  const dispatch = useDispatch();
  const { activeStep } = usePointsCalculator();
  const [showInterstitial, setShowInterstitial] = useState(false);
  const handleBack = () => {
    dispatch(push("/homebuying"));
  };

  const renderStep = () => {
    switch (activeStep) {
      case STEP.INTRO_SCREEN:
        return <PointsCalculatorIntroScreen />;
      case STEP.MORTGAGE_DETAILS:
        return <MortgageDetailsScreen />;
      case STEP.POINTS_INTEREST_RATE_DETAILS:
        return <PointsInterestRateDetailsScreen />;
      case STEP.RESULT_SCREEN:
        return <ResultScreen />;
      default:
        return <PointsCalculatorIntroScreen />;
    }
  };

  return (
    <CalculatorLayout
      title="Mortgage Points Calculator"
      onBackClick={handleBack}
      disclaimer={POINTS_CALCULATOR_DISCLAIMER}
      footer={
        activeStep === STEP.RESULT_SCREEN ? (
          <StickyFooter text="Check purchase or refinance rates with FitBUX's mortgage lending partners.">
            <Button
              className="mr-10"
              fbColor="accent"
              onClick={() => setShowInterstitial(true)}
            >
              Check My Rates
            </Button>
          </StickyFooter>
        ) : null
      }
    >
      {renderStep()}
      <NewOrRefinanceInterstitial
        open={showInterstitial}
        onClose={() => setShowInterstitial(false)}
      />
    </CalculatorLayout>
  );
};

export default Content;
