import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import UpgradeDialog from "src/components/Upgrade";
import { getPopupPlan } from "src/store/dashboard/selector";
import { implementPlan } from "src/store/planBuild/actions";
import { getIsSubscribed } from "src/store/system/selector";
// import { setTutorial, setVideo } from "src/store/dashboard/actions";

interface CheckoutPopupProps {
  close: (target: string) => void;
}

const CheckoutPopup = ({ close }: CheckoutPopupProps) => {
  const dispatch = useDispatch();
  const isSubscribed = useSelector(getIsSubscribed);
  const popupPlan: number | null = useSelector(getPopupPlan);
  const [open, setOpen] = useState(true);

  const onClose = () => {
    setOpen(false);
    if (popupPlan) {
      dispatch(implementPlan(popupPlan));
      // dispatch(setTutorial("post_subscription"));
      // dispatch(setVideo("post_subscription"));
      close(isSubscribed ? "dashboard" : "");
    }
  };

  return <UpgradeDialog forceOpen={open} onClose={onClose} />;
};

export default CheckoutPopup;
