import { forOwn } from "lodash";

import {
  Cashflow,
  Earning,
  Expense,
  EXPENSE_TYPES,
  RISK_MANAGEMENT_TYPES,
} from "src/interfaces";
import { addCashflow, editCashflow, removeCashflow } from "../cashflow/actions";

const matchWho = (value1: string, value2: string) => {
  const spouse1 = value1 === "spouse";
  const spouse2 = value2 === "spouse";
  return (spouse1 && spouse2) || (!spouse1 && !spouse2);
};

export const makeCashflowUpdates = (
  incomes: Earning[],
  expenses: Expense[],
  risks: Expense[],
  cashflows: Cashflow[],
) => {
  const toEdit: any[] = [];
  const toRemove: any[] = [];
  const toAdd: any[] = [];
  const debugOverview: any = {
    add: [],
    remove: [],
    edit: [],
  };
  const matchedIncomes: Set<number> = new Set([]);
  const matchedExpenses: Set<number> = new Set([]);
  const matchedRisks: Set<number> = new Set([]);
  cashflows.forEach((cashflow) => {
    let match: unknown;
    const isRisk = !!RISK_MANAGEMENT_TYPES[cashflow.type];
    const isExpense = !!EXPENSE_TYPES[cashflow.type];
    if (isRisk) {
      match = risks.find((risk, index) => {
        if (!matchedRisks.has(index) && risk.type === cashflow.type) {
          matchedRisks.add(index);
          return true;
        }
        return false;
      });
    } else if (isExpense) {
      match = expenses.find((expense, index) => {
        if (!matchedExpenses.has(index) && expense.type === cashflow.type) {
          matchedExpenses.add(index);
          return true;
        }
        return false;
      });
    } else {
      match = incomes.find((income, index) => {
        if (
          !matchedIncomes.has(index) &&
          matchWho(income.who as string, cashflow.whose as string) &&
          (income.type === cashflow.type ||
            (income.type === "other" && cashflow.type === "other_income") ||
            (income.type === "rent" && cashflow.type === "rental_income"))
        ) {
          matchedIncomes.add(index);
          return true;
        }
        return false;
      });
    }
    if (!match) {
      debugOverview.remove.push(cashflow);
      toRemove.push(removeCashflow(cashflow.id));
    } else {
      const newAmount =
        isExpense || isRisk
          ? (match as Expense).payment
          : (match as Earning).earning;
      if (newAmount !== cashflow.amount) {
        debugOverview.edit.push(cashflow);
        toEdit.push(editCashflow({ id: cashflow.id, amount: newAmount }));
      }
    }
  });
  expenses.forEach((expense, index) => {
    if (!matchedExpenses.has(index)) {
      debugOverview.add.push(expense);
      toAdd.push(
        addCashflow({
          cashflow: {
            type: expense.type === "other" ? "other_expense" : expense.type,
            amount: expense.payment,
          },
        })
      );
    }
  });
  risks.forEach((risk, index) => {
    if (!matchedRisks.has(index)) {
      debugOverview.add.push(risk);
      toAdd.push(
        addCashflow({
          cashflow: {
            type: risk.type,
            amount: risk.payment,
          },
        })
      );
    }
  });
  incomes.forEach((income, index) => {
    if (!matchedIncomes.has(index)) {
      debugOverview.add.push(income);
      let newType = income.type;
      if (newType === "other") {
        newType = "other_income";
      } else if (newType === "rent") {
        newType = "rental_income";
      }
      toAdd.push(
        addCashflow({
          cashflow: {
            type: newType,
            amount: income.earning,
            who: (income.who as string) || "applicant",
          },
        })
      );
    }
  });
  return [...toAdd, ...toEdit, ...toRemove];
};

export const fillInMissingFunding = (summary: any) => {
  const yearlyRemainingValues = summary.remaining;
  const output: any = {
    addLoans: false,
    years: {},
  };
  let runningTotal = 0;
  forOwn(yearlyRemainingValues, (value: number, year: string) => {
    if (value < runningTotal) {
      output.addLoans = true;
      const newLoanAmount = value - runningTotal;
      runningTotal += newLoanAmount;
      output.years[year] = newLoanAmount;
    }
  });
  return output;
};
