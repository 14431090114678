import React from "react";

import { Box, Container, makeStyles, Typography } from "@material-ui/core";

import { AdapterLink } from "src/utils";

const useStyles = makeStyles({
  img: {
    maxHeight: 250,
    maxWidth: 250,
    "& img": {
      width: "100%",
    },
  },
});

const LandingPage = () => {
  const classes = useStyles();

  return (
    <Container className="py-48 flex justify-around">
      <Box className="w-2/3">
        <Typography variant="h2" component="h2" className="mb-3">
          Compare Plans
        </Typography>
        <Typography component="p" variant="body1">
          Navigate to <AdapterLink to="/build-plan">Build My Plan</AdapterLink>{" "}
          to build at least 2 plans to be able to compare them.
        </Typography>
      </Box>
      <Box className={classes.img}>
        <img src="/assets/images/png/map.png" alt="rocket" />
      </Box>
    </Container>
  );
};

export default LandingPage;
