import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { Box, Card, Link, makeStyles, Typography } from "@material-ui/core";
import {
  currentPlanAllocationTotals,
  currentPlanExpenseTotal,
  currentPlanIncomeTotal,
  currentPlanRiskManagementTotal,
  getCurrentPlanCashflows,
  getLiabilities,
} from "src/store/planBuild/selector";

import { savePlan, savePlanAndCashflows } from "src/store/planBuild/actions";
import { PLAN_BUILD_STEPS } from "src/store/planBuild/constants";
import { PlanViewComponent } from "src/interfaces";
import { notifications, RECOMMENDATION_CATEGORY } from "./notifcations";
import CenterContainer from "../../Components/CenterContainer";
import HelpToggle from "src/components/HelpToggle";

const useStyle = makeStyles({
  messageContainer: {
    // height: "80px",
    display: "flex",
    alignItems: "center",
    borderRadius: "15px",
    padding: "10px 15px",
    margin: "10px 0px",
  },
  message: {
    width: "75%",
  },
  link: {
    width: "25%",
    display: "flex",
    justifyContent: "center",
  },
});

const Recommendations: PlanViewComponent = ({ render }) => {
  const dispatch = useDispatch();
  const classes = useStyle();
  const [recommendations, setRecommendations] = useState<any[]>([""]);
  const [disableNext, setDisableNext] = useState(false);

  const totalIncome = useSelector(currentPlanIncomeTotal);
  const expenses = useSelector(currentPlanExpenseTotal);
  const { assetsTotal, debtsTotal } = useSelector(currentPlanAllocationTotals);
  const risks = useSelector(currentPlanRiskManagementTotal);
  const liabilities = useSelector(getLiabilities).min;
  const cashAfterExpenses = totalIncome - expenses;
  const remainingMoney = cashAfterExpenses - (assetsTotal + debtsTotal + risks);

  const { income } = useSelector(getCurrentPlanCashflows);

  useEffect(() => {
    // TODO: Once we are ready to enable notifications, change the below const to a let
    const userRecommendations: any[] = [];
    if (remainingMoney < 0) {
      userRecommendations.push(
        notifications[RECOMMENDATION_CATEGORY.VACATION_FUN_MONEY]
      );
      setDisableNext(true);
    } else {
      // TODO: hide recommendations- comment out below line to hide notifications
      // userRecommendations = getNotifications();
      setDisableNext(false);
    }
    setRecommendations(userRecommendations);
  }, [income, expenses, assetsTotal, debtsTotal, risks, liabilities]);

  useEffect(() => {
    if (!recommendations.length) {
      onNext();
    }
  }, [recommendations]);

  const onNext = () => {
    dispatch(savePlan(PLAN_BUILD_STEPS.GRADUATED_REVIEW));
  };

  //   if (!recommendations.length || bypassRecomendations) {
  //     return <ReviewPlan render={render} />;
  //   }

  const getToolTipMessage = () => {
    return disableNext
      ? "You have exceeded your budget. Adjust your plan so that there is some vacation/fun money left over."
      : "";
  };

  const handleClick = (planStep: number) => {
    dispatch(savePlanAndCashflows(planStep as PLAN_BUILD_STEPS));
  };

  return render({
    component: (
      <CenterContainer
        title="Recommendations"
        scrollable
        iconName="fb-clipboard"
      >
        {recommendations.map((item: any, index: number) => {
          return (
            <Card className={classes.messageContainer} key={index}>
              <Box className={classes.message}>
                {item.message && (
                  <Typography className="text-sm">
                    {item.message}

                    {item.help && (
                      <span style={{ position: "relative" }}>
                        <span style={{ position: "absolute" }}>
                          <HelpToggle content={item.help} />
                        </span>
                      </span>
                    )}
                  </Typography>
                )}
              </Box>

              <Box className={classes.link}>
                {item.planStep && (
                  <Link
                    onClick={() => {
                      handleClick(item.planStep);
                    }}
                  >
                    <Typography className="text-sm">
                      Click Here To Fix
                    </Typography>
                  </Link>
                )}
              </Box>
            </Card>
          );
        })}
      </CenterContainer>
    ),
    onNext,
    nextDisabled: disableNext,
    nextTooltip: getToolTipMessage(),
  });
};

export default Recommendations;
