import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { Box } from "@material-ui/core";
import MainCard from "src/components/MainCard";

import { formatAnnually, formatMonthly } from "src/utils";
import {
  addExpense,
  addRisk,
  editExpense,
  editRisk,
  removeExpense,
  removeRisk,
  savePlanAndCashflows,
} from "src/store/planBuild/actions";
import { PLAN_BUILD_STEPS } from "src/store/planBuild/constants";
import {
  currentPlanExpenseTotal,
  currentPlanRiskManagementTotal,
  getCurrentPlanCashflows,
  getStudentTax,
} from "src/store/planBuild/selector";
import {
  PLAN_EXPENSE_TYPES,
  RISK_MANAGEMENT_TYPES,
  IncomeExpenses,
  MappedCashflow,
  Expense,
  PlanViewComponent,
} from "src/interfaces";

// const useStyles = makeStyles(profileBuilderStyles);
const expenseColumns = [
  {
    label: "Type",
    field: "type",
    type: "select",
    width: "46%",
    items: PLAN_EXPENSE_TYPES,
  },
  {
    label: "Monthly",
    field: "monthly",
    type: "number",
    width: "27%",
    formatter: formatMonthly,
  },
  {
    label: "Annual",
    field: "annual",
    type: "number",
    width: "27%",
    formatter: formatAnnually,
  },
];

const MainForm: PlanViewComponent = ({ render }) => {
  const dispatch = useDispatch();
  const { expenses, risks }: IncomeExpenses = useSelector(
    getCurrentPlanCashflows
  );
  const expenseTotal = useSelector(currentPlanExpenseTotal);
  const riskTotal = useSelector(currentPlanRiskManagementTotal);
  const studentTaxes = useSelector(getStudentTax);
  const [newExpense, setNewExpense] = useState<any>(null);

  const currentYear = "" + new Date().getFullYear();
  // const savedYear = localStorage.getItem("yearMemo") || currentYear;
  const savedYear = currentYear;
  const estimatedTaxes = studentTaxes[savedYear];

  const tax = {
    id: 0,
    type: "tax",
    typeLabel: "Taxes",
    annual: estimatedTaxes * 12,
    monthly: estimatedTaxes,
  };

  const saveItem = (
    item: MappedCashflow,
    updateFields: Partial<MappedCashflow>,
    index: number,
    isExpense: boolean
  ) => {
    const listToUpdate = isExpense ? [tax, ...expenses] : risks;
    const isNew = index === listToUpdate.length;
    let action;
    const update = {
      payment: Math.abs(updateFields.annual || item.annual || 0),
      type: updateFields.type || item.type,
    };
    if (isExpense) {
      if (isNew) {
        setNewExpense(null);
        if (!update.type) {
          return;
        }
        action = addExpense(update);
      } else {
        action = editExpense({ index: index - 1, update });
      }
    } else {
      action = editRisk({ index, update });
    }
    dispatch(action);
    dispatch(savePlanAndCashflows(null));
  };

  const saveExpense = (
    item: MappedCashflow,
    updateFields: Partial<MappedCashflow>,
    index: number
  ) => saveItem(item, updateFields, index, true);

  const saveRisk = (
    item: MappedCashflow,
    updateFields: Partial<MappedCashflow>,
    index: number
  ) => saveItem(item, updateFields, index, false);

  const onNext = () =>
    dispatch(savePlanAndCashflows(PLAN_BUILD_STEPS.ASSETS_AND_DEBTS));

  const defaultNewExpense = {
    payment: "",
    type: "",
  };
  const addNewExpense = () => setNewExpense(defaultNewExpense);
  const addRiskItem = (label: string, key: string) => {
    dispatch(addRisk({ type: key, payment: 0 }));
  };

  const removeItem = (item: Expense, index: number, isExpense: boolean) => {
    if (isExpense && index === expenses.length + 1) {
      setNewExpense(null);
    } else {
      dispatch(isExpense ? removeExpense(index - 1) : removeRisk(index));
      dispatch(savePlanAndCashflows(null));
    }
  };

  const removeExpenseItem = (item: Expense, index: number) =>
    removeItem(item, index, true);
  const removeRiskItem = (item: Expense, index: number) =>
    removeItem(item, index, false);

  const showExpense: any[] = [tax, ...expenses];
  if (newExpense) {
    showExpense.push(newExpense);
  }
  const availableRisks = RISK_MANAGEMENT_TYPES;

  return render({
    component: (
      <Box>
        <MainCard
          iconName="fb-wallet"
          title="Day-to-Day Expenses"
          columns={expenseColumns}
          data={showExpense}
          allowSelect
          defaultNewItem={defaultNewExpense}
          fixedRows={[0]}
          onAdd={addNewExpense}
          onCancelEdit={() => setNewExpense(null)}
          onDelete={removeExpenseItem}
          onSave={saveExpense}
          summaryRow={
            !showExpense.length
              ? undefined
              : {
                  type: "Total",
                  annual: expenseTotal,
                  monthly: Math.round(expenseTotal / 12),
                }
          }
        />
        <MainCard
          addLabel="Add Insurance &amp; Premium Payments"
          iconName="fb-manage-protection"
          title="Risk Management"
          columns={[
            { label: "Type", field: "type", type: "fixed", width: "30%" },
            {
              label: "Monthly",
              field: "monthly",
              type: "number",
              width: "20%",
              formatter: formatMonthly,
            },
            {
              label: "Annual",
              field: "annual",
              type: "number",
              width: "20%",
              formatter: formatAnnually,
            },
          ]}
          data={risks}
          addItems={availableRisks}
          onAddFromSelect={addRiskItem}
          onDelete={removeRiskItem}
          onSave={saveRisk}
          className="p-0 shadow-none"
          summaryRow={
            !risks.length
              ? undefined
              : {
                  type: "Total",
                  monthly: Math.round(riskTotal / 12),
                  annual: riskTotal,
                }
          }
        />
      </Box>
    ),
    onNext,
    nextLabel: "Next Section",
  });
};

export default MainForm;
