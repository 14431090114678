import React from "react";
import { useSelector } from "react-redux";

import {
  Box,
  Container,
  Divider,
  Grid,
  makeStyles,
  Typography,
} from "@material-ui/core";

import {
  currentPlanIncomeTotal,
  currentPlanExpenseTotal,
  currentPlanRiskManagementTotal,
  currentPlanSurplus,
  getCurrentPlan,
  getCurrentPlanMessages,
  getMy401kEligibleIncome,
  getSpouse401kEligibleIncome,
} from "src/store/planBuild/selector";
import { getIsMarried } from "src/store/system/selector";
import colors from "src/theme/colors";
import {
  formatExecutionPercent,
  formatPercent,
  formatWholeDollars,
} from "src/utils";
import CenterContainer from "src/pages/PlanBuilder/Components/CenterContainer";
import HelpToggle from "src/components/HelpToggle";
import {
  ASSET_ALLOCATIONS,
  ASSET_TYPES,
  DEBT_TYPES,
  SidebarContent,
} from "src/interfaces";
import { getReviewMessage } from "src/constants/planReviewMessages";
import LinkedText from "src/components/TextWithLink";

// let animationTriggered = false;

const blowOutContent: SidebarContent[] = [
  {
    header: "Thinking In Percentages",
    body: [
      "Analyzing your financial plan using percentages is much easier and more informative than looking at absolute dollar amounts.",
      "1) It makes it easy to visualize the most significant and impactful items in your finances.  It also makes it easy to make adjustments.",
      "2) It get rids of the guesswork. For example, if you get a pay raise, you don't have to guess the amount you should now dedicate to a goal (all else equal). You simply have to look at what percentage of your income is supposed to go where according to your plan and adjust the dollar amount accordingly.",
    ],
  },
];

interface allocationSubset {
  percent: number;
  type: string;
  amount: number;
  who?: string;
}

const PayCheckAllocation = ({ optimized }: { optimized?: boolean }) => {
  const styles = useStyles();
  const isMarried = useSelector(getIsMarried);
  const income = useSelector(currentPlanIncomeTotal);
  const eligibleIncome = useSelector(getMy401kEligibleIncome);
  const eligibleIncomeSpouse = useSelector(getSpouse401kEligibleIncome);
  const expenses = useSelector(currentPlanExpenseTotal);
  const risks = useSelector(currentPlanRiskManagementTotal);
  const remainingMoney = useSelector(currentPlanSurplus);
  const plan = useSelector(getCurrentPlan);
  const messages = useSelector(getCurrentPlanMessages);
  const allocations = plan.allocations[0];
  const soloAllocation = allocations.solo[0];
  const soloAllocationSpouse = allocations.solo[1];
  const payCheckWidth = messages.length ? 6 : 12;
  const orderIndex = optimized ? 3 : 6;
  const title = `${orderIndex}. Pay Check Allocation`;

  // No Right Sidebar for Monthly/Annual selection, default to Monthly
  const isMonthly = true;
  const formatValue = (value: number) => {
    const formatted = isMonthly ? Math.round(value / 12) : value;
    return formatWholeDollars(formatted);
  };

  const debtAllocations: allocationSubset[] = [];
  const assetAllocations: allocationSubset[] = [];
  for (const type in allocations) {
    const soloUser = soloAllocation[type as keyof typeof soloAllocation];
    const soloSpouse =
      soloAllocationSpouse[type as keyof typeof soloAllocationSpouse];
    const isAssetType =
      ASSET_ALLOCATIONS[type as keyof typeof ASSET_ALLOCATIONS];

    if (soloUser || soloSpouse) {
      const userIncome = type.includes("fed") ? income : eligibleIncome;
      const spouseIncome = type.includes("fed") ? income : eligibleIncomeSpouse;
      const userAmount = soloUser * userIncome;
      const spouseAmount = soloSpouse * spouseIncome;
      const userPercent = userAmount / income;
      const spousePercent = spouseAmount / income;
      const collection = isAssetType ? assetAllocations : debtAllocations;
      collection.push({
        type,
        percent: userPercent,
        amount: userAmount / 100,
        who: "applicant",
      });
      if (isMarried) {
        collection.push({
          type,
          percent: spousePercent,
          amount: spouseAmount / 100,
          who: "spouse",
        });
      }
    } else {
      const percent = [allocations[type as keyof typeof allocations]][0];
      if (typeof percent === "number") {
        const collection = isAssetType ? assetAllocations : debtAllocations;
        collection.push({
          type,
          percent,
          amount: (percent / 100) * income,
        });
      }
    }
  }
  const hasAllocatedAssets = !!assetAllocations.filter((asset) => asset.amount)
    .length;
  const hasAllocatedDebts = !!debtAllocations.filter((debt) => debt.percent)
    .length;
  const sortedAssets = assetAllocations.sort(
    (a, b) => b.percent - a.percent || a.type.localeCompare(b.type)
  );
  const sortedDebts = debtAllocations.sort(
    (a, b) => b.percent - a.percent || a.type.localeCompare(b.type)
  );

  const renderGraduatedContent = () => (
    <div
    // onClick={()=>{dispatch(setHeaderClicked(!triggerAnimation))}}
    >
      <Box className="mb-3">
        <Box>
          <Box className={styles.title}>
            <Typography> Day-To-Day Money </Typography>
            <Typography className="text-right">$ Amount </Typography>

            <Box className="flex align-center justify-end">
              <Typography className="relative">
                % Of Your Income
                <span style={{ position: "absolute", bottom: "-5px" }}>
                  <HelpToggle content={blowOutContent} />
                </span>
              </Typography>
            </Box>
          </Box>
          <Divider className="w-full my-2" />

          <Box className={styles.itemBox}>
            <Typography>Total Income</Typography>
            <Typography className="text-right">
              {formatValue(income)}
            </Typography>
            <Typography className="text-right">100.00%</Typography>
          </Box>

          <Box className={styles.itemBox}>
            <Typography>Day-to-Day Expenses</Typography>
            <Typography className="text-right">
              {formatValue(expenses)}
            </Typography>
            <Typography className="text-right">
              {" "}
              {formatExecutionPercent(expenses, income)}{" "}
            </Typography>
          </Box>
        </Box>

        <Box className={styles.title}>
          <Typography> Money For Future Self </Typography>
        </Box>
        <Divider className="w-full my-2" />

        <Box>
          <Box className={styles.itemBox}>
            <Typography>Asset Contributions</Typography>
            {/* 
              Render the amount and percent for each for each asset type in the current plan allocation 0 
              Hide the dollar and percents for the the label
              Indent each of these 
              */}
            <Typography className="text-right">
              {hasAllocatedAssets ? "" : "$0"}
            </Typography>
            <Typography className="text-right">
              {hasAllocatedAssets ? "" : "0%"}
            </Typography>
          </Box>

          {hasAllocatedAssets &&
            sortedAssets.map((asset) => (
              <Box className={styles.subItemBox}>
                <Typography className="pl-8">
                  {ASSET_TYPES[asset.type]}{" "}
                  {asset.who === "spouse" ? "(Spouse)" : ""}
                </Typography>
                <Typography className="text-right">
                  {formatValue(asset.amount)}
                </Typography>
                <Typography className="text-right">
                  {formatPercent(asset.percent)}
                </Typography>
              </Box>
            ))}

          <Box className={styles.itemBox}>
            <Typography>Debt Payments</Typography>
            {/* 
          Render the amount and percent for each for each Debt type in the current plan allocation 0 
          Hide the dollar and percents for the the label
          Indent each of these 
        */}
            <Typography className="text-right">
              {hasAllocatedDebts ? "" : "$0"}
            </Typography>
            <Typography className="text-right">
              {hasAllocatedDebts ? "" : "0%"}
            </Typography>
          </Box>

          {hasAllocatedDebts &&
            sortedDebts.map((debt) => (
              <Box className={styles.subItemBox}>
                <Typography className="pl-8">
                  {DEBT_TYPES[debt.type]}{" "}
                  {debt.who === "spouse" ? "(Spouse)" : ""}
                </Typography>
                <Typography className="text-right">
                  {formatValue(debt.amount)}
                </Typography>
                <Typography className="text-right">
                  {formatPercent(debt.percent)}
                </Typography>
              </Box>
            ))}
        </Box>

        <Box>
          <Box className={styles.title}>
            <Typography> Risk Management </Typography>
          </Box>
          <Divider className="w-full my-2" />
          <Box className={styles.itemBox}>
            <Typography>Insurance Premiums</Typography>
            <Typography className="text-right">{formatValue(risks)}</Typography>
            <Typography className="text-right">
              {" "}
              {formatExecutionPercent(risks, income)}{" "}
            </Typography>
          </Box>
        </Box>

        <Box>
          <Box className={styles.title}>
            <Typography> Vacation/Fun Money </Typography>
          </Box>
          <Divider className="w-full my-2" />
          <Box className={styles.itemBox}>
            <Typography> Unallocated Funds </Typography>
            <Typography className="text-right">
              {" "}
              {formatValue(remainingMoney)}{" "}
            </Typography>
            <Typography className="text-right">
              {" "}
              {formatPercent((remainingMoney / income) * 100)}{" "}
            </Typography>
          </Box>
        </Box>
      </Box>

      {/* <Box className={styles.itemBox}>
        <Typography >
          Vacation/Fun Money
        </Typography>
        
        <Typography className="text-center">
          {formatValue(remainingMoney)}
        </Typography>
        
        <Typography className="text-center"> { formatExecutionPercent(income - expenses, income)} </Typography>
      </Box> */}
    </div>
  );

  const renderPlanMessages = () =>
    messages.map((entry) => {
      const planMessage = getReviewMessage(entry, "paycheck");
      if (planMessage.text && !planMessage.links) {
        return (
          <Typography
            style={{
              fontSize: "14px",
              marginBottom: "10px",
            }}
          >
            {planMessage.text}
          </Typography>
        );
      } else if (planMessage.text && planMessage.links) {
        return (
          <Typography
            style={{
              fontSize: "14px",
              marginBottom: "10px",
            }}
          >
            <LinkedText
              text={planMessage.text}
              links={planMessage.links}
              indicator="(Click here to do so)"
            />
          </Typography>
        );
      } else {
        return <div />;
      }
    });

  const boxStyle = messages ? "messagesPresent" : "noMessages";

  return (
    <CenterContainer review title={title} scrollable={!plan.messages}>
      <Container className="flex justify-center">
        {!!messages.length && (
          <Grid xs={6} className={styles[boxStyle]}>
            {/* <Box className="pr-2"> */}
            {renderPlanMessages()}
            {/* </Box> */}
          </Grid>
        )}
        <Grid xs={payCheckWidth} className={styles[boxStyle]}>
          <Typography style={{ fontSize: "14px" }}>
            Below is a summary of how you chose to allocate your money each
            month to follow your plan and hit your goals.
          </Typography>
          {renderGraduatedContent()}
        </Grid>
      </Container>
    </CenterContainer>
  );
};

export default PayCheckAllocation;

const useStyles = makeStyles({
  actionBar: {
    background: "white",
    padding: "12px 68px",
    bottom: 0,
    left: 0,
    display: "flex",
    justifyContent: "space-between",
    position: "fixed",
    width: "100%",
  },
  actionButton: {
    width: 168,
  },
  cardRowCell: {
    fontSize: 15,
    width: "16%",
  },
  cardRowLabel: {
    fontSize: 15,
    paddingLeft: 10,
    width: "36%",
  },
  cardTableHeader: {},
  chart: {
    minWidth: 440,
    maxWidth: 524,
  },
  loaderContainer: {
    position: "fixed",
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    zIndex: 555,
    background: "rgba(255, 255, 255, .8)",
  },
  title: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    margin: "10px 0px",
    "&>p": {
      color: colors.brandingBlue1,
      fontSize: 13,
      fontWeight: 500,
      width: "33%",
    },
    "&>div": {
      width: "33%",
      display: "flex",
      justifyContent: "center",
      "&>p": {
        color: colors.brandingBlue1,
        fontSize: 13,
        fontWeight: 500,
      },
    },
  },
  itemBox: {
    display: "flex",
    justifyContent: "space-between",
    "& p": {
      fontSize: 13,
      width: "36%",
      paddingLeft: 16,
    },
    "& div": {
      width: "33%",
    },
  },
  subItemBox: {
    display: "flex",
    justifyContent: "space-between",
    "& p": {
      fontSize: 13,
      width: "33%",
      textTransform: "capitalize",
    },
    "& div": {
      width: "400px",
    },
  },
  messagesPresent: {
    margin: "1rem 0px",
    height: "calc(100vh - 300px)",
    padding: "0px 1.5rem 0 .5rem",
    overflowY: "auto",
    overflowX: "hidden",
  },

  noMessages: {
    margin: "2rem 10px",
    height: "calc(100vh - 300px)",
  },
});
