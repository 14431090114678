import React from "react";

import { Dialog, DialogTitle, IconButton, makeStyles } from "@material-ui/core";
import { ClassNameMap } from "@material-ui/styles";
import clsx from "clsx";

import Icon from "src/components/Icon";
import colors from "src/theme/colors";

interface Props {
  title: React.ReactNode;
  subTitle?: string;
  isOpen: boolean;
  size: false | "sm" | "xs" | "md" | "lg" | "xl" | undefined;
  onClose?: any;
  onBack?: () => void;
  children?: React.ReactNode;
  classes?: ClassNameMap;
  className?: string;
  style?: any;
}

const Modal = ({
  title,
  isOpen,
  size,
  onClose,
  className,
  children,
  style,
  classes = {},
}: Props) => {
  const styles = useStyles();

  return (
    <Dialog
      classes={classes}
      fullWidth={true}
      onExiting={onClose}
      onClose={onClose}
      open={isOpen}
      maxWidth={size}
      className={styles.container}
      aria-labelledby="form-dialog-title"
      disableEnforceFocus
      PaperProps={{ style }}
    >
      {!!title && (
        <div className={styles.header}>
          <DialogTitle id="alert-dialog-title">{title}</DialogTitle>
          <IconButton color="primary" onClick={onClose}>
            <Icon iconName="fb-close" className={styles.close_icon} />
          </IconButton>
        </div>
      )}
      <div className={clsx(styles.content, className)}>{children}</div>
    </Dialog>
  );
};
const useStyles = makeStyles({
  container: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    margin: "auto",
    borderRadius: "16px!important",
  },
  header: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  content: {
    padding: "0px 8px",
  },
  close_icon: {
    color: colors.brandingBlue1,
    width: "24px",
    height: "24px",
    cursor: "pointer",
  },
});

export default Modal;
