import React, { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Grid } from "@material-ui/core";

import BarChart from "src/components/BarChart";
import Button from "src/components/Button";
import HeaderText from "src/components/HeaderText";
import IncomeCard from "./Blocks/Income";
import ExpenseCard from "./Blocks/Expenses";
import LockedLayer from "src/components/LockedLayer";
import ConfirmedTransactions from "src/pages/Dashboard/MyProfile/Transactions/Blocks/ConfirmedTransactions";
import ConfirmIncomeDialog from "src/pages/Dashboard/MyProfile/Transactions/Dialogs/ConfirmIncomeDialog";
import { newPlanContent } from "./data";
import { getBreakoutBaseApi, getTaxExpenseApi } from "src/apiService";
import { MappedCashflow, Transaction } from "src/interfaces";
import ReviewTransactionsDialog from "src/pages/Dashboard/MyProfile/Transactions/Dialogs/ReviewTransactionsDialog";
import { getIncomeAndExpenseSummaries } from "src/store/dashboard/actions";
import {
  getLoadedSummaries,
  getRefreshNeeded,
} from "src/store/dashboard/selector";
import { getLiveProjection } from "src/store/planBuild/selector";
import { getDashboardCashflows } from "src/store/cashflow/selector";
import { getHasPlan, getIsSubscribed } from "src/store/system/selector";
import { AdapterLink, formatWholeDollars } from "src/utils";

const emptyTransaction: Transaction = {
  account: -999,
  amount: -999,
  date: "1999-01-01",
  description: "",
  id: -999,
  type: 7000,
};

export const Content = () => {
  const dispatch = useDispatch();
  const loadedSummaries = useSelector(getLoadedSummaries);
  const {
    income: incomeCashflows,
    expenses: expenseCashflows,
  }: any = useSelector(getDashboardCashflows);
  const { heading, description, subscribedDescription } = newPlanContent;
  const [showConfirmDialog, setShowConfirmDialog] = useState(false);
  const [showTransactionsDialog, setShowTransactionsDialog] = useState(false);
  const [transactionQuery, setTransactionQuery] = useState({});
  const [
    confirmingTransaction,
    setConfirmingTransaction,
  ] = useState<Transaction>(emptyTransaction);
  const [editing, setEditing] = useState(false);
  const [estimatedTaxes, setEstimatedTaxes] = useState<number>(0);
  const projection = useSelector(getLiveProjection);
  const subscribed = useSelector(getIsSubscribed);
  const hasPlan = useSelector(getHasPlan);
  const refreshNeeded = useSelector(getRefreshNeeded);

  useEffect(() => {
    getTaxExpenseApi().then((result) => setEstimatedTaxes(result.amount));
  }, []);

  const taxBudget =
    projection?.now?.expenses?.[0]?.payment || estimatedTaxes / 12;

  useEffect(() => {
    if (subscribed && (!loadedSummaries || refreshNeeded.summaries)) {
      dispatch(getIncomeAndExpenseSummaries());
    }
  }, [subscribed, loadedSummaries, refreshNeeded.summaries]);

  const incomeTotal = useMemo(
    () =>
      incomeCashflows.reduce((result: number, item: MappedCashflow) => {
        return (item.monthly || 0) + result;
      }, 0),
    [incomeCashflows]
  );

  const expenseTotal = useMemo(
    () =>
      expenseCashflows.reduce((result: number, item: MappedCashflow) => {
        return (item.monthly || 0) + result;
      }, 0),
    [expenseCashflows]
  );

  const openConfirmDialog = (transaction?: Transaction, editing?: boolean) => {
    setEditing(!!editing);
    let rootTransaction = transaction;
    let promise = Promise.resolve();
    if (transaction) {
      promise = getBreakoutBaseApi(transaction.id)
        .then((result: any) => {
          rootTransaction = result?.transactions?.[0] || transaction;
        })
        .catch(console.error);
    }
    return promise.then(() => {
      if (rootTransaction) {
        setConfirmingTransaction(rootTransaction);
      } else {
        setConfirmingTransaction(emptyTransaction);
      }
      setShowConfirmDialog(true);
    });
  };

  const closeConfirmDialog = () => {
    setShowConfirmDialog(false);
    setEditing(false);
  };

  const openTransactionsDialog = (
    category: string,
    key: string,
    timeframe: string
  ) => {
    const query: any = {
      category,
      period: timeframe,
      type: key,
    };
    setShowTransactionsDialog(true);
    setTransactionQuery(query);
  };

  const closeTransactionsDialog = () => {
    setShowTransactionsDialog(false);
    setTransactionQuery({});
  };

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} md={6}>
        <HeaderText
          heading={heading}
          description={subscribed ? subscribedDescription : description}
          buttons={
            hasPlan
              ? undefined
              : [
                  <Button
                    fbColor="primary"
                    component={AdapterLink}
                    to="/build-plan"
                  >
                    Build a New Plan
                  </Button>,
                ]
          }
        />
      </Grid>
      <Grid item xs={12} md={6}>
        <BarChart
          dataSource={[
            {
              name: "Income",
              value: incomeTotal,
            },
            {
              name: "Expenses",
              value: expenseTotal + (taxBudget || 0),
            },
            {
              name: "Remaining Cash",
              value: incomeTotal - expenseTotal - (taxBudget || 0),
            },
          ]}
          formatter={formatWholeDollars}
          labelYOffset={10}
          title="Budget Overview"
          height="h-72 pb-5"
        />
      </Grid>
      <Grid item xs={12} md={6}>
        <IncomeCard openTransactionsDialog={openTransactionsDialog} />
      </Grid>
      <Grid item xs={12} md={6}>
        <ExpenseCard
          openTransactionsDialog={openTransactionsDialog}
          taxBudget={taxBudget}
        />
      </Grid>
      <Grid item xs={12} md={6}>
        <LockedLayer isLocked={!subscribed}>
          <ConfirmedTransactions
            openDialog={(transaction: Transaction) =>
              openConfirmDialog(transaction, true)
            }
          />
        </LockedLayer>
      </Grid>
      <ConfirmIncomeDialog
        open={showConfirmDialog}
        onClose={closeConfirmDialog}
        transaction={confirmingTransaction}
        alreadyConfirmed={editing}
      />
      <ReviewTransactionsDialog
        onClose={closeTransactionsDialog}
        open={showTransactionsDialog}
        query={transactionQuery}
      />
    </Grid>
  );
};
