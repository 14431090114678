import React, { useState } from "react";
import { useSelector } from "react-redux";

import { range } from "lodash";

import {
  Box,
  makeStyles,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from "@material-ui/core";

import {
  ACCOUNT_TYPES,
  EDUCATION_FUNDING_AND_EXPENSES,
  PLAN_EXPENSE_TYPES,
  RISK_MANAGEMENT_TYPES,
} from "src/interfaces";
import { getCurrentPlanYearlySummary } from "src/store/planBuild/selector";
import {
  getGraduationYear,
  getSpouseGraduationYear,
} from "src/store/profileBuild/selector";
import colors from "src/theme/colors";
import { formatWholeDollars } from "src/utils";
import Icon from "src/components/Icon";

const useStyles = makeStyles({
  root: {
    padding: 15,
    borderRadius: 12,
    marginBottom: 20,
  },
  title: {
    fontSize: 14,
    color: colors.brandingBlue1,
    fontWeight: 600,
  },
  table: {
    width: "100%",
    borderBottom: `1px solid ${colors.blueGray6}`,
    paddingLeft: 10,
  },
  headerCell: {
    fontSize: 10,
    color: colors.blueGray1,
    fontWeight: 500,
    borderBottom: `1px solid ${colors.blueGray6}`,
    padding: "7px 0",
    textAlign: "center",
  },
  firstCol: {
    width: 225,
  },
  row: {
    cursor: "pointer",
  },
  cell: {
    fontSize: 10,
    fontWeight: 600,
    borderBottom: "none",
    borderTop: `1px solid ${colors.blueGray6}`,
    padding: "10px 0",
    position: "relative",
    "& svg": {
      fontSize: 12,
      color: colors.blueGray1,
      position: "absolute",
      left: 0,
    },
  },
  unbold: {
    fontWeight: 500,
  },
  odd: {
    color: colors.brandingBlue1,
  },
  even: {
    color: colors.brandingBlue2,
  },
  leftBorder: {
    borderLeft: `1px solid ${colors.blueGray6}`,
    textAlign: "center",
  },
  topBorder: {
    borderTop: `1px solid ${colors.blueGray6} !important`,
  },
  subCell: {
    fontSize: 10,
    fontWeight: 500,
    borderBottom: "none",
    borderTop: "none",
    padding: "7px 0",
    color: colors.blueGray2,
  },
});
const now = new Date();

const noGridStyle = { border: "0" };

const SchoolYearFundingCard = () => {
  const classes = useStyles();
  const summaryData: any = useSelector(getCurrentPlanYearlySummary);
  const graduationYear: number = useSelector(getGraduationYear);
  const spouseGraduationYear: number = useSelector(getSpouseGraduationYear);
  const [expanded, setExpanded] = useState<Array<number>>([]);

  const isExpanded = (i: number) => {
    return expanded.indexOf(i) > -1;
  };

  const handleToggle = (i: number) => {
    const index = expanded.indexOf(i);
    if (index > -1) {
      setExpanded(expanded.filter((j) => j !== i));
      expanded.splice(index, 1);
    } else {
      setExpanded([...expanded, i]);
    }
  };

  const years = range(
    now.getFullYear(),
    Math.max(graduationYear, spouseGraduationYear) + 1
  );

  const rows = [
    {
      header: "Education Funding",
      columns: years.map((year: number) =>
        formatWholeDollars(summaryData.funding["" + year].total)
      ),
      children: Array.from<string>(summaryData.funding.keys).map(
        (type: string) => ({
          header: EDUCATION_FUNDING_AND_EXPENSES[type],
          columns: years.map((year: number) => {
            const value = summaryData.funding?.["" + year]?.[type];
            if (value) {
              return formatWholeDollars(value);
            }
            return "";
          }),
        })
      ),
    },
    {
      header: "Education Expenses",
      columns: years.map((year: number) =>
        formatWholeDollars(summaryData.educationExpenses["" + year].total)
      ),
      children: Array.from<string>(summaryData.educationExpenses.keys).map(
        (type: string) => ({
          header: EDUCATION_FUNDING_AND_EXPENSES[type],
          columns: years.map((year: number) => {
            const value = summaryData.educationExpenses?.["" + year]?.[type];
            if (value) {
              return formatWholeDollars(value);
            }
            return "";
          }),
        })
      ),
    },
    {
      header: "Day-to-day Expenses",
      columns: years.map((year: number) =>
        formatWholeDollars(summaryData.expenses["" + year].total)
      ),
      children: Object.keys(summaryData.expenses["" + now.getFullYear()])
        .filter((key) => key !== "total")
        .map((type: string) => ({
          header: PLAN_EXPENSE_TYPES[type],
          columns: years.map((year: number) => {
            const value = summaryData.expenses?.["" + year]?.[type];
            if (value) {
              return formatWholeDollars(value);
            }
            return "";
          }),
        })),
    },
    {
      header: "Asset Contributions",
      columns: years.map((year: number) =>
        formatWholeDollars(summaryData.assets["" + year].total)
      ),
      children: Object.keys(summaryData.assets["" + now.getFullYear()])
        .filter((key) => key !== "total")
        .map((type: string) => ({
          header: ACCOUNT_TYPES[type],
          columns: years.map((year: number) => {
            const value = summaryData.assets?.["" + year]?.[type];
            if (value) {
              return formatWholeDollars(value);
            }
            return "";
          }),
        })),
    },
    {
      header: "Debt Payments",
      columns: years.map((year: number) =>
        formatWholeDollars(summaryData.debts["" + year].total)
      ),
      children: Object.keys(summaryData.debts["" + now.getFullYear()])
        .filter((key) => key !== "total")
        .map((type: string) => ({
          header: ACCOUNT_TYPES[type],
          columns: years.map((year: number) => {
            const value = summaryData.debts?.["" + year]?.[type];
            if (value) {
              return formatWholeDollars(value);
            }
            return "";
          }),
        })),
    },
    {
      header: "Risk Management",
      columns: years.map((year: number) =>
        formatWholeDollars(summaryData.risks["" + year].total)
      ),
      children: Object.keys(summaryData.risks["" + now.getFullYear()])
        .filter((key) => key !== "total")
        .map((type: string) => ({
          header: RISK_MANAGEMENT_TYPES[type],
          columns: years.map((year: number) => {
            const value = summaryData.risks?.["" + year]?.[type];
            if (value) {
              return formatWholeDollars(value);
            }
            return "";
          }),
        })),
    },
    {
      header: "Left Over Funds",
      columns: years.map((year: number) =>
        formatWholeDollars(summaryData.remaining["" + year])
      ),
    },
  ];

  return (
    <Paper className={classes.root}>
      <Typography component="h3" variant="body1" className={classes.title}>
        School Year Funding &amp; Expenses
      </Typography>
      <Table className={classes.table} aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell
              className={`${classes.headerCell} ${classes.firstCol}`}
            ></TableCell>
            {years.map((col, index) => (
              <TableCell key={index} className={classes.headerCell}>
                {col}
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map((row, rIndex) => {
            const className = classes.cell;
            return (
              <>
                <TableRow
                  key={rIndex}
                  className={classes.row}
                  onClick={() => handleToggle(rIndex)}
                >
                  <TableCell
                    component="th"
                    scope="row"
                    className={className}
                    style={noGridStyle}
                  >
                    <Box className="flex items-center relative">
                      {!!row.children && !!row.children.length && (
                        <Icon
                          iconName={
                            isExpanded(rIndex)
                              ? "fb-chevron-down"
                              : "fb-chevron-right"
                          }
                        ></Icon>
                      )}
                      <span className="ml-4 text-left">{row.header}</span>
                    </Box>
                  </TableCell>
                  {(row.columns || []).map((col, cIndex) => (
                    <TableCell
                      key={cIndex}
                      className={`${className} ${classes.leftBorder}`}
                      style={noGridStyle}
                    >
                      {col}
                    </TableCell>
                  ))}
                </TableRow>
                {isExpanded(rIndex) && !!row.children && !!row.children.length && (
                  <>
                    {row.children.map((subCol, srIndex) => (
                      <TableRow key={srIndex}>
                        <TableCell
                          component="th"
                          scope="row"
                          className={`${classes.subCell} ${
                            !srIndex ? classes.topBorder : ""
                          }`}
                          style={noGridStyle}
                        >
                          <span className="ml-4 text-primary">
                            {subCol.header}
                          </span>
                        </TableCell>
                        {(subCol.columns || []).map((col, cIndex) => (
                          <TableCell
                            key={cIndex}
                            className={`${classes.subCell} ${
                              classes.leftBorder
                            } ${!srIndex ? classes.topBorder : ""}`}
                            style={noGridStyle}
                          >
                            {col}
                          </TableCell>
                        ))}
                      </TableRow>
                    ))}
                  </>
                )}
              </>
            );
          })}
        </TableBody>
      </Table>
    </Paper>
  );
};

export default SchoolYearFundingCard;
