import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { push } from "connected-react-router";
import { Grid } from "@material-ui/core";

import { Header } from "./Blocks/Header";
import { Cards } from "./Blocks/Cards";
import FreeAccountInterstitial from "./FreeAccountInterstitial";
import Upgrade from "src/components/Upgrade";
import { getRouterState } from "src/store/router/selector";
import { getIsSubscribed } from "src/store/system/selector";

const TOOL_NAMES = ["payoffAnalysis", "payoffVsIDR", "refinance", "investment"];

export const Content = () => {
  const dispatch = useDispatch();
  const subscribed = useSelector(getIsSubscribed);
  const router = useSelector(getRouterState);
  const [showUpgradeDialog, setShowUpgradeDialog] = useState(false);
  const [showFreeAccountDialog, setShowFreeAccountDialog] = useState(false);

  const gotoTool = (toolName: string) => {
    if (toolName === "investment" && !subscribed) {
      setShowFreeAccountDialog(true);
    } else {
      dispatch(push(`tools/${toolName}`));
    }
  };

  useEffect(() => {
    if (router.location.hash) {
      const targetTool = router.location.hash.slice(1);
      if (new Set(TOOL_NAMES).has(targetTool)) {
        gotoTool(targetTool);
      }
    }
  }, [router]);

  const openUpgradeDialog = () => setShowUpgradeDialog(true);

  const closeDialogs = () => {
    setShowUpgradeDialog(false);
    setShowFreeAccountDialog(false);
  };
  return (
    <>
      <Grid container spacing={2} style={{ maxWidth: 900 }}>
        <Grid item xs={12}>
          <Header />
        </Grid>
        <Grid item xs={12}>
          <Cards setTool={gotoTool} />
        </Grid>
        {/* <Grid item xs={12}>
          <Intro
            heading={heading}
            description={description}
            buttons={buttons}
            img={img}
            specialHeading={specialHeading}
          />
        </Grid> */}
      </Grid>
      <FreeAccountInterstitial
        open={showFreeAccountDialog}
        onClose={closeDialogs}
        gotoUpgrade={openUpgradeDialog}
      />
      <Upgrade open={showUpgradeDialog} onClose={closeDialogs} />
    </>
  );
};

export default Content;
