import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { Box, makeStyles, Typography } from "@material-ui/core";

import InformationCard from "../Components/InformationCard";
import SubscriptionCard from "../Components/SubscriptionCard";
import colors from "src/theme/colors";
import {
  selectPortal,
  selectPurchasePlans,
  selectStripeError,
  selectStripeLoading,
  selectSubscriptions,
} from "src/store/stripe/selector";
import { openUpgradeDialog } from "src/store/system/actions";
import { getIsSubscribed } from "src/store/system/selector";
import { getAllPurchaseData } from "src/store/stripe/actions";
import CustomDialog from "src/components/Dialogs/CustomDialog";
import Button from "src/components/Button";

const Subscription = () => {
  const styles = useStyles();
  const dispatch = useDispatch();

  const loading = useSelector(selectStripeLoading);
  const error = useSelector(selectStripeError);
  const subscriptions = useSelector(selectSubscriptions) || [];
  const plans = useSelector(selectPurchasePlans) || [];
  const portal = useSelector(selectPortal);
  const isSubscribed = useSelector(getIsSubscribed);

  const [portalVisible, setPortalVisible] = useState<boolean>(false);

  useEffect(() => {
    if (loading || error) return;
    if (isSubscribed && (!subscriptions || !subscriptions.length)) {
      dispatch(getAllPurchaseData());
    }
  }, [loading, error, dispatch]);

  const getPlanByName = (name?: string) => {
    const plan = plans.find((p) => p.name === name);
    if (plan && plan.amount) {
      return `$${plan.amount} Billed ${name}`;
    }
    return name || "Unknown";
  };

  return (
    <InformationCard title="Subscription">
      {isSubscribed && (
        <SubscriptionCard
          iconName="fb-checkmark-outline"
          heading={subscriptions?.[0]?.name || "Unknown"}
          label={getPlanByName(subscriptions?.[0]?.plan)}
          btnLabel="Manage"
          handleClick={() => setPortalVisible(true)}
        />
      )}
      {!isSubscribed && (
        <SubscriptionCard
          iconName="fb-checkmark-outline"
          heading={"Free Membership"}
          label={""}
          btnLabel="Upgrade"
          handleClick={() => dispatch(openUpgradeDialog())}
        />
      )}
      <CustomDialog
        size="sm"
        isOpen={portalVisible}
        title="Manage Subscriptions"
        onClose={() => setPortalVisible(false)}
      >
        <Typography className={styles.modalMessage} component="p" paragraph>
          You are temporarily leaving the FitBUX site.
        </Typography>
        <a href={portal} target="_blank">
          Please Click Here
        </a>
        <Box className={styles.buttonsContainer}>
          <Button
            variant="contained"
            fbColor="secondary"
            disabled={loading}
            onClick={() => setPortalVisible(false)}
            className="mr-4"
          >
            Close
          </Button>
        </Box>
      </CustomDialog>
    </InformationCard>
  );
};

const useStyles = makeStyles({
  container: {
    paddingTop: 20,
  },
  dialogRoot: {
    backgroundColor: "transparent",
    backgroundImage: colors.gradLightAltVertical2,
  },
  message: {
    fontSize: 14,
    color: colors.blueGray6,
    width: "100%",
    textAlign: "center",
    lineHeight: "26px",
  },
  modalMessage: {
    fontSize: 14,
    color: colors.blueGray1,
    width: "100%",
    textAlign: "left",
    lineHeight: "26px",
    marginBottom: 20,
    marginTop: 15,
  },
  buttonsContainer: {
    display: "flex",
    justifyContent: "flex-end",
  },
});
export default Subscription;
