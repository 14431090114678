import {
  Box,
  Card,
  Divider,
  IconButton,
  makeStyles,
  Tooltip,
  Typography,
} from "@material-ui/core";
import DoubleArrowIcon from "@material-ui/icons/DoubleArrow";
import { push } from "connected-react-router";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getMyGI, getRawCashflows } from "src/store/cashflow/selector";
// import { useStyles } from "src/pages/PlanBuilder/Views/MoneyForFutureGoals/styles";
import { setPopup } from "src/store/dashboard/actions";
import {
  clearoutReviewSection,
  setBuildStep,
  setFurthestStep,
  setCurrentPlan,
} from "src/store/planBuild/actions";
import { PLAN_BUILD_STEPS } from "src/store/planBuild/constants";
import { fetchUser } from "src/store/system/actions";
import {
  getIsCurrentStudent,
} from "src/store/system/selector";
import { colors, theme } from "src/theme";
import FbButton from "../Button";
import FbIcon from "../Icon";

import { text } from "./text";

const PlanSelect = () => {
  const dispatch = useDispatch();
  const styles = useStyles();
  const isStudent = useSelector(getIsCurrentStudent);
  const [forceCall, setForceCall] = useState(false);
  const grossIncome = useSelector(getMyGI);
  const incomeSources = useSelector(getRawCashflows);
  const sideIncome = incomeSources.reduce((accumulator, source) => {
    if (!source.inschool && source.type === "side_hustle") {
      return accumulator + (source.amount || 0);
    } else {
      return accumulator;
    }
  }, 0);
  const sideIncomePercent = sideIncome / grossIncome;
  const forceCoachCall = sideIncomePercent > 0.2;

  const textOptimizer = isStudent ? text.standard : text.student;

  const handleClose = () => {
    dispatch(push("build-plan"));
  };

  const ButtonCard = ({
    title,
    bodyText,
    optimized,
  }: {
    title: string;
    bodyText: string;
    optimized?: boolean;
  }) => {
    const disabled = optimized && (isStudent);
    const toolText = optimized && isStudent ? text.disabled.tooltip : "";

    const routeToOptimized = () => {
      // Tempoarily disable AFP
      if (optimized) {
        return;
      }
      if (isStudent) {
        // Disable the button.
        // AFP Questionnaire is currently worded only for GRADUATED profiles.
        return;
      }
      if (forceCoachCall) {
        setForceCall(true);
      } else {
        dispatch(push("/plan-builder-optimized"));
      }
    };
    const routeToManual = () => {
      dispatch(clearoutReviewSection());
      dispatch(setCurrentPlan({ planId: 0, keepId: false }));
      dispatch(fetchUser());
      dispatch(setBuildStep(PLAN_BUILD_STEPS.NAME));
      dispatch(setFurthestStep(PLAN_BUILD_STEPS.NAME));
      dispatch(setPopup("Build"));
      dispatch(push("/plan-builder"));
    };

    const handleClick = () => {
      if (optimized) {
        routeToOptimized();
      } else {
        routeToManual();
      }
    };
    const appliedStyle = disabled ? styles.buttonDisabled : styles.button;

    return (
      <Tooltip title={toolText} placement="bottom">
        <Card className={appliedStyle} onClick={handleClick}>
          <Typography className={styles.subTitle}>{title}</Typography>

          <Box className={styles.textBody}>
            <Typography>{bodyText}</Typography>
          </Box>

          <Box className={styles.adornment}>
            <DoubleArrowIcon />
          </Box>
        </Card>
      </Tooltip>
    );
  };

  return (
    <Box className="h-full">
      <Box className="flex align-center space-between">
        <Typography variant="h2" className="pt-2 pl-4">
          Build a plan
        </Typography>
        <IconButton
          color="inherit"
          className="absolute right-0"
          onClick={handleClose}
        >
          <FbIcon iconName="fb-close" className="text-4xl" />
        </IconButton>
      </Box>
      <Box className={styles.main}>
        {forceCall && (
          <Box>
            <Card className={styles.container}>
              <Typography className={styles.title}>
                Welcome To Your Optimized Plan
              </Typography>
              <Divider className={styles.divider} />

              <Typography className="mt-8 mx-4">
                Since a significant amount of your income is derived from
                self-employment and/or 1099s, you have access to special
                investment options. We recommend scheduling a call with your
                FitBUX Coach.
              </Typography>
            </Card>
            <Box className="flex justify-center mt-4">
              <FbButton
                onClick={() => {
                  dispatch(push("build-plan#schedule"));
                }}
              >
                Schedule A Call
              </FbButton>
            </Box>
          </Box>
        )}

        {!forceCall && (
          <Box className={styles.container}>
            <Typography variant="h2" className="text-center">
              {text.title}
            </Typography>

            <Box className={styles.buttonContainer}>
              {/* Optimized Reroute */}

              <ButtonCard
                title={textOptimizer.heading}
                bodyText={textOptimizer.body}
                optimized
              />

              <ButtonCard
                title={text.manual.heading}
                bodyText={text.manual.body}
              />
            </Box>
          </Box>
        )}
      </Box>
    </Box>
  );
};

export default PlanSelect;

const useStyles = makeStyles({
  main: {
    display: "flex",
    justifyContent: "center",
    height: "95%",
    alignItems: "center",
  },
  container: {
    display: "flex",
    flexDirection: "column",
    width: "800px",
    height: "350px",
    alignItems: "space-between",
  },
  divider: {
    width: "calc(100% - 10px)",
  },
  buttonContainer: {
    height: "100%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-evenly",
    alignItems: "center",
  },
  button: {
    height: "35%",
    width: "800px",
    margin: "2px auto",
    borderRadius: "9px",
    padding: "0 20px",
    position: "relative",
    cursor: "pointer",
    "&:hover": {
      background: colors.gradLightHorizontal,
    },
    "&:active": {
      background: colors.gradButtonBright,
    },
  },
  buttonDisabled: {
    height: "45%",
    width: "800px",
    margin: "2px auto",
    borderRadius: "9px",
    padding: "0 20px",
    position: "relative",
    backgroundColor: "#f6fbfd",
    "&:hover": {
      background: colors.gradLightHorizontal,
    },
    "&:active": {
      background: colors.gradButtonBright,
    },
  },
  title: {
    fontSize: "16px",
    fontWeight: 500,
    color: theme.palette.primary.main,
    margin: "10px",
  },
  subTitle: {
    fontSize: "14px",
    fontWeight: 500,
    color: theme.palette.primary.main,
    marginTop: "10px",
  },
  textBody: {
    height: "70%",
    display: "flex",
    alignItems: "center",
  },
  adornment: {
    position: "absolute",
    right: "5px",
    bottom: "35%",
  },
});
