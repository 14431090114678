import React from "react";
import { useDispatch, useSelector } from "react-redux";

import {
  Box,
  Grid,
  // makeStyles,
  Tooltip,
  Typography,
} from "@material-ui/core";

import Button from "src/components/Button";
import TitleCard from "src/components/TitleCard";
import BigButton from "src/components/BigButton";
import { GeneralPageCardDefinition } from "src/interfaces";
import { openUpgradeDialog } from "src/store/system/actions";
import { getIsSubscribed } from "src/store/system/selector";
import { AdapterLink } from "src/utils";
import { push } from "connected-react-router";

// const useStyles = makeStyles({});

const GeneralCard = ({
  title,
  heading,
  subscribedHeading,
  items,
  showSubscribeButton,
}: GeneralPageCardDefinition & { title: string }) => {
  // const styles = useStyles();
  const dispatch = useDispatch();
  const subscribed = useSelector(getIsSubscribed);

  let displayHeading = heading;
  if (!items.length) {
    displayHeading =
      "Watch this space as we are working on a lot of great things!";
  } else if (subscribed && subscribedHeading) {
    displayHeading = subscribedHeading;
  }

  let height = 430;
  let gridHeight = 272;

  if (!subscribed) {
    height += 55;
  }

  if (items.length > 4) {
    const extra = 120 + 2 * 8;
    height += extra;
    gridHeight += extra;
  }

  return (
    <TitleCard title={title} style={{ width: "auto", height }}>
      <Typography className="h-8">{displayHeading}</Typography>
      <Grid
        container
        spacing={2}
        className="my-4"
        style={{ height: gridHeight }}
      >
        {items.map((item) => {
          const button = (
            <BigButton
              iconImage={item.iconImage}
              iconName={item.iconName}
              variant="outlined"
              fbColor="primary"
              component={AdapterLink}
              // disabled={item.disabled}
              // style={
              //   item.disabled
              //     ? { background: colors.gray1, color: "white" }
              //     : undefined
              // }
              onClick={() => {
                if (!item.disabled) {
                  const destination =
                    subscribed && item.subscribedTarget
                      ? item.subscribedTarget
                      : item.target;
                  dispatch(push(destination));
                }
              }}
              // to={
              //   subscribed && item.subscribedTarget
              //     ? item.subscribedTarget
              //     : item.target
              // }
            >
              {item.label}
            </BigButton>
          );
          let element = button;
          if (item.tooltip) {
            element = (
              <Tooltip title={item.tooltip}>
                <span>{button}</span>
              </Tooltip>
            );
          }
          return (
            <Grid item xs={6} key={item.label}>
              {element}
            </Grid>
          );
        })}
      </Grid>
      {!subscribed && showSubscribeButton && (
        <Box className="text-center mt-6">
          <Button
            fbColor="primary"
            onClick={() => dispatch(openUpgradeDialog())}
          >
            Upgrade to Premium
          </Button>
        </Box>
      )}
    </TitleCard>
  );
};

export default GeneralCard;
