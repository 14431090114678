import React from "react";

import {
  Box,
  Dialog,
  DialogContent,
  makeStyles,
  Typography,
} from "@material-ui/core";

import Button from "src/components/Button";
import Card from "src/components/Card";
import CalculatorLayout from "src/layouts/CalculatorLayout";
import { colors } from "src/theme";
import { AdapterLink } from "src/utils";

const useStyles = makeStyles({
  container: {
    margin: "20px auto",
    width: "90%",
    maxWidth: 1100,
  },
  dialogRoot: {
    background: colors.blueGray8,
  },
});

export const NewOrRefinanceInterstitial = ({ open, onClose }: any) => {
  const styles = useStyles();

  return (
    <Dialog
      fullScreen
      open={open}
      className="m-0 p-0"
      PaperComponent={React.Fragment}
    >
      <DialogContent className={styles.dialogRoot}>
        <CalculatorLayout disclaimer={""} title={""} onBackClick={onClose}>
          <Box className={styles.container}>
            <Card divider title="Check Mortgage Rates">
              <Typography className="my-5">
                FitBUX's mortgage lending partners offer pre-qualifications and
                mortgages for home purchase and refinancing. Select which one
                you would like to check rates for.
              </Typography>
              <Box className="w-full flex justify-center">
                <Button
                  className="mr-4"
                  fbColor="primary"
                  component={AdapterLink}
                  to="/mortgage-rates"
                >
                  Home Purchase
                </Button>
                <Button
                  fbColor="primary"
                  component={AdapterLink}
                  to="/refi-rates"
                >
                  Refinance
                </Button>
              </Box>
            </Card>
          </Box>
        </CalculatorLayout>
      </DialogContent>
    </Dialog>
  );
};

export default NewOrRefinanceInterstitial;
