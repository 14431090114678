import React from "react";
import { useSelector } from "react-redux";

import { Box, List, ListItem, makeStyles, Typography } from "@material-ui/core";

import Button from "src/components/Button";
import Card from "src/components/Card";
import { getUnconfirmedIncomeAndExpenses } from "src/store/transaction/selector";
import colors from "src/theme/colors";
import { theme } from "src/theme";
import { formatDollarsAndCents } from "src/utils";

const useStyles = makeStyles({
  title: {
    fontSize: "16px !important",
  },
  linkAccount: {
    color: colors.brandingBlue1,
    fontWeight: 500,
    height: 40,
    width: 156,
  },
  content: {
    height: 600,
    overflowY: "auto",
  },
  item: {
    alignItems: "center",
    boxShadow: theme.shadows[3],
    borderRadius: 10,
    minHeight: 55,
    marginTop: 10,
    border: `1px solid ${colors.blueGray7}`,
    padding: `0 5px`,
    display: "flex",
    justifyContent: "space-between",
  },
  subtitle: {
    fontSize: 10,
    color: colors.blueGray2,
  },
  rightSide: {
    alignItems: "center",
    display: "flex",
  },
  amount: {
    color: colors.success,
    marginRight: 12,
  },
  confirmButton: {
    fontSize: 10,
    minWidth: 58,
    width: 58,
  },
});

const ConfirmIncome = ({ openConfirmDialog }: any) => {
  const styles = useStyles();
  const { income } = useSelector(getUnconfirmedIncomeAndExpenses);
  const displayIncome = income;

  return (
    <Card
      titleClassName={styles.title}
      headComponent={
        undefined /*
        <Button
          className={styles.linkAccount}
          variant="outlined"
          fbColor="secondary"
          endIcon={<Icon iconName="fb-add-alt" />}
        >Link an Account
        </Button>
      */
      }
      title="Confirm Income"
    >
      <List className={styles.content}>
        {displayIncome.map((item) => (
          <ListItem className={styles.item} key={item.id}>
            <Box>
              <Typography variant="body1">{item.description}</Typography>
              <Typography className={styles.subtitle} variant="body2">
                {item.date}
              </Typography>
            </Box>
            <Box className={styles.rightSide}>
              <Box className={styles.amount}>
                {item.amount >= 0 ? "-" : "+"}
                {formatDollarsAndCents(Math.abs(item.amount))}
              </Box>
              <Button
                className={styles.confirmButton}
                fbColor="primary"
                onClick={() => openConfirmDialog(item)}
              >
                Confirm
              </Button>
            </Box>
          </ListItem>
        ))}
      </List>
    </Card>
  );
};

export default ConfirmIncome;
