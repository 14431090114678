import React from "react";
import clsx from "clsx";

import { Icon, SvgIcon, IconProps, SvgIconProps } from "@material-ui/core";

interface IFbIconProps {
  iconName: string;
}
const FbIcon = ({
  iconName,
  ...rest
}: IFbIconProps & IconProps & SvgIconProps) => {
  const isCustomIcon = iconName && iconName.includes("fb-");
  if (isCustomIcon) {
    return (
      <SvgIcon {...rest}>
        <use xlinkHref={`/assets/images/svg/sprite.svg#${iconName}`} />
      </SvgIcon>
    );
  }
  const isFaIcon = iconName && iconName.includes("fa-");
  if (isFaIcon) {
    const { className, style, ...props } = rest;
    return (
      <Icon
        className={clsx(className, "fa-thin", iconName)}
        style={{ ...style, fontSize: 19, width: 24 }}
        {...props}
      />
    );
  }
  return <Icon {...rest}>{iconName}</Icon>;
};

export default FbIcon;
