import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";

import { range } from "lodash";

import {
  Box,
  Divider,
  makeStyles,
  MenuItem,
  Select,
  useMediaQuery,
} from "@material-ui/core";

import { cacheRemainingFundsApi } from "src/apiService";
import { planBuilderStyles } from "src/theme";
import {
  getCurrentPlan,
  getCurrentPlanYearlySummary,
} from "src/store/planBuild/selector";
import { getLastGraduationYearMonth } from "src/store/profileBuild/selector";
import { formatWholeDollars } from "src/utils";
import CircularProgressBar from "src/components/CircleProgressChart";
const useStyles = makeStyles(planBuilderStyles);

const currentYear = "" + new Date().getFullYear();

const RightSidebarStudentContent = () => {
  const plan = useSelector(getCurrentPlan);
  const graduationYearMonth = useSelector(getLastGraduationYearMonth);
  const graduationYear = graduationYearMonth[0];
  const years = range(+currentYear, graduationYear + 1);
  let savedYear = localStorage.getItem("yearMemo") || currentYear;
  if (+savedYear > graduationYear) {
    savedYear = "" + graduationYear;
  }
  const styles = useStyles();
  const summaryData: any = useSelector(getCurrentPlanYearlySummary);
  const [selectedYear, setSelectedYear] = useState(savedYear);
  const shortScreen = useMediaQuery("(max-height:720px)");

  const remainingCash = summaryData?.funding?.[selectedYear]
    ? (selectedYear === currentYear
        ? 0
        : summaryData.remaining["" + (+selectedYear - 1)]) +
      summaryData.funding[selectedYear].total -
      summaryData.educationExpenses[selectedYear]?.total -
      summaryData.expenses[selectedYear].total
    : 0;
  const remainingCashStyle =
    remainingCash >= 0 ? styles.emphasis : styles.danger;

  const leftOverFunds = summaryData?.remaining?.[selectedYear] || 0;
  const leftOverFundsStyle =
    leftOverFunds >= 0 ? styles.emphasis : styles.danger;

  useEffect(() => {
    if (plan.id) {
      cacheRemainingFundsApi(plan.id, leftOverFunds);
    }
  }, [plan, summaryData, graduationYear]);

  if (!summaryData?.funding?.[selectedYear]) {
    return <Box />;
  }

  const selectYear = (e: React.ChangeEvent<any>) => {
    localStorage.setItem("yearMemo", e.target.value);
    setSelectedYear(e.target.value);
  };

  const prevYearRemaining =
    selectedYear === currentYear
      ? 0
      : summaryData.remaining["" + (+selectedYear - 1)];

  const chartData = [
    {
      key: "Funds Allocated",
      value:
        prevYearRemaining +
        summaryData.funding[selectedYear].total -
        leftOverFunds,
    },
    { key: "Left Over Funds", value: leftOverFunds },
  ];
  return (
    <>
      {!shortScreen && (
        <Box>
          <CircularProgressBar
            format="list"
            data={chartData}
            labelXOffset={-45}
            labelYOffset={-45}
          />
        </Box>
      )}
      <Box className="w-full">
        <Select
          className={styles.chartOption}
          onChange={selectYear}
          fullWidth={shortScreen}
          value={selectedYear}
          variant="outlined"
          margin="dense"
        >
          {years.map((year: number) => (
            <MenuItem key={year} value={"" + year}>
              {year}
            </MenuItem>
          ))}
        </Select>
      </Box>
      <Divider className="w-full mt-16" />
      <Box className="mb-3">
        <Box className={styles.rightRow}>
          <Box>Previous Yr. Left Over Funds</Box>
          <Box className="text-right">
            {selectedYear === currentYear
              ? "N/A"
              : formatWholeDollars(prevYearRemaining)}
          </Box>
        </Box>
        <Box className={styles.rightRow}>
          <Box>Education Funding</Box>
          <Box className="text-right">
            {formatWholeDollars(summaryData.funding[selectedYear].total)}
          </Box>
        </Box>
        <Box className={styles.rightRow}>
          <Box>Education Costs</Box>
          <Box className="text-right">
            {formatWholeDollars(
              summaryData.educationExpenses[selectedYear].total
            )}
          </Box>
        </Box>
        <Box className={styles.rightRow}>
          <Box>Day-To-Day Expenses</Box>
          <Box className="text-right">
            {formatWholeDollars(summaryData.expenses[selectedYear].total)}
          </Box>
        </Box>
      </Box>
      <Divider />
      <Box className={styles.rightRow}>
        <Box className={remainingCashStyle}>Remaining Cash</Box>
        <Box className={"text-right " + remainingCashStyle}>
          {formatWholeDollars(remainingCash)}
        </Box>
      </Box>
      <Divider className="mt-3" />
      <Box className="mb-3">
        <Box>
          <Box className={styles.rightRow}>
            <Box>Asset Contributions</Box>
            <Box className="text-right">
              {formatWholeDollars(summaryData.assets[selectedYear].total)}
            </Box>
          </Box>
          <Box className={styles.rightRow}>
            <Box>Debt Payments</Box>
            <Box className="text-right">
              {formatWholeDollars(summaryData.debts[selectedYear].total)}
            </Box>
          </Box>
          <Box className={styles.rightRow}>
            <Box>Risk Management</Box>
            <Box className="text-right">
              {formatWholeDollars(summaryData.risks[selectedYear].total)}
            </Box>
          </Box>
        </Box>
      </Box>
      <Divider />
      <Box className={styles.rightRow}>
        <Box className={leftOverFundsStyle}>Left Over Funds</Box>
        <Box className={"text-right " + leftOverFundsStyle}>
          {formatWholeDollars(leftOverFunds)}
        </Box>
      </Box>
    </>
  );
};

export default RightSidebarStudentContent;
