import React from "react";

import { Box } from "@material-ui/core";

export const solutionsText = {
  heading: "FitBUX Solutions & Partnerships",
  description:
    "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Lorem feugiat leo sed at pellentesque scelerisque sit nunc sapien. Ultrices auctor nam enim, sagittis varius. Sit enim.",
};

export const cardsData = [
  {
    heading: "FitBUX Financial Solution",
    description: (
      <Box>
        FitBUX helps you implement your plan by:
        <ol>
          <li>Tracking your financial goals and FitBUX Score over time.</li>
          <li>Holding you accountable to following your financial plan, and</li>
          <li>
            Providing recommendations to help you tailor and manage your loan
            repayment strategy.
          </li>
        </ol>
      </Box>
    ),
    button: { text: "Upgrade", upgrade: true },
  },
  {
    heading: "Student Loan Refinance",
    description:
      "Check how much you could save by refinancing with one of our ten partners.",
    button: { text: "Get rates", value: "refinance" },
    icons: [
      "/assets/images/png/logo_earnest.png",
      "/assets/images/png/logo_sofi.png",
      "/assets/images/png/logo_splashfinancial.png",
      "/assets/images/png/logo_laurelroad.png",
      "/assets/images/png/logo_lendkey.png",
      "/assets/images/png/logo_elfi.png",
    ],
  },
  {
    heading: "In-School Student Loans",
    description:
      "If you are in school and Federal loans leave you short, see if you qualify for a private student loan with one of our partners.",
    button: { text: "Get Rates", value: "newStudentLoans" },
    icons: [
      "/assets/images/png/logo_laurelroad.png",
      "/assets/images/png/logo_lendkey.png",
      "/assets/images/png/logo_salliemae.png",
    ],
  },
  {
    heading: "FitBUX Money School",
    description:
      "Learn what they forgot to teach us! We've helped thousands of Young Professionals dominate student loans, invest, and live life with our online financial course.",
    button: { text: "Upgrade", upgrade: true, value: "moneyschool" },
  },
  {
    heading: "Investment Management",
    description:
      "Get investment advice, rollover an old 401k/403b, or open an IRA account with FitBUX.",
    button: { text: "More Info", to: "betterment" },
    icons: [],
  },
  {
    heading: "Life Insurance",
    description:
      "Reduce the risk of your finances by purchasing life insurance with our partner.",
    button: { text: "Get Rates", value: "policygeniuslife" },
    icons: ["/assets/images/png/logo_policygenius.png"],
  },
  {
    heading: "Disability Insurance",
    description:
      "Reduce the risk of your finances by purchasing disability insurance with our partner.",
    button: { text: "Get Rates", value: "policygeniusdisability" },
    icons: ["/assets/images/png/logo_policygenius.png"],
  },
  {
    heading: "Homeowners Insurance",
    description:
      "Shop insurance with our partner to see how much money you can save.",
    button: { text: "Get Rates", value: "policygeniushome" },
    icons: ["/assets/images/png/logo_policygenius.png"],
  },
];

export const detailData = {
  moneyschool: {},
  betterment: {
    heading: "FitBUX Teams Up with",
    headingImage: "/assets/images/png/logo_betterment_large.png",
    description:
      "We've teamed up with Betterment because they have awesome technology. Open an account below (you'll be taken to Betterment to open the account) or schedule a call with a FitBUX Coach to get personalized investment advice.",
    buttons: [
      /*{ color: "secondary", text: "Schedule a Call", to: "/coach?schedule=true" }, */ {
        color: "primary",
        text: "Access My Betterment Account",
        href:
          "https://wwws.betterment.com/fitbux-ria-llc/app/signup/?advisor_token=qReLJ7i5QUI",
      },
    ],
    img: "/assets/images/png/interstitial_betterment.png",
    value: "betterment",
  },
  policygeniusdisability: {
    heading: "FitBUX Teams Up with",
    headingImage: "/assets/images/png/logo_policygenius_large.png",
    description:
      "We've teamed up with Policy Genius to provide you with competitive rates on disability insurance. When you go to Policy Genius, you'll be given custom rates and a disability insurance specialist to speak with.",
    buttons: [
      {
        color: "primary",
        text: "Check Rates",
        href:
          "http://policygenius.go2cloud.org/aff_c?offer_id=494&aff_id=1860&redirect_slug=fitbux-disability",
      },
      // { color: "secondary", text: "Schedule a Call", to: "/coach?schedule=true" },
    ],
    img: "/assets/images/png/interstitial_policygenius.png",
    value: "policygeniusdisability",
  },
  policygeniuslife: {
    heading: "FitBUX Teams Up with",
    headingImage: "/assets/images/png/logo_policygenius_large.png",
    description:
      "We've teamed up with Policy Genius to provide you with competitive rates on life insurance. When you go to Policy Genius, you'll be given custom rates and a life insurance specialist to speak with.",
    buttons: [
      {
        color: "primary",
        text: "Check Rates",
        href:
          "http://policygenius.go2cloud.org/aff_c?offer_id=493&aff_id=1860&redirect_slug=fitbux-life",
      },
      // { color: "secondary", text: "Schedule a Call", to: "/coach?schedule=true" },
    ],
    img: "/assets/images/png/interstitial_policygenius.png",
    value: "policygeniuslife",
  },
  policygeniushome: {
    heading: "FitBUX Teams Up with",
    headingImage: "/assets/images/png/logo_policygenius_large.png",
    description:
      "We've teamed up with Policy Genius to provide you with competitive rates on homeowner's insurance. When you go to Policy Genius, you'll be given custom rates and a homeowner's insurance specialist to speak with.",
    buttons: [
      {
        color: "primary",
        text: "Check Rates",
        href:
          "https://www.policygenius.com/fitbux-homeowners/?utm_campaign=495&utm_content=&utm_medium=affiliate&utm_source=Fitbux+Services+LLC",
      },
      // { color: "secondary", text: "Schedule a Call", to: "/coach?schedule=true" },
    ],
    img: "/assets/images/png/interstitial_policygenius.png",
    value: "policygeniushome",
  },
  refinance: {
    heading: "Student Loan Refinancing",
    description:
      "This is a free service. Check out how much you could save by refinancing. Get your rates in seconds by visiting our refinancing partners. Getting an offer will not impact your credit. If you need help, schedule a call with a FitBUX Coach. We can help you decide if refinancing is right for you.",
    buttons: [
      {
        color: "secondary",
        text: "Schedule a Call",
        to: "/coach?schedule=true",
      },
    ],
    links: [
      {
        image: "/assets/images/png/logo_splashfinancial.png",
        href: "https://splash-financial.j48ltb.net/c/337506/761189/9516",
      },
      {
        image: "/assets/images/png/logo_sofi.png",
        href:
          "https://refer.sofi.com/c/337506/843614/11190?adcampaigngroup=refi&adnetwork=BD",
      },
      {
        image: "/assets/images/png/logo_laurelroad.png",
        href: "https://www.laurelroad.com/FITBUX",
      },
      {
        image: "/assets/images/png/logo_lendkey.png",
        href: "https://lendkey.7eer.net/c/337506/187810/3276",
      },
      {
        image: "/assets/images/png/logo_earnest.png",
        href:
          "https://partner.earnest.com/referral/adbedeb1-cf2a-42d8-9672-86e58c68501e?utm_source=fitbux&utm_medium=referral",
      },
      {
        image: "/assets/images/png/logo_elfi.png",
        href:
          "https://www.elfi.com/fitbux/?utm_source=fitbux&utm_medium=affiliate&utm_content=refinance",
      },
      {
        image: "/assets/images/png/logo_collegeave.png",
        href:
          "https://www.collegeavestudentloans.com/application/?SC=CARF01&ACT=SS&utm_campaign=affiliate%20ongoing&utm_source=fitbux&utm_medium=aggregator&utm_content=apply%20now&brand=college%20ave&product=refi&p_aff=fitbux&goal=acquisition&agg_ref_id=PLACEHOLDER",
      },
      {
        image: "/assets/images/png/logo_brazos.png",
        href:
          "https://studentloans.com/landing-pages/refi-loan-fitbux.html?utm_campaign=fitbux&utm_medium=referral",
        caption: "(for Texas residents only)",
      },
      {
        image: "/assets/images/png/logo_iowa.png",
        href:
          "https://www.iowastudentloan.org/refinance-loan/?ref=fitbux&cid=fitbux&utm_source=fitbux&utm_medium=referral&utm_campaign=reset&utm_content=fitbux&campaign=fitbux",
      },
    ],
  },
  newStudentLoans: {
    heading: "Private Student Loans",
    description:
      "Get your rates in seconds by visiting our student loan partners. Getting an offer will not impact your credit.",
    links: [
      {
        image: "/assets/images/png/logo_lendkey.png",
        href: "https://lendkey.evyy.net/c/337506/217574/3788",
      },
      {
        image: "/assets/images/png/logo_salliemae.png",
        href: "https://www.salliemae.com/studentloans/fitbux",
      },
      {
        image: "/assets/images/png/logo_collegeave.png",
        href:
          "https://www.collegeavestudentloans.com/application/?SC=CAIS01&ACT=US&utm_campaign=affiliate%20ongoing&utm_source=fitbux&utm_medium=aggregator&utm_content=apply%20now&brand=college%20ave&product=inschool&program=undergrad&p_aff=fitbux&goal=acquisition",
      },
    ],
  },
};
