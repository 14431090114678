import React, { useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { sortBy } from "lodash";

import { Box, makeStyles, Typography } from "@material-ui/core";

import {
  fetchAccountTickersApi,
  getHouseholdPerformanceApi,
} from "src/apiService";
import Button from "src/components/Button";
import Card from "src/components/Card";
import RiskSlider from "src/components/RiskSlider";
import { Account } from "src/interfaces";
import { getInvestmentAccounts } from "src/store/account/selector";
import { getScore } from "src/store/dashboard/selector";
import { getPortfolioPerformance } from "src/store/investments/selector";
import { AdapterLink, scoreToAllocation } from "src/utils";

const useStyles = makeStyles({
  bottomLinks: {
    position: "absolute",
    // display: "flex",
    // justifyContent: "space-between",
    bottom: 10,
    fontSize: 10,
  },
  content: {
    height: 350,
    position: "relative",
  },
});

export const InvestmentAllocationCard = () => {
  const classes = useStyles();
  const score = useSelector(getScore);
  const { standardDeviations } = useSelector(getPortfolioPerformance);
  // const portfolioSettings = useSelector(getPortfolioSettings);
  const [myPerformance, setMyPerformance] = useState<any>(null);
  const [error, setError] = useState(false);
  const fitbuxAllocation = scoreToAllocation(score);
  const accounts: any[] = useSelector(getInvestmentAccounts);
  const [accountInvestments, setAccountInvestments] = useState<any>({});

  const refresh = () => {
    const newInvestments: any = {};
    Promise.all(
      accounts.map((account) =>
        fetchAccountTickersApi(account.id).then((results) => {
          const sorted = sortBy(
            results,
            (item: any) => -(item.quantity * item.close_price)
          );
          newInvestments[account.id] = sorted;
        })
      )
    ).then(() => {
      setAccountInvestments((current: any) => ({
        ...current,
        ...newInvestments,
      }));
    });
  };

  useEffect(refresh, [accounts]);

  const accountBuckets = useMemo(() => {
    const withInvestments: Account[] = [];
    const withoutInvestments: Account[] = [];
    accounts.forEach((account) => {
      if (accountInvestments[account.id]?.length) {
        withInvestments.push(account);
      } else {
        withoutInvestments.push(account);
      }
    });
    return { withInvestments, withoutInvestments };
  }, [accountInvestments]);

  useEffect(() => {
    getHouseholdPerformanceApi()
      .then((result) => setMyPerformance(result.inception))
      .catch(() => setError(true));
  }, []);

  const renderContent = () => {
    if (error || !myPerformance || accountBuckets.withoutInvestments.length) {
      return (
        <>
          <Typography className="my-10">
            You either don't have investment accounts on your profile or you
            have investment account balances but haven't included the details of
            your holdings.
          </Typography>
          <Button
            fbColor="primary"
            component={AdapterLink}
            to="/investment-allocations?return=summary"
          >
            Click Here To Add/Edit Your Investment Holdings
          </Button>
        </>
      );
    }

    const fbStdDev = standardDeviations["" + fitbuxAllocation];
    const myStdDev = myPerformance.standard_deviation * 100;
    return (
      <Box className={classes.content}>
        <Box className="pt-20">
          <RiskSlider
            sliderWidth={300}
            fitbuxStdDev={fbStdDev}
            myStdDev={myStdDev}
          />
        </Box>
        <Box className={classes.bottomLinks}>
          <Box>
            <AdapterLink to="/investment-allocations?return=summary">
              Review My Current Portfolio's Allocations and Risk
            </AdapterLink>
          </Box>
          <Box>
            <AdapterLink to="/investment-allocations?return=summary">
              See How My Risk And Returns Change When I Adjust My Allocations
            </AdapterLink>
          </Box>
        </Box>
      </Box>
    );
  };

  return (
    <Card title="Investment Allocation" className="mb-12 h-full">
      {renderContent()}
    </Card>
  );
};

export default InvestmentAllocationCard;
