import React from "react";
import { IconButton, makeStyles } from "@material-ui/core";
import Icon from "src/components/Icon";
import colors from "src/theme/colors";

interface IProps {
  title: string;
  handleClick?: VoidFunction;
}

const ProfileInformationCard = ({ title, handleClick }: IProps) => {
  const styles = useStyles();
  return (
    <div className={styles.root}>
      <p className={styles.title}>{title}</p>
      <IconButton color="primary" onClick={handleClick}>
        <Icon iconName="fb-edit" />
      </IconButton>
    </div>
  );
};

const useStyles = makeStyles({
  root: {
    borderRadius: 12,
    background: colors.white,
    padding: 12,
    marginRight: 10,
    marginBottom: 10,
    border: "1px solid #E8F1F5",
    width: 160,
    minWidth: 160,
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  title: {
    color: colors.blueGray2,
    fontSize: 13,
    fontWeight: 400,
    margin: "0px 10px 0px 0px",
  },
});
export default ProfileInformationCard;
