import React, { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { push } from "connected-react-router";

import { Finance } from "financejs";

// import { makeStyles, Theme } from "@material-ui/core";

import Button from "src/components/Button";
import CalculatorLayout from "src/layouts/CalculatorLayout";
import StickyFooter from "src/components/StickyFooter";
import NewOrRefinanceInterstitial from "src/pages/Dashboard/ToolsAndProducts/HomeBuying/NewOrRefinanceInterstitial";
import { getAccounts } from "src/store/account/selector";
import { getCashflows } from "src/store/cashflow/selector";
import { MortgageCalculatorSummary } from "src/interfaces";
import { AdapterLink, payDownData } from "src/utils";

import { DISCLAIMER, STEP } from "./Constants";
import MortgageDetails from "./MortgageDetails";
import MonthlyPrePayment from "./MonthlyPrepayment";
import { calculateDebtToIncome, convertTermToDate, getTerm } from "./helper";

const finance = new Finance();

const MortgageRepaymentCalculator = ({ isHeloc }: any) => {
  const [step, setStep] = useState(STEP.MORTGAGE_DETAILS);
  const { income }: any = useSelector(getCashflows);
  const [data, setData] = useState<{
    prePayment: Array<number>;
    normal: Array<number>;
  }>({ prePayment: [], normal: [] });
  // const classes = useStyles();
  const dispatch = useDispatch();
  const [monthlyPayment, setMonthlyPayment] = useState<any>("");
  const [prePayment, setPrePayment] = useState<number>(0);
  const [result, setResult] = useState<MortgageCalculatorSummary | undefined>();
  const [isCalculated, setCalculated] = useState(false);
  const [totalIncome, setTotalIncome] = useState(0);
  const [showInterstitial, setShowInterstitial] = useState(false);

  const [mortgageDetails, setMortgageDetails] = useState<{
    month: number;
    year: number;
    rate: number;
    balance: number;
    term: number;
  }>({
    month: 0,
    year: isHeloc ? 20 : 0,
    rate: isHeloc ? 5.5 : 0,
    balance: isHeloc ? 50000 : 0,
    term: isHeloc ? 20 : 0,
  });

  const totalMonthlyPayment = +prePayment + +monthlyPayment;

  const accounts = useSelector(getAccounts);

  useEffect(() => {
    if (!isHeloc) {
      let debt: any = null;
      accounts.forEach((account) => {
        if (account.variable !== "home_loan") return;
        if (!debt) return (debt = account);

        if (account.rate > debt.rate) {
          debt = account;
        }
      });
      if (!debt) return;
      const { month, year, term } = getTerm(debt);
      setMortgageDetails({
        month: year >= 30 ? 0 : month,
        year: year > 30 ? 30 : year,
        rate: debt.rate.toFixed(3),
        balance: Math.round(debt.balance),
        term: term,
      });
    }
  }, [isHeloc]);

  useEffect(() => {
    if (!income) return;
    let totalIncome = 0;
    income.forEach((a: any) => {
      if (a?.inschool) return;
      totalIncome = totalIncome + a.monthly;
    });
    setTotalIncome(totalIncome);
  }, [income]);

  const handleFormChange = (value: any, key: string) => {
    setMortgageDetails((pre) => {
      switch (key) {
        case "year":
          if (value === 30) {
            return {
              ...pre,
              term: +(30).toFixed(1),
              month: 0,
              [key]: value,
            };
          }
          return {
            ...pre,
            term: +((value * 12 + pre.month) / 12).toFixed(1),
            [key]: value,
          };
        case "month":
          return {
            ...pre,
            term: +((pre.year * 12 + value) / 12).toFixed(1),
            [key]: value,
          };
        case "rate":
          return {
            ...pre,
            [key]: value.toFixed(3),
          };
        case "balance":
          return {
            ...pre,
            [key]: value,
          };
        default:
          return {
            ...pre,
            [key]: value,
          };
      }
    });
  };

  useEffect(() => {
    const { year, month, balance, rate } = mortgageDetails;
    if (year === 0 && month === 0) {
      setMonthlyPayment(0);
      return;
    }
    const term = year === 30 ? 12 * year : 12 * year + month;
    const i = rate / 100 / 12;
    const pmt = finance.PMT(i, term, -balance);
    setMonthlyPayment(+pmt.toFixed(2));
  }, [mortgageDetails]);

  const handleNextButton = () => setStep(STEP.MONTHLY_REPAYMENTS);
  const handleBackButton = () => {
    setStep(STEP.MORTGAGE_DETAILS);
    setCalculated(false);
  };

  const handlePrePaymentChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setPrePayment(+e.target.value);
  };

  const handleCalculate = () => {
    const { balance, rate } = mortgageDetails;
    const { term: newTermWithPrePayment } = getTerm({
      balance,
      rate,
      payment: prePayment + +monthlyPayment,
    });
    const { term: normalTerm } = getTerm({
      balance,
      rate,
      payment: monthlyPayment,
    });

    const monthlyRate = rate / 100 / 12;
    const normal = payDownData(balance, monthlyRate, monthlyPayment);
    const withPrePayment = payDownData(
      balance,
      monthlyRate,
      monthlyPayment + prePayment
    );

    const normalResult = {
      monthlyPayment: +monthlyPayment,
      monthlyPrePayment: 0,
      totalMonthlyPayment: +monthlyPayment,
      debtToIncome: calculateDebtToIncome(totalIncome, +monthlyPayment),
      cumulativePaymentAmount: normalTerm * +monthlyPayment * 12,
      repaymentPeriod: +normalTerm.toFixed(1),
      dateOfCompletions: convertTermToDate(mortgageDetails.term),
    };
    const resultWithPrePayment = {
      monthlyPayment: +monthlyPayment,
      monthlyPrePayment: prePayment,
      totalMonthlyPayment: totalMonthlyPayment,
      debtToIncome: calculateDebtToIncome(totalIncome, totalMonthlyPayment),
      cumulativePaymentAmount: newTermWithPrePayment * totalMonthlyPayment * 12,
      repaymentPeriod: +newTermWithPrePayment.toFixed(1),
      dateOfCompletions: convertTermToDate(newTermWithPrePayment),
    };

    setResult({
      normal: normalResult,
      withPrePayment: resultWithPrePayment,
    });
    setData({
      normal: normal,
      prePayment: withPrePayment,
    });

    setCalculated(true);
  };

  const formattedData = useMemo(() => {
    if (!data || !data.normal.length) {
      return [];
    }
    return data.normal.map((value, index) => ({
      month: index,
      normal: data.normal[index],
      prepay: data.prePayment[index],
    }));
  }, [data]);

  const backToTools = () => {
    dispatch(push("/homebuying"));
  };

  const renderStep = () => {
    switch (step) {
      case STEP.MORTGAGE_DETAILS: {
        return (
          <MortgageDetails
            isHeloc={isHeloc}
            mortgageDetails={mortgageDetails}
            onChange={handleFormChange}
            monthlyPayment={monthlyPayment}
            onNextClick={handleNextButton}
          />
        );
      }
      case STEP.MONTHLY_REPAYMENTS: {
        return (
          <MonthlyPrePayment
            monthlyPayment={monthlyPayment}
            prePayment={prePayment}
            onPrePaymentChange={handlePrePaymentChange}
            onBackClick={handleBackButton}
            onCalculate={handleCalculate}
            mortgageDetails={mortgageDetails}
            totalMonthlyPayment={totalMonthlyPayment}
            isCalculated={isCalculated}
            totalIncome={totalIncome}
            formattedData={formattedData}
            result={result}
          />
        );
      }

      default: {
        return (
          <MortgageDetails
            isHeloc={isHeloc}
            mortgageDetails={mortgageDetails}
            onChange={handleFormChange}
            monthlyPayment={monthlyPayment}
            onNextClick={handleNextButton}
          />
        );
      }
    }
  };

  return (
    <CalculatorLayout
      title={
        isHeloc ? "HELOC Repayment Calculator" : "Mortgage Repayment Calculator"
      }
      onBackClick={backToTools}
      disclaimer={DISCLAIMER}
      footer={
        result ? (
          <StickyFooter
            text={
              isHeloc
                ? "Apply for a HELOC with one of FitBUX's lending partners."
                : "Check purchase or refinance rates with FitBUX's mortgage lending partners."
            }
          >
            {isHeloc ? (
              <Button
                fbColor="primary"
                component={AdapterLink}
                to="/heloc-rates"
                className="mr-20"
              >
                Check My Rates
              </Button>
            ) : (
              <Button
                className="mr-10"
                fbColor="accent"
                onClick={() => setShowInterstitial(true)}
              >
                Check My Rates
              </Button>
            )}
          </StickyFooter>
        ) : null
      }
    >
      {renderStep()}
      <NewOrRefinanceInterstitial
        open={showInterstitial}
        onClose={() => setShowInterstitial(false)}
      />
    </CalculatorLayout>
  );
};

// const useStyles = makeStyles((theme: Theme) => ({
//   root: {
//     width: "100%",
//     display: "flex",
//     minHeight: "calc(100vh)",
//     flexDirection: "column",
//   },
//   container: {
//     flex: 1,
//     display: "flex",
//   },
//   disclaimerContainer: {
//     marginBottom: 60,
//     padding: theme.spacing(2, 8, 4, 2),
//   },
// }));

export default MortgageRepaymentCalculator;
