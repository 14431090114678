import React from "react";
import { useDispatch, useSelector } from "react-redux";

// import {
//   makeStyles,
// } from "@material-ui/core";

import { formatAnnually, formatMonthly, formatPercent } from "src/utils";

import {
  addRisk,
  editRisk,
  removeRisk,
  savePlan,
} from "src/store/planBuild/actions";
import { PLAN_BUILD_STEPS } from "src/store/planBuild/constants";
import {
  currentPlanIncomeTotal,
  currentPlanRiskManagementTotal,
  getCurrentPlanCashflows,
} from "src/store/planBuild/selector";
import {
  RISK_MANAGEMENT_TYPES,
  IncomeExpenses,
  MappedCashflow,
  Expense,
  PlanViewComponent,
} from "src/interfaces";
import CenterContainer from "../../Components/CenterContainer";
import BasicCard from "src/components/BasicCard";
import { riskBlowout } from "./blowoutContent";

const MainForm: PlanViewComponent = ({ render }) => {
  const dispatch = useDispatch();
  const { risks }: IncomeExpenses = useSelector(getCurrentPlanCashflows);
  const totalIncome = useSelector(currentPlanIncomeTotal);
  const total = useSelector(currentPlanRiskManagementTotal);

  const saveItem = (
    item: MappedCashflow,
    updateFields: Partial<MappedCashflow>,
    index: number
  ) => {
    const updatePayload = { ...item, ...updateFields };
    const update = {
      payment: Math.abs(updatePayload.annual || 0),
      type: updatePayload.type,
    };
    dispatch(editRisk({ index, update }));
    dispatch(savePlan(null));
  };

  const onNext = () => dispatch(savePlan(PLAN_BUILD_STEPS.SAVINGS));

  const availableItems = RISK_MANAGEMENT_TYPES;

  const addItem = (label: string, key: string) => {
    dispatch(
      addRisk({
        type: key,
        payment: 0,
      })
    );
  };

  const removeItem = (item: Expense, index: number) => {
    dispatch(removeRisk(index));
  };

  return render({
    component: (
      <CenterContainer
        title="Risk Management"
        iconName="fb-manage-protection"
        help={riskBlowout}
        scrollable
      >
        <BasicCard
          addLabel="Add Insurance &amp; Premium Payments"
          hideTitle
          title="Insurance"
          columns={[
            { label: "Type", field: "type", type: "fixed", width: "30%" },
            {
              label: "% of Total Income",
              field: "percent",
              type: "percent",
              width: "30%",
              formatter: formatPercent,
            },
            {
              label: "Monthly",
              field: "monthly",
              type: "number",
              width: "20%",
              formatter: formatMonthly,
            },
            {
              label: "Annual",
              field: "annual",
              type: "number",
              width: "20%",
              formatter: formatAnnually,
            },
          ]}
          data={risks}
          addItems={availableItems}
          onAddFromSelect={addItem}
          onDelete={removeItem}
          onSave={saveItem}
          className="p-0 shadow-none"
          total={totalIncome}
          summaryRow={
            !risks.length
              ? undefined
              : {
                  type: "Total",
                  monthly: Math.round(total / 12),
                  annual: total,
                  percent: (total / totalIncome) * 100,
                }
          }
        />
      </CenterContainer>
    ),
    onNext,
    nextLabel: "Next Section",
  });
};

export default MainForm;
