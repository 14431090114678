import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useStyles } from "./styles";
import { Box, List, Typography } from "@material-ui/core";

import Button from "src/components/Button";
import { PlanViewComponent, SidebarContent } from "src/interfaces";
import { savePlan, updateAllocations } from "src/store/planBuild/actions";
import {
  currentPlanIncomeTotal,
  getCurrentPlan,
} from "src/store/planBuild/selector";
import { ShortTermGoals } from "src/interfaces";

import CenterContainer from "../../Components/CenterContainer";
import { CURRATED_PLAN_BUILD_STEPS } from "./common";
import PercentBreakdown from "src/components/PercentBreakdown";

const ShortTemSavingsGoal: PlanViewComponent = ({
  onClose,
  onSave,
  render,
  curatedIndex,
}) => {
  const dispatch = useDispatch();
  const styles = useStyles();
  const totalIncome = useSelector(currentPlanIncomeTotal);
  const plan = useSelector(getCurrentPlan);
  const [formState, setFormState] = useState<any>({
    type: "cash_value",
    percent: "",
  });
  const shortTermEvents = plan.lifeevents.filter(
    (lifeEvent) =>
      ShortTermGoals[lifeEvent.eventtype as keyof typeof ShortTermGoals]
  );

  const save = () => {
    if (formState.percent) {
      const newAllocations = {
        ...plan.allocations[0],
        [formState.type]: formState.percent,
      };
      dispatch(updateAllocations(newAllocations));
      dispatch(savePlan(null));
    }
    onSave();
  };

  // let max = 0;

  // map over the short term goals that where input in from graduatedLifeEvents and render each one
  const getSavingsGoals = () => {
    if (shortTermEvents.length) {
      return (
        <List>
          {shortTermEvents.map((shortTermGoal: any, index: number) => (
            <Typography>
              {index + 1}){" "}
              {
                ShortTermGoals[
                  shortTermGoal.eventtype as keyof typeof ShortTermGoals
                ]
              }
            </Typography>
          ))}
        </List>
      );
    } else {
      return <div />;
    }
  };

  return render({
    component: (
      <CenterContainer
        scrollable
        title={"Short-term Goals (Savings/Checking)"}
        iconName={"fb-model"}
        help={help}
      >
        {curatedIndex === CURRATED_PLAN_BUILD_STEPS.SHORT_TERM_GOAL && (
          <Box className="mt-5">
            {!!shortTermEvents.length && (
              <Typography className="mt-5">
                In Step 1, you indicated you have a short-term goal(s) to:
              </Typography>
            )}
            <Box>{getSavingsGoals()}</Box>
            <Typography>
              How much would you like to save towards your short-term goal(s)
              each month?
            </Typography>
          </Box>
        )}

        <Box className="my-6">
          <PercentBreakdown
            // min={min}
            income={totalIncome}
            onChange={setFormState}
            values={formState}
            label="Savings"
          />
        </Box>

        {!curatedIndex && (
          <Button
            variant="outlined"
            fbColor="primary"
            onClick={onClose}
            className={styles.btnCancel}
          >
            Cancel
          </Button>
        )}
      </CenterContainer>
    ),
    nextLabel: "Save",
    onNext: save,
  });
};

export default ShortTemSavingsGoal;

const help: SidebarContent[] = [
  {
    header: "Focus Is Key",
    body: [
      "Although you may have multiple goals, you are more likely to accomplish more in the long run the more you focus.  Therefore, we ask you how much you'd like to save only to the goal that is going to occur soonest.  Once you accomplish that goal, we assume you will start saving for the next one.",
    ],
  },
  {
    header: "% of Total Income",
    body: [
      "Analyzing your financial plan by percentages is much easier and more informative than looking at dollar amounts.",
      "1) When building your financial plan, looking at where the percentages of your income are going makes it easier to visualize the most impactful items in your finances. It also makes it easy to make adjustments.",
      "2) Implementing your plan when using percentages is easier because you don't have to guess and it makes you more accountable. For example, if you get a pay raise, a bonus, or a bigger tax return, etc., you don't have to guess as to where to allocate the extra funds. Simply look at where the percentages of your income are supposed to go based on your plan and proceed accordingly.",
    ],
  },
];
