import React from "react";
import { makeStyles } from "@material-ui/core";
import Button from "src/components/Button";
import Icon from "src/components/Icon";
import colors from "src/theme/colors";

interface IState {
  heading: string;
  label: string;
  iconName: string;
  btnLabel: string;
  isEdit?: boolean;
  handleClick: () => void;
}

const SubscriptionCard = ({
  heading,
  iconName,
  label,
  isEdit,
  btnLabel,
  handleClick,
}: IState) => {
  const styles = useStyles();
  return (
    <div className={styles.root}>
      <div className={styles.leftBox}>
        <Icon iconName={iconName} />
        <p className={styles.title}>{heading}</p>
        <p className={styles.label}>{label}</p>
      </div>
      <Button
        variant={isEdit ? "outlined" : "contained"}
        fbColor="primary"
        onClick={handleClick}
        className={`w-max ${styles.button} ${isEdit && styles.space}`}
        endIcon={isEdit && <Icon iconName="fb-edit" />}
      >
        {btnLabel}
      </Button>
    </div>
  );
};

const useStyles = makeStyles({
  root: {
    borderRadius: 12,
    background: colors.white,
    padding: 12,
    marginTop: 12,
    border: "1px solid #E8F1F5",
    width: "100%",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  leftBox: {
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "center",
    width: "100%",
    "& svg": {
      fill: colors.brandingBlue1,
    },
  },
  title: {
    color: colors.brandingBlue1,
    fontSize: 13,
    fontWeight: 600,
    margin: "0px 10px 0px 25px",
  },
  label: {
    color: colors.blueGray1,
    fontSize: 12,
    fontWeight: 400,
    margin: 0,
  },
  button: {
    width: "100%",
    "&>span": {
      width: "100%",
      display: "flex",
      fontSize: 12,
      fontWeight: 500,
    },
  },
  space: {
    justifyContent: "space-between",
  },
});
export default SubscriptionCard;
