import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { cloneDeep, map } from "lodash";
import colors from "src/theme/colors";

import {
  Box,
  Divider,
  makeStyles,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@material-ui/core";
import { Autocomplete } from "@material-ui/lab";

import CenterContainer from "../../Components/CenterContainer";
import {
  Graduation,
  REPAYMENT_PLANS,
  SPECIAL_REPAYMENTS,
  Profile,
} from "src/interfaces";
import { getAccounts } from "src/store/account/selector";
import { savePlan, updateCurrentPlan } from "src/store/planBuild/actions";
import { PLAN_BUILD_STEPS } from "src/store/planBuild/constants";
import { fetchProfessions, updateDebts } from "src/store/profileBuild/actions";
import {
  getProfessions,
  getProfileLoaded,
  getProfileLoading,
  getProfile,
  getSpouseProfile,
  getSpouseInSchool,
  getUserInSchool,
} from "src/store/profileBuild/selector";
import { getIsMarried } from "src/store/system/selector";
import { getCurrentPlan } from "src/store/planBuild/selector";
import { PlanViewComponent, Plan } from "src/interfaces";
import { DollarTextField } from "src/utils";

const useStyles = makeStyles({
  root: {
    borderRadius: 12,
    marginBottom: 10,
  },
  content: {
    padding: 15,
  },
  header: {
    display: "flex",
    justifyContent: "space-between",
    color: colors.brandingBlue1,
    marginBottom: 20,
    fontSize: 14,
    marginTop: 50,
  },
  inputRow: {
    alignItems: "center",
    display: "flex",
    justifyContent: "space-between",
    marginBottom: 15,
    marginTop: 15,
  },
  rowHeader: {
    fontSize: 14,
  },
  symbol: {
    fontSize: "2em",
    color: colors.brandingBlue1,
  },
});

const FutureQuestions: PlanViewComponent = ({ render }) => {
  const styles = useStyles();
  const dispatch = useDispatch();
  const loading: any = useSelector(getProfileLoading);
  const loaded: any = useSelector(getProfileLoaded);
  const professions: any[] = useSelector(getProfessions);
  const isMarried = useSelector(getIsMarried);
  const profile: Profile = useSelector(getProfile);
  const spouseProfile: Profile = useSelector(getSpouseProfile);
  const spouseInSchool = useSelector(getSpouseInSchool);
  const userInSchool = useSelector(getUserInSchool);
  const currentPlan: Plan = useSelector(getCurrentPlan);
  const studentLoanDetails = currentPlan.studentloan;
  const accounts: any[] = useSelector(getAccounts);
  let hasFedLoans = !!accounts.find(
    (account) =>
      account.variable === "fed_loan" &&
      (account.who || account.whose) !== "spouse"
  );
  if (!hasFedLoans) {
    hasFedLoans =
      !!currentPlan.allocations[0]?.solo?.[0]?.fed_loan ||
      !!(currentPlan?.education || []).find(
        (fundingItem) =>
          fundingItem.type === "fed_loan" &&
          !!fundingItem.payment &&
          fundingItem.who !== "spouse"
      );
  }
  let spouseFedLoans = !!accounts.find(
    (account) =>
      account.variable === "fed_loan" &&
      (account.who || account.whose) === "spouse"
  );
  if (!spouseFedLoans) {
    spouseFedLoans =
      !!currentPlan.allocations[0]?.solo?.[1]?.fed_loan ||
      !!(currentPlan?.education || []).find(
        (fundingItem) =>
          fundingItem.type === "fed_loan" &&
          !!fundingItem.payment &&
          fundingItem.who === "spouse"
      );
  }
  const myGraduationIndex = currentPlan.lifeevents.findIndex(
    (event: any) => event.eventtype === "graduate" && event.who !== "spouse"
  );
  const myGraduationEvent =
    myGraduationIndex >= 0
      ? (currentPlan.lifeevents[myGraduationIndex] as Graduation)
      : {
          eventtype: "graduate",
          date: "2023-05",
          profession: "29-9999",
          salary: 40000,
          who: "applicant",
        };
  let spouseGraduationIndex = -1;
  let spouseGraduationEvent: any = {};
  if (isMarried) {
    spouseGraduationIndex = currentPlan.lifeevents.findIndex(
      (event: any) => event.eventtype === "graduate" && event.who === "spouse"
    );
    if (spouseGraduationIndex >= 0) {
      spouseGraduationEvent = currentPlan.lifeevents[
        spouseGraduationIndex
      ] as Graduation;
    }
  }
  const [formValues, setFormValues] = useState({
    myProfession: myGraduationEvent.profession || "",
    myRepaymentPlan:
      studentLoanDetails?.[0]?.repayplan || profile.fed_repayment_plan || "",
    myProjectedIncome: myGraduationEvent.salary || "",
    myPslf: studentLoanDetails?.[0]?.idroption === "pslf" ? "y" : "n",
    spouseProfession: spouseGraduationEvent.profession || "",
    spouseRepaymentPlan:
      studentLoanDetails?.[1]?.repayplan ||
      spouseProfile?.fed_repayment_plan ||
      "",
    spouseProjectedIncome: spouseGraduationEvent.salary || "",
    spousePslf: studentLoanDetails?.[1]?.idroption === "pslf" ? "y" : "n",
  });

  useEffect(() => {
    if (!loaded.professions && !loading.professions) {
      dispatch(fetchProfessions());
    }
  }, [dispatch]);

  const handleChange = (prop: string) => (
    event: React.ChangeEvent<any>,
    t?: any
  ) => {
    let value = event.target.value;
    if (prop === "myProfession" || prop === "spouseProfession") {
      // autocomplete
      value = t?.id || undefined;
    }
    setFormValues({ ...formValues, [prop]: value });
  };

  const onNext = () => {
    const lifeevents = [...currentPlan.lifeevents];
    lifeevents[myGraduationIndex] = {
      ...myGraduationEvent,
      profession: formValues.myProfession,
      salary: formValues.myProjectedIncome,
    } as Graduation;
    const newPlanStudentLoans = cloneDeep(currentPlan.studentloan);
    if (isMarried && spouseInSchool && spouseGraduationIndex >= 0) {
      lifeevents[spouseGraduationIndex] = {
        ...spouseGraduationEvent,
        profession: formValues.spouseProfession,
        salary: formValues.spouseProjectedIncome,
      } as Graduation;
      if (spouseFedLoans) {
        newPlanStudentLoans[1].repayplan = formValues.spouseRepaymentPlan;
        if (
          SPECIAL_REPAYMENTS.indexOf(
            formValues.spouseRepaymentPlan as string
          ) >= 0
        ) {
          newPlanStudentLoans[1].idroption =
            formValues.spousePslf === "y" ? "pslf" : "standard";
        } else {
          newPlanStudentLoans[1].idroption = "standard";
        }
        dispatch(
          updateDebts({
            who: "spouse",
            update: {
              fed_repayment_plan: formValues.spouseRepaymentPlan as string,
            },
          })
        );
      }
    }
    if (hasFedLoans) {
      newPlanStudentLoans[0].repayplan = formValues.myRepaymentPlan;
      if (
        SPECIAL_REPAYMENTS.indexOf(formValues.myRepaymentPlan as string) >= 0
      ) {
        newPlanStudentLoans[0].idroption =
          formValues.myPslf === "y" ? "pslf" : "standard";
      } else {
        newPlanStudentLoans[0].idroption = "standard";
      }
      dispatch(
        updateDebts({
          who: "applicant",
          update: { fed_repayment_plan: formValues.myRepaymentPlan as string },
        })
      );
    }
    dispatch(
      updateCurrentPlan({ lifeevents, studentloan: newPlanStudentLoans })
    );
    dispatch(savePlan(PLAN_BUILD_STEPS.LIFE_EVENTS_OVERVIEW));
  };

  return render({
    component: (
      <CenterContainer title="Future Questions" iconName="fb-life-saver">
        {userInSchool && (
          <>
            <Box className={styles.inputRow}>
              <Typography variant="h3" className={styles.rowHeader}>
                What will be your profession post-graduation?
              </Typography>
              <Box className="w-64">
                <Autocomplete
                  options={professions || []}
                  disabled={loading.professions}
                  getOptionLabel={(option) => option?.name || "-"}
                  fullWidth
                  onChange={handleChange("myProfession")}
                  value={
                    (professions || []).find(
                      (profession: { id: string | undefined }) =>
                        profession?.id === formValues.myProfession
                    ) || ""
                  }
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      variant="outlined"
                      placeholder="Profession"
                    />
                  )}
                />
              </Box>
            </Box>
            {!!hasFedLoans && (
              <>
                <Box className={styles.inputRow}>
                  <Typography variant="h3" className={styles.rowHeader}>
                    What Federal Student Loan repayment plan will you use post
                    graduation?
                  </Typography>
                  <Box className="w-36">
                    <Select
                      variant="outlined"
                      name="myRepaymentPlan"
                      fullWidth
                      value={formValues.myRepaymentPlan}
                      onChange={handleChange("myRepaymentPlan")}
                    >
                      {map(REPAYMENT_PLANS, (label: string, value: string) => (
                        <MenuItem key={value} value={value}>
                          {label}
                        </MenuItem>
                      ))}
                    </Select>
                  </Box>
                </Box>
                {SPECIAL_REPAYMENTS.indexOf(
                  formValues.myRepaymentPlan as string
                ) >= 0 && (
                  <Box className={styles.inputRow}>
                    <Typography variant="h3" className={styles.rowHeader}>
                      Do you plan on pursuing public service loan forgiveness?
                    </Typography>
                    <Box className="w-36">
                      <Select
                        variant="outlined"
                        name="myPslf"
                        fullWidth
                        value={formValues.myPslf}
                        onChange={handleChange("myPslf")}
                      >
                        <MenuItem value="n">No</MenuItem>
                        <MenuItem value="y">Yes</MenuItem>
                      </Select>
                    </Box>
                  </Box>
                )}
              </>
            )}
            <Box className={styles.inputRow}>
              <Typography variant="h3" className={styles.rowHeader}>
                Your starting income post-gradution is expected to be:
              </Typography>
              <Box className="w-36">
                <DollarTextField
                  variant="outlined"
                  name="myProjectedIncome"
                  fullWidth
                  value={formValues.myProjectedIncome}
                  onChange={handleChange("myProjectedIncome")}
                />
              </Box>
            </Box>
          </>
        )}
        {isMarried && !!spouseInSchool && (
          <>
            <Divider />
            <Box className={styles.inputRow}>
              <Typography variant="h3" className={styles.rowHeader}>
                What will be your spouse's profession post-graduation?
              </Typography>
              <Box className="w-64">
                <Autocomplete
                  options={professions || []}
                  disabled={loading.professions}
                  getOptionLabel={(option) => option?.name || "-"}
                  fullWidth
                  placeholder="Profession"
                  onChange={handleChange("spouseProfession")}
                  value={
                    (professions || []).find(
                      (profession: { id: string | undefined }) =>
                        profession?.id === formValues.spouseProfession
                    ) || ""
                  }
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      variant="outlined"
                      placeholder="Profession"
                    />
                  )}
                />
              </Box>
            </Box>
            {!!spouseFedLoans && (
              <>
                <Box className={styles.inputRow}>
                  <Typography variant="h3" className={styles.rowHeader}>
                    What Federal Student Loan repayment plan will your spouse
                    use post graduation?
                  </Typography>
                  <Box className="w-36">
                    <Select
                      variant="outlined"
                      name="spouseRepaymentPlan"
                      fullWidth
                      value={formValues.spouseRepaymentPlan}
                      onChange={handleChange("spouseRepaymentPlan")}
                    >
                      {map(REPAYMENT_PLANS, (label: string, value: string) => (
                        <MenuItem key={value} value={value}>
                          {label}
                        </MenuItem>
                      ))}
                    </Select>
                  </Box>
                </Box>
                {SPECIAL_REPAYMENTS.indexOf(
                  formValues.spouseRepaymentPlan as string
                ) >= 0 && (
                  <Box className={styles.inputRow}>
                    <Typography variant="h3" className={styles.rowHeader}>
                      Does your spouse plan on pursuing public service loan
                      forgiveness?
                    </Typography>
                    <Box className="w-36">
                      <Select
                        variant="outlined"
                        name="spousePslf"
                        fullWidth
                        value={formValues.spousePslf}
                        onChange={handleChange("spousePslf")}
                      >
                        <MenuItem value="n">No</MenuItem>
                        <MenuItem value="y">Yes</MenuItem>
                      </Select>
                    </Box>
                  </Box>
                )}
              </>
            )}
            <Box className={styles.inputRow}>
              <Typography variant="h3" className={styles.rowHeader}>
                Your spouse's starting income post-gradution is expected to be:
              </Typography>
              <Box className="w-36">
                <DollarTextField
                  variant="outlined"
                  name="spouseProjectedIncome"
                  fullWidth
                  value={formValues.spouseProjectedIncome}
                  onChange={handleChange("spouseProjectedIncome")}
                />
              </Box>
            </Box>
          </>
        )}
      </CenterContainer>
    ),
    nextDisabled: !formValues.myProjectedIncome,
    nextTooltip: formValues.myProjectedIncome
      ? undefined
      : "Please enter your expected future income to proceed.",
    onNext,
  });
};

export default FutureQuestions;
