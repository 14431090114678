import { createSelector } from "reselect";
import { AppState } from "..";
import {
  Account,
  ASSET_TYPES,
  DEBT_TYPES,
  AssetsDebts,
  MappedAccount,
} from "../../interfaces";
import { getLiveShortTermSavings } from "../planBuild/selector";
import { getHasPlan } from "../system/selector";

const INVESTABLE_ACCOUNT_TYPES = new Set([
  "roth_ira_value",
  "401k_value",
  "roth_401k_value",
  "ira_value",
  "other_retirement_value",
  "other_investments_value",
  "hsa_value",
  "crypto",
]);

export const getAccountState = (state: AppState) => state.account;

export const getAccounts = createSelector(
  getAccountState,
  (state) => state.accounts
);
export const getPublicToken = createSelector(
  getAccountState,
  (state) => state.publicToken
);
export const getAccountsLoaded = createSelector(
  getAccountState,
  (state) => state.loaded
);
export const getAccountsLoading = createSelector(
  getAccountState,
  (state) => state.loading
);
export const getAccountProviders = createSelector(
  getAccountState,
  (state) => state.providers
);
export const getLinkedAccounts = createSelector(getAccountState, (state) =>
  state.linkedAccounts.filter((account) => account.category !== "closed")
);
export const getLoadedAccountDetails = createSelector(
  getAccountState,
  (state) => state.loadedAccountDetails
);
export const getLinkedAccountsLoading = createSelector(
  getAccountState,
  (state) => state.loadingLinkedAccounts
);
export const getCashTotal = createSelector(
  [getAccounts, getHasPlan, getLiveShortTermSavings],
  (accounts: Account[], hasPlan: boolean, shortTermSavings: number) => {
    const total = accounts.reduce((result, account) => {
      if (account.variable === "cash_value") {
        return result + (account.balance || 0);
      }
      return result;
    }, 0);
    if (!hasPlan || !shortTermSavings) {
      return total;
    }
    return Math.max(0, total - shortTermSavings);
  }
);

// export const getOpenAccountIndex = createSelector(getAccountState, (state) => state.openAccount);
// export const getOpenAccount = createSelector<AppState, DashboardState, number, Account | null>
//   ([getAccountState, getOpenAccountIndex], (state, index) =>
//   index < 0 ? null : state.accounts[index]);

export const getInvestmentAccounts = createSelector(getAccounts, (accounts) => {
  return accounts.filter(
    (account) =>
      account.variable && INVESTABLE_ACCOUNT_TYPES.has(account.variable)
  );
});

export const getInvestableAssetTotal = createSelector(getAccounts, (accounts) =>
  accounts.reduce(
    (result, account) =>
      account.variable && INVESTABLE_ACCOUNT_TYPES.has(account.variable)
        ? result + (account.balance || 0)
        : result,
    0
  )
);

export const getFormattedAssetsAndDebts = createSelector<
  AppState,
  Account[],
  AssetsDebts
>([getAccounts], (accounts: Account[]) => {
  const assets: MappedAccount[] = [];
  const debts: MappedAccount[] = [];
  accounts.forEach((account) => {
    const payment = account.payment || 0;
    const mapped: MappedAccount = {
      ...account,
      variable: account.variable || "",
      monthly: payment,
      annual: payment * 12,
      variableLabel: "",
      name: account.name,
      balance: account.balance,
    };
    if (ASSET_TYPES[mapped.variable]) {
      mapped.variableLabel = ASSET_TYPES[mapped.variable];
      assets.push(mapped);
    } else if (DEBT_TYPES[mapped.variable]) {
      mapped.variableLabel = DEBT_TYPES[mapped.variable];
      debts.push(mapped);
    }
  });
  return { assets, debts };
});

export const getAllFormattedAssetsAndDebts = createSelector<
  AppState,
  Account[],
  AssetsDebts
>([getAccounts], (accounts: Account[]) => {
  const assets: MappedAccount[] = [];
  const debts: MappedAccount[] = [];
  accounts.forEach((account) => {
    const payment = account.payment || 0;
    const mapped: MappedAccount = {
      ...account,
      variable: account.variable || "",
      monthly: payment,
      annual: payment * 12,
      variableLabel: "",
      name: account.name,
      balance: account.balance,
    };
    if (account.variable === "credit_card") {
      mapped.balance = account.balance_live || account.balance;
    }
    if (ASSET_TYPES[mapped.variable]) {
      mapped.variableLabel = ASSET_TYPES[mapped.variable];
      assets.push(mapped);
    } else if (DEBT_TYPES[mapped.variable]) {
      mapped.variableLabel = DEBT_TYPES[mapped.variable];
      debts.push(mapped);
    } else if (mapped.variable === "vehicle_lease") {
      mapped.variableLabel = "Auto Lease";
      debts.push(mapped);
    }
  });
  return { assets, debts };
});

export const getAssetBalanceTotals = createSelector(getAccounts, (accounts) => {
  const totals: { [assetType: string]: number } = {};

  accounts.forEach((account) => {
    const variable = account.variable || "";
    if (variable !== "auto_value" && ASSET_TYPES[variable || ""]) {
      if (!totals[variable]) {
        totals[variable] = 0;
      }
      let balance = account.balance || 0;
      if (variable === "home_value") {
        const debt = accounts.find(
          (foundAccount) => foundAccount.variable === "home_loan"
        );
        if (debt && debt.balance) {
          balance -= debt.balance;
        }
      } else if (variable === "property_value") {
        const debt = accounts.find(
          (foundAccount) => foundAccount.variable === "property_loan"
        );
        if (debt && debt.balance) {
          balance -= debt.balance;
        }
      }
      totals[variable] += balance;
    }
  });

  return totals;
});

export const getIsHomeowner = createSelector(
  getAccounts,
  (accounts) => !!accounts.find((account) => account.variable === "home_value")
);

export const getMyFedLoanPayments = createSelector(getAccounts, (accounts) =>
  accounts.reduce((result, account) => {
    if (account.variable === "fed_loan" && account.whose !== "spouse") {
      return result + (account.payment || 0);
    }
    return result;
  }, 0)
);
export const getSpouseFedLoanPayments = createSelector(
  getAccounts,
  (accounts) =>
    accounts.reduce((result, account) => {
      if (account.variable === "fed_loan" && account.whose === "spouse") {
        return result + (account.payment || 0);
      }
      return result;
    }, 0)
);

export const getAssetSummary = createSelector(getAccounts, (accounts) => {
  const result: any = {
    autoTotal: 0,
    autoDetail: null,
    cashTotal: 0,
    cashDetail: null,
    otherInvestmentsTotal: 0,
    otherInvestmentsDetail: null,
    otherAssetsTotal: 0,
    otherAssetsDetail: null,
    realEstateTotal: 0,
    realEstateDetail: null,
    retirementTotal: 0,
    retirementDetail: null,
    grandTotal: 0,
  };
  accounts.forEach((account: Account) => {
    switch (account.variable) {
      case "cash_value":
        result.cashTotal += account.balance || 0;
        result.grandTotal += account.balance || 0;
        if (!result.cashDetail) {
          result.cashDetail = [];
        }
        result.cashDetail.push(account);
        break;
      case "401k_value":
      case "roth_401k_value":
      case "roth_ira_value":
      case "ira_value":
      case "hsa_value":
      case "other_retirement_value":
        result.retirementTotal += account.balance || 0;
        result.grandTotal += account.balance || 0;
        if (!result.retirementDetail) {
          result.retirementDetail = [];
        }
        result.retirementDetail.push(account);
        break;
      case "home_value":
      case "property_value":
        result.realEstateTotal += account.balance || 0;
        result.grandTotal += account.balance || 0;
        if (!result.realEstateDetail) {
          result.realEstateDetail = [];
        }
        result.realEstateDetail.push(account);
        break;
      case "auto_value":
        result.autoTotal += account.balance || 0;
        result.grandTotal += account.balance || 0;
        if (!result.autoDetail) {
          result.autoDetail = [];
        }
        result.autoDetail.push(account);
        break;
      case "other_assets_value":
        result.otherAssetsTotal += account.balance || 0;
        result.grandTotal += account.balance || 0;
        if (!result.otherAssetsDetail) {
          result.otherAssetsDetail = [];
        }
        result.otherAssetsDetail.push(account);
        break;
      case "other_investments_value":
        result.otherInvestmentsTotal += account.balance || 0;
        result.grandTotal += account.balance || 0;
        if (!result.otherInvestmentsDetail) {
          result.otherInvestmentsDetail = [];
        }
        result.otherInvestmentsDetail.push(account);
        break;
      default:
        break;
    }
  });
  return result;
});

export const getDebtSummary = createSelector([getAccounts], (accounts) => {
  const result: any = {
    creditCardsTotal: 0,
    creditCardsDetail: null,
    studentLoans: 0,
    fedLoansTotalMine: 0,
    fedLoansTotalSpouse: 0,
    fedLoansDetailMine: null,
    fedLoansDetailSpouse: null,
    perkinsLoansTotalMine: 0,
    perkinsLoansTotalSpouse: 0,
    perkinsDetailMine: null,
    perkinsDetailSpouse: null,
    privLoansTotalMine: 0,
    privLoansTotalSpouse: 0,
    privLoansDetailMine: null,
    privLoansDetailSpouse: null,
    mortgageTotal: 0,
    mortgageDetail: null,
    personalLoansTotal: 0,
    personalLoansDetail: null,
    autoTotal: 0,
    autoDetail: null,
    otherTotal: 0,
    otherDetail: null,
    grandTotal: 0,
  };
  accounts.forEach((account: Account) => {
    switch (account.variable) {
      case "credit_card":
        result.creditCardsTotal += account.balance_live || account.balance || 0;
        result.grandTotal += account.balance_live || account.balance || 0;
        if (!result.creditCardsDetail) {
          result.creditCardsDetail = [];
        }
        result.creditCardsDetail.push(account);
        break;
      case "fed_loan":
        result.studentLoans += account.balance;
        result.grandTotal += account.balance || 0;
        if (account.whose === "spouse") {
          if (!result.fedLoansDetailSpouse) {
            result.fedLoansDetailSpouse = [];
          }
          result.fedLoansTotalSpouse += account.balance;
          result.fedLoansDetailSpouse.push(account);
        } else {
          if (!result.fedLoansDetailMine) {
            result.fedLoansDetailMine = [];
          }
          result.fedLoansTotalMine += account.balance;
          result.fedLoansDetailMine.push(account);
        }

        break;
      case "perkins_loan":
        result.studentLoans += account.balance;
        result.grandTotal += account.balance || 0;
        if (account.whose === "spouse") {
          if (!result.perkinsLoansDetailSpouse) {
            result.perkinsLoansDetailSpouse = [];
          }
          result.perkinsLoansTotalSpouse += account.balance;
          result.perkinsLoansDetailSpouse.push(account);
        } else {
          if (!result.perkinsLoansDetailMine) {
            result.perkinsLoansDetailMine = [];
          }
          result.perkinsLoansTotalMine += account.balance;
          result.perkinsLoansDetailMine.push(account);
        }
        break;
      case "priv_loan":
        result.studentLoans += account.balance;
        result.grandTotal += account.balance || 0;
        if (account.whose === "spouse") {
          if (!result.privLoansDetailSpouse) {
            result.privLoansDetailSpouse = [];
          }
          result.privLoansTotalSpouse += account.balance;
          result.privLoansDetailSpouse.push(account);
        } else {
          if (!result.privLoansDetailMine) {
            result.privLoansDetailMine = [];
          }
          result.privLoansTotalMine += account.balance;
          result.privLoansDetailMine.push(account);
        }
        break;
      case "auto_loan":
        result.autoTotal += account.balance;
        result.grandTotal += account.balance || 0;
        if (!result.autoDetail) {
          result.autoDetail = [];
        }
        result.autoDetail.push(account);
        break;
      case "home_loan":
      case "property_loan":
        result.mortgageTotal += account.balance;
        result.grandTotal += account.balance || 0;
        if (!result.mortgageDetail) {
          result.mortgageDetail = [];
        }
        result.mortgageDetail.push(account);
        break;
      case "personal_loan":
        result.personalLoansTotal += account.balance;
        result.grandTotal += account.balance || 0;
        if (!result.personalLoansDetail) {
          result.personalLoansDetail = [];
        }
        result.personalLoansDetail.push(account);
        break;
      case "other_debt":
        result.otherTotal += account.balance;
        result.grandTotal += account.balance || 0;
        if (!result.otherDetail) {
          result.otherDetail = [];
        }
        result.otherDetail.push(account);
        break;
      default:
        break;
    }
  });
  return result;
});

export const getReferralCode = createSelector(
  getAccountState,
  (state) => state.referralCode
);

export const getPrimaryMortgage = createSelector(getAccounts, (accounts) => {
  const allMortgages: any[] = accounts.filter(
    (account) => account.variable === "home_loan"
  );
  if (!allMortgages.length) {
    return null;
  }
  let foundAccount = allMortgages[0];
  if (allMortgages.length > 1) {
    allMortgages.forEach((account) => {
      if (account.balance > foundAccount.balance) {
        foundAccount = account;
      }
    });
  }
  return foundAccount;
});

export const getHomeValue = createSelector(getAccounts, (accounts) => {
  const foundAccount = accounts.find(
    (account) => account.variable === "home_value"
  );
  return foundAccount?.balance || 0;
});

export const getDebtObligations = createSelector(getAccounts, (accounts) => {
  const result = {
    home: 0,
    student: 0,
    auto: 0,
    other: 0,
  };
  accounts.forEach((account) => {
    switch (account.variable || account.type) {
      case "fed_loan":
      case "priv_loan":
      case "perkins_loan":
        result.student += account.payment;
        break;
      case "auto_loan":
        result.auto += account.payment;
        break;
      case "credit_card":
      case "property_loan":
      case "personal_loan":
      case "other_debt":
        result.other += account.payment;
        break;
      default:
        break;
    }
  });
  return result;
});
