import React, { useEffect, useState, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";

import { range } from "lodash";

import {
  Box,
  Divider,
  IconButton,
  makeStyles,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@material-ui/core";

import Icon from "src/components/Icon";
import SimpleTabs from "src/components/SimpleTabs";
import ReviewTransactionsDialog from "src/pages/Dashboard/MyProfile/Transactions/Dialogs/ReviewTransactionsDialog";
import {
  ACCOUNT_TYPES,
  EDUCATION_EXPENSE_TYPES,
  ALL_EXPENSE_TYPES,
  ALL_RISK_MANAGEMENT_TYPES,
} from "src/interfaces";
import {
  livePlanIncomeTotal,
  livePlanExpenseTotal,
  livePlanRiskManagementTotal,
} from "src/store/planBuild/selector";
import { AdapterLink } from "src/utils";
import colors from "src/theme/colors";
import {
  formatPercent,
  formatWholeDollars,
  formatExecutionPercent,
} from "src/utils";
import Card from "src/components/Card";
import {
  getIncomeSummary,
  getExpenseSummary,
  getRiskSummary,
  getExecutionSummary,
  getStudentCashflowSummaries,
  getStudentPlanSummaries,
} from "src/store/dashboard/selector";
import {
  getIncomeAndExpenseSummaries,
  getStudentIncomeAndExpenseSummaries,
  loadPlanSummary,
  loadStudentPlanSummary,
} from "src/store/dashboard/actions";
import {
  getActivePlanYearlySummary,
  getLiveProjection,
} from "src/store/planBuild/selector";
import { getLastGraduationYearMonth } from "src/store/profileBuild/selector";
import { getIsCurrentStudent } from "src/store/system/selector";

const useStyles = makeStyles({
  emphasis: {
    color: colors.brandingBlue1,
    fontWeight: 600,
    fontSize: 12,
    borderBottom: 0,
    borderTop: `1px solid ${colors.blueGray6}`,
  },
  indent: {
    paddingLeft: 30,
  },
  table: {
    width: "100%",
    borderBottom: `1px solid ${colors.blueGray6}`,
  },
  tableBorder: {
    padding: "0px 15px",
    width: "100%",
  },
  row: {
    height: "37px",
    fontSize: 12,
    borderBottom: 0,
  },
  dynamicRow: {
    fontSize: 12,
    borderBottom: 0,
    height: "37px",
    "& > *": {
      alignItems: "center",
    },
  },
  rowHeaderBox: {
    display: "flex",
    flexDirection: "row" as const,
    position: "relative" as const,
    height: "24.5px",
    "& svg": {
      position: "absolute",
      fontSize: "12px",
      left: 0,
      top: 6,
    },
    paddingLeft: 15,
  },
  dynamicRowHeader: {
    borderBottom: 0,
    fontSize: 12,
    margin: "auto 0",
  },
  columnHeaders: {
    fontSize: 12,
    textAlign: "center" as const,
  },
  lastColumn: {
    borderLeft: "1px solid lightgrey",
  },
  footer: {
    paddingTop: "20px",
  },
  zero: {
    height: 0,
    padding: 0,
    margin: 0,
    borderBottom: 0,
  },
  footRow: {
    fontSize: 12,
  },
  tabs: {
    margin: "20px auto",
  },
});

const nowYear = new Date(
  new Date().valueOf() - new Date().getTimezoneOffset() * 60000
).getFullYear();

const PlanDetails = () => {
  const styles = useStyles();
  const dispatch = useDispatch();
  const incomeSelector = livePlanIncomeTotal;
  const expenseSelector = livePlanExpenseTotal;
  const riskSelector = livePlanRiskManagementTotal;
  const income = useSelector(incomeSelector);
  const expenses = useSelector(expenseSelector);
  const risks = useSelector(riskSelector);
  const executionSummary: any = useSelector(getExecutionSummary);
  const incomeSummary = useSelector(getIncomeSummary);
  const expenseSummary = useSelector(getExpenseSummary);
  const riskSummary = useSelector(getRiskSummary);
  const [expanded, setExpanded] = useState<Array<number>>([]);
  const liveProjection = useSelector(getLiveProjection);
  const isCurrentStudent = useSelector(getIsCurrentStudent);
  const studentExecutionSummaries: any = useSelector(getStudentPlanSummaries);
  const studentPlanSummary: any = useSelector(getActivePlanYearlySummary);
  const studentCashflowSummaries: any = useSelector(
    getStudentCashflowSummaries
  );
  const [graduationYear, graduationMonth] = useSelector(
    getLastGraduationYearMonth
  );
  const [showTransactionsDialog, setShowTransactionsDialog] = useState(false);
  const [transactionQuery, setTransactionQuery] = useState({});
  const [cashMagnifyingMessage, setCashMagnifyingMessage] = useState(false);
  const [currentTab, setTab] = useState(0);
  const [showUnplannedRows, setShowUnplannedRows] = useState<any>({
    education: false,
    expenses: false,
    assets: false,
    debts: false,
    risks: false,
  });
  const planStart = liveProjection?.start || `${nowYear}-01`;
  const startYear = +planStart.slice(0, 4);
  const timeframes = isCurrentStudent
    ? range(startYear, graduationYear + 1)
    : ["current", "month" /*, "month_avg"*/, "annual"];
  const timeframe = timeframes[currentTab];

  const yearList = isCurrentStudent
    ? range(startYear, graduationYear + 1).map((val) => "" + val)
    : [];
  const startDate = new Date(planStart).toLocaleDateString([], {
    month: "short",
    year: "numeric",
  });

  useEffect(() => {
    dispatch(getIncomeAndExpenseSummaries());
  }, []);

  const getSummaryIncome = () => {
    const incomeKey = timeframe === "month" ? "monthly" : timeframe;
    let timeFrameIncome = 0;
    if (incomeKey) {
      const incomeItem = incomeSummary[incomeKey as keyof typeof incomeSummary];
      for (const item in incomeItem) {
        timeFrameIncome += incomeItem[item];
      }
    }
    return timeFrameIncome;
  };

  const getSummaryExpenses = () => {
    const expenseKey = timeframe === "month" ? "monthly" : timeframe;
    let timeFrameIncome = 0;
    if (expenseKey) {
      const expenseItem =
        expenseSummary[expenseKey as keyof typeof expenseSummary];
      for (const item in expenseItem) {
        timeFrameIncome += expenseItem[item];
      }
    }
    return timeFrameIncome;
  };

  const getSummaryRisk = () => {
    const riskKey = timeframe === "month" ? "monthly" : timeframe;
    let timeFrameRisk = 0;
    if (riskKey) {
      const riskItem = riskSummary[riskKey as keyof typeof riskSummary];
      for (const item in riskItem) {
        timeFrameRisk += riskItem[item];
      }
    }
    return timeFrameRisk;
  };

  const getWholeDollars = (num: number) => {
    if (num <= 0) {
      return formatWholeDollars(0);
    } else {
      return formatWholeDollars(num);
    }
  };

  const unplannedValues: any = useMemo(() => {
    const output: any = {
      education: [],
      expenses: [],
      assets: [],
      debts: [],
      risks: [],
    };
    if (
      !studentExecutionSummaries ||
      !studentPlanSummary ||
      !studentCashflowSummaries
    ) {
      return output;
    }
    try {
      const plannedEducationKeys = new Set(
        Object.keys(studentPlanSummary.educationExpenses[timeframe])
      );
      const plannedExpenseKeys = new Set(
        Object.keys(studentPlanSummary.expenses[timeframe])
      );
      const plannedAssetKeys = new Set(
        Object.keys(studentPlanSummary.assets[timeframe])
      );
      const plannedDebtKeys = new Set(
        Object.keys(studentPlanSummary.debts[timeframe])
      );
      const plannedRiskKeys = new Set(
        Object.keys(studentPlanSummary.risks[timeframe])
      );
      studentExecutionSummaries.educationKeys.forEach((key: string) => {
        if (!plannedEducationKeys.has(key)) {
          output.education.push(key);
        }
      });
      studentCashflowSummaries.expenseKeys.forEach((key: string) => {
        if (!plannedExpenseKeys.has(key) && key !== "taxes") {
          output.expenses.push(key);
        }
      });
      studentExecutionSummaries.assetKeys.forEach((key: string) => {
        if (!plannedAssetKeys.has(key)) {
          output.assets.push(key);
        }
      });
      studentExecutionSummaries.debtKeys.forEach((key: string) => {
        if (!plannedDebtKeys.has(key)) {
          output.debts.push(key);
        }
      });
      studentCashflowSummaries.riskKeys.forEach((key: string) => {
        if (!plannedRiskKeys.has(key)) {
          output.risks.push(key);
        }
      });
    } catch (e) {
      return output;
    }
    return output;
  }, [
    studentExecutionSummaries,
    studentPlanSummary,
    studentCashflowSummaries,
    timeframe,
  ]);

  const loadSummary = (year: number) => {
    const summaryArgs = { year: year || nowYear, startMonth: 0, endMonth: 0 };
    if (isCurrentStudent) {
      if (startYear === nowYear) {
        summaryArgs.startMonth = +planStart.slice(5, 7);
      }
      if (graduationYear === year) {
        summaryArgs.endMonth = graduationMonth;
      }
      dispatch(loadStudentPlanSummary(summaryArgs));
      dispatch(getStudentIncomeAndExpenseSummaries(summaryArgs));
    } else {
      dispatch(loadPlanSummary());
    }
  };

  useEffect(() => {
    loadSummary(startYear);
  }, []);

  const assetKeyList = Array.from(
    executionSummary.assetKeys as Set<string>
  ).filter((key: string) => key !== "total" && key !== "waterfall");
  const debtKeyList = Array.from(
    executionSummary.debtKeys as Set<string>
  ).filter((key: string) => key !== "total" && key !== "waterfall");

  const displayActual = !isCurrentStudent || +timeframe <= nowYear;

  const selectTab = isCurrentStudent
    ? (yearIndex: any) => {
        const year = range(startYear, graduationYear + 1)[yearIndex];
        loadSummary(year);
        setTab(yearIndex);
      }
    : setTab;

  const refreshData = () => {
    if (isCurrentStudent) {
      const year = range(startYear, graduationYear + 1)[currentTab];
      loadSummary(year);
    } else {
      loadSummary(0);
    }
  };

  const openCashDialog = () => {
    setTransactionQuery({});
    setShowTransactionsDialog(true);
    setCashMagnifyingMessage(true);
  };

  const openTransactionsDialog = (category: string, key: string) => {
    if (key === "cash_value") {
      openCashDialog();
      return;
    }
    const query: any = {
      category,
      type: key,
    };
    if (isCurrentStudent) {
      const queryYear = range(startYear, graduationYear + 1)[currentTab];
      let queryStartMonth = "01";
      let queryEndMonth = "12";
      if (queryYear === +planStart.slice(0, 4)) {
        queryStartMonth = planStart.slice(5, 7);
      }
      if (queryYear === graduationYear) {
        queryEndMonth = "" + graduationMonth;
        if (queryEndMonth.length === 1) {
          queryEndMonth = "0" + queryEndMonth;
        }
      }
      query.from = `${queryYear}-${queryStartMonth}`;
      query.to = `${queryYear}-${queryEndMonth}`;
    } else {
      if (timeframe === "month") {
        query.period = "monthly";
      } else {
        query.period = timeframe;
      }
    }
    setShowTransactionsDialog(true);
    setTransactionQuery(query);
    setCashMagnifyingMessage(false);
  };

  const toggleShowUnplanned = (key: string) => {
    setShowUnplannedRows((current: any) => {
      const result = { ...current, [key]: !current[key] };
      return result;
    });
  };

  const closeTransactionsDialog = () => {
    setShowTransactionsDialog(false);
    setTransactionQuery({});
  };

  const renderUnplannedRow = (key: string) => ({
    header: (
      <Box
        className="inline-flex items-center cursor-pointer"
        onClick={() => toggleShowUnplanned(key)}
      >
        <Box>Unplanned</Box>
        <Box className="ml-2">
          <Icon
            style={{ fontSize: 12 }}
            iconName={
              showUnplannedRows[key] ? "fb-chevron-down" : "fb-chevron-right"
            }
          />
        </Box>
      </Box>
    ),
    columns: [],
  });

  const renderEducationChildRow = (key: string) => ({
    header:
      EDUCATION_EXPENSE_TYPES[key as keyof typeof EDUCATION_EXPENSE_TYPES],
    columns: displayActual
      ? [
          <Box className="flex items-center justify-center">
            {!!studentExecutionSummaries.education[key] && (
              <IconButton
                color="primary"
                onClick={() => openTransactionsDialog("education", key)}
                style={{ height: 0, marginLeft: -26, marginRight: 3, width: 0 }}
              >
                <Icon iconName="fb-search" />
              </IconButton>
            )}
            <Box>
              {formatWholeDollars(studentExecutionSummaries.education[key])}
            </Box>
          </Box>,
          formatWholeDollars(
            studentPlanSummary.educationExpenses[timeframe][key]
          ),
          formatExecutionPercent(
            studentExecutionSummaries.education[key],
            studentPlanSummary.educationExpenses[timeframe][key]
          ),
        ]
      : [
          formatWholeDollars(
            studentPlanSummary.educationExpenses[timeframe][key]
          ),
        ],
  });
  const renderExpenseChildRow = (key: string) => {
    const actualKey = key === "tax" ? "taxes" : key;
    return {
      header: ALL_EXPENSE_TYPES[key as keyof typeof ALL_EXPENSE_TYPES],
      columns: displayActual
        ? [
            <Box className="flex items-center justify-center">
              {!!studentCashflowSummaries.expenses[actualKey] && (
                <IconButton
                  color="primary"
                  onClick={() => openTransactionsDialog("expense", actualKey)}
                  style={{
                    height: 0,
                    marginLeft: -26,
                    marginRight: 3,
                    width: 0,
                  }}
                >
                  <Icon iconName="fb-search" />
                </IconButton>
              )}
              <Box>
                {formatWholeDollars(
                  studentCashflowSummaries.expenses[actualKey]
                )}
              </Box>
            </Box>,
            formatWholeDollars(studentPlanSummary.expenses[timeframe][key]),
            formatExecutionPercent(
              studentCashflowSummaries.expenses[actualKey],
              studentPlanSummary.expenses[timeframe]
            ),
          ]
        : [formatWholeDollars(studentPlanSummary.expenses[timeframe][key])],
    };
  };
  const renderAssetChildRow = (key: string) => ({
    header: ACCOUNT_TYPES[key as keyof typeof ACCOUNT_TYPES],
    columns: displayActual
      ? [
          <Box className="flex items-center justify-center">
            {!!studentExecutionSummaries.asset[key] && (
              <IconButton
                style={{ height: 0, marginLeft: -26, marginRight: 3, width: 0 }}
                color="primary"
                onClick={() => openTransactionsDialog("contribution", key)}
              >
                <Icon style={{ padding: 0 }} iconName="fb-search" />
              </IconButton>
            )}
            <Box>
              {formatWholeDollars(studentExecutionSummaries.asset[key])}
            </Box>
          </Box>,
          formatWholeDollars(studentPlanSummary.assets[timeframe][key]),
          formatExecutionPercent(
            studentExecutionSummaries.asset[key],
            studentPlanSummary.assets[timeframe][key]
          ),
        ]
      : [formatWholeDollars(studentPlanSummary.assets[timeframe][key])],
  });
  const renderDebtChildRow = (key: string) => ({
    header: ACCOUNT_TYPES[key as keyof typeof ACCOUNT_TYPES],
    columns: displayActual
      ? [
          <Box className="flex items-center justify-center">
            {!!studentExecutionSummaries.debt[key] && (
              <IconButton
                color="primary"
                onClick={() => openTransactionsDialog("contribution", key)}
                style={{ height: 0, marginLeft: -26, marginRight: 3, width: 0 }}
              >
                <Icon iconName="fb-search" />
              </IconButton>
            )}
            <Box>{formatWholeDollars(studentExecutionSummaries.debt[key])}</Box>
          </Box>,
          formatWholeDollars(studentPlanSummary.debts[timeframe][key]),
          formatExecutionPercent(
            studentExecutionSummaries.debt[key],
            studentPlanSummary.debts[timeframe][key]
          ),
        ]
      : [formatWholeDollars(studentPlanSummary.debts[timeframe][key])],
  });
  const renderRiskChildRow = (key: string) => {
    return {
      header:
        ALL_RISK_MANAGEMENT_TYPES[
          key as keyof typeof ALL_RISK_MANAGEMENT_TYPES
        ],
      columns: displayActual
        ? [
            <Box className="flex items-center justify-center">
              {!!studentCashflowSummaries.risks[key] && (
                <IconButton
                  color="primary"
                  onClick={() => openTransactionsDialog("risk", key)}
                  style={{
                    height: 0,
                    marginLeft: -26,
                    marginRight: 3,
                    width: 0,
                  }}
                >
                  <Icon iconName="fb-search" />
                </IconButton>
              )}
              <Box>
                {formatWholeDollars(studentCashflowSummaries.risks[key])}
              </Box>
            </Box>,
            formatWholeDollars(studentPlanSummary.risks[timeframe][key]),
            formatExecutionPercent(
              studentCashflowSummaries.risks[key],
              studentPlanSummary.risks[timeframe]
            ),
          ]
        : [formatWholeDollars(studentPlanSummary.risks[timeframe][key])],
    };
  };

  const getMagnifier = (
    income: boolean,
    actualOrCash: boolean,
    key: string
  ) => {
    // We treat cash value special so that even if there is no cash contribution we still show that magnifying glass
    if (actualOrCash) {
      return (
        <IconButton
          color="primary"
          onClick={() => openTransactionsDialog("contribution", key)}
          style={{ height: 0, marginLeft: -26, marginRight: 3, width: 0 }}
        >
          <Icon iconName="fb-search" />
        </IconButton>
      );
    } else {
      return <div />;
    }
  };

  const getSummaryRows = React.useMemo(() => {
    let loading = false;
    let rows: any[] = [];
    try {
      if (isCurrentStudent) {
        if (!studentPlanSummary?.educationExpenses?.[timeframe]) {
          loading = true;
          return { loading, rows };
        }
        const educationDetailChildren: any[] = Object.keys(
          studentPlanSummary.educationExpenses[timeframe]
        )
          .filter((key) => key !== "total")
          .map(renderEducationChildRow);
        if (displayActual && unplannedValues.education.length) {
          educationDetailChildren.push(renderUnplannedRow("education"));
          if (showUnplannedRows.education) {
            educationDetailChildren.push(
              ...unplannedValues.education.map(renderEducationChildRow)
            );
          }
        }
        const expenseDetailChildren: any[] = Object.keys(
          studentPlanSummary.expenses[timeframe]
        )
          .filter((key) => key !== "total" && key !== "tax")
          .map(renderExpenseChildRow);
        expenseDetailChildren.unshift(renderExpenseChildRow("tax"));
        if (displayActual && unplannedValues.expenses.length) {
          expenseDetailChildren.push(renderUnplannedRow("expenses"));
          if (showUnplannedRows.expenses) {
            expenseDetailChildren.push(
              ...unplannedValues.expenses.map(renderExpenseChildRow)
            );
          }
        }
        const assetsDetailChildren: any[] = Object.keys(
          studentPlanSummary.assets[timeframe]
        )
          .filter((key) => key !== "total")
          .map(renderAssetChildRow);
        if (displayActual && unplannedValues.assets.length) {
          assetsDetailChildren.push(renderUnplannedRow("assets"));
          if (showUnplannedRows.assets) {
            assetsDetailChildren.push(
              ...unplannedValues.assets.map(renderAssetChildRow)
            );
          }
        }
        const debtsDetailChildren: any[] = Object.keys(
          studentPlanSummary.debts[timeframe]
        )
          .filter((key) => key !== "total")
          .map(renderDebtChildRow);
        if (displayActual && unplannedValues.debts.length) {
          debtsDetailChildren.push(renderUnplannedRow("debts"));
          if (showUnplannedRows.debts) {
            debtsDetailChildren.push(
              ...unplannedValues.debts.map(renderDebtChildRow)
            );
          }
        }
        const risksDetailChildren: any[] = Object.keys(
          studentPlanSummary.risks[timeframe]
        )
          .filter((key) => key !== "total")
          .map(renderRiskChildRow);
        if (displayActual && unplannedValues.risks.length) {
          risksDetailChildren.push(renderUnplannedRow("risks"));
          if (showUnplannedRows.risks) {
            risksDetailChildren.push(
              ...unplannedValues.risks.map(renderRiskChildRow)
            );
          }
        }
        rows = [
          {
            header: "Education Costs",
            columns: displayActual
              ? [
                  formatWholeDollars(studentExecutionSummaries.education.total),
                  formatWholeDollars(
                    studentPlanSummary.educationExpenses[timeframe].total
                  ),
                  formatExecutionPercent(
                    studentExecutionSummaries.education.total,
                    studentPlanSummary.educationExpenses[timeframe].total
                  ),
                ]
              : [
                  formatWholeDollars(
                    studentPlanSummary.educationExpenses[timeframe].total
                  ),
                ],
            children: educationDetailChildren,
          },
          {
            header: "Day to Day Expenses",
            columns: displayActual
              ? [
                  formatWholeDollars(studentCashflowSummaries.expenses.total),
                  formatWholeDollars(
                    studentPlanSummary.expenses[timeframe].total
                  ),
                  formatExecutionPercent(
                    studentCashflowSummaries.expenses.total,
                    studentPlanSummary.expenses[timeframe].total
                  ),
                ]
              : [
                  formatWholeDollars(
                    studentPlanSummary.expenses[timeframe].total
                  ),
                ],
            children: expenseDetailChildren,
          },
          {
            header: "Assets",
            columns: displayActual
              ? [
                  formatWholeDollars(studentExecutionSummaries.asset.total),
                  formatWholeDollars(
                    studentPlanSummary.assets[timeframe].total
                  ),
                  formatExecutionPercent(
                    studentExecutionSummaries.asset.total,
                    studentPlanSummary.assets[timeframe].total
                  ),
                ]
              : [
                  formatWholeDollars(
                    studentPlanSummary.assets[timeframe].total
                  ),
                ],
            children: assetsDetailChildren,
          },
          {
            header: "Debts",
            columns: displayActual
              ? [
                  formatWholeDollars(studentExecutionSummaries.debt.total),
                  formatWholeDollars(studentPlanSummary.debts[timeframe].total),
                  formatExecutionPercent(
                    studentExecutionSummaries.debt.total,
                    studentPlanSummary.debts[timeframe].total
                  ),
                ]
              : [formatWholeDollars(studentPlanSummary.debts[timeframe].total)],
            children: debtsDetailChildren,
          },
          {
            header: "Risk Management",
            columns: displayActual
              ? [
                  formatWholeDollars(studentCashflowSummaries.risks.total),
                  formatWholeDollars(studentPlanSummary.risks[timeframe].total),
                  formatExecutionPercent(
                    studentCashflowSummaries.risks.total,
                    studentPlanSummary.risks[timeframe].total
                  ),
                ]
              : [formatWholeDollars(studentPlanSummary.risks[timeframe].total)],
            children: risksDetailChildren,
          },
        ];
      } else {
        const executionIncome = executionSummary.income.total[timeframe].actual;
        rows = [
          {
            header: "Assets",
            columns: [
              formatExecutionPercent(
                executionSummary.asset.total[timeframe].actual,
                executionIncome
              ),
              formatPercent(executionSummary.asset.total[timeframe].plan),
            ],
            children: assetKeyList.map((key: string) => ({
              header: ACCOUNT_TYPES[key as keyof typeof ACCOUNT_TYPES],
              columns: [
                <Box className="flex items-center justify-center">
                  {
                    // We treat cash value special so that even if there is no cash contribution we still show that magnifying glass
                    getMagnifier(
                      executionIncome,
                      executionSummary.asset[key][timeframe].actual ||
                        key === "cash_value",
                      key
                    )
                  }
                  {formatExecutionPercent(
                    executionSummary.asset[key][timeframe].actual,
                    executionIncome
                  )}
                </Box>,
                formatPercent(executionSummary.asset[key][timeframe].plan),
              ],
              dollars: executionSummary.asset[key][timeframe].actual,
              percent: executionSummary.asset[key][timeframe].plan,
              formPercentActual: formatExecutionPercent(
                executionSummary.asset[key][timeframe].actual,
                executionIncome
              ),
              node: getMagnifier(
                executionIncome,
                executionSummary.asset[key][timeframe].actual ||
                  key === "cash_value",
                key
              ),
            })),
          },
          {
            header: "Debts",
            columns: [
              formatExecutionPercent(
                executionSummary.debt.total[timeframe].actual,
                executionIncome
              ),
              formatPercent(executionSummary.debt.total[timeframe].plan),
            ],
            children: debtKeyList.map((key: string) => ({
              header: ACCOUNT_TYPES[key as keyof typeof ACCOUNT_TYPES],
              columns: [
                <Box className="flex items-center justify-center">
                  {getMagnifier(
                    executionIncome,
                    executionSummary.debt[key][timeframe].actual,
                    key
                  )}
                  {formatExecutionPercent(
                    executionSummary.debt[key][timeframe].actual,
                    executionIncome
                  )}
                </Box>,
                formatPercent(executionSummary.debt[key][timeframe].plan),
              ],
              dollars: executionSummary.debt[key][timeframe].actual,
              percent: executionSummary.debt[key][timeframe].plan,
              formPercentActual: formatExecutionPercent(
                executionSummary.debt[key][timeframe].actual,
                executionIncome
              ),
              node: getMagnifier(
                executionIncome,
                executionSummary.debt[key][timeframe].actual,
                key
              ),
            })),
          },
        ];
      }
    } catch (summaryError) {
      loading = true;
      rows = [];
    }
    return { loading, rows };
  }, [
    studentExecutionSummaries,
    studentPlanSummary,
    studentCashflowSummaries,
    executionSummary,
    timeframe,
    showUnplannedRows,
  ]);

  const summary = useMemo(() => {
    const income = getSummaryIncome();
    const expense = getSummaryExpenses();
    return { income, expense };
  }, [timeframe]);

  const isExpanded = (i: number) => {
    return expanded.indexOf(i) > -1;
  };

  const handleToggle = (i: number) => {
    const index = expanded.indexOf(i);
    if (index > -1) {
      setExpanded(expanded.filter((j) => j !== i));
      expanded.splice(index, 1);
    } else {
      setExpanded([...expanded, i]);
    }
  };

  const getPlannedValue = (plannedValue: number) => {
    if (timeframe === "annual") {
      return plannedValue;
    } else {
      return plannedValue / 12;
    }
  };

  const getPlannedCollectionPercent = (collection: any) => {
    let total = 0;
    if (collection.children) {
      collection.children.map((child: any) => {
        if (child.percent && typeof child.percent === "number") {
          total += child.percent;
        }
      });
    }
    return total;
  };

  const PlanTableRow = ({
    rowName,
    actualDollars,
    actualPercent,
    plannedDollars,
    plannedPercent,
    noData,
    style,
    clickEvent,
    titleClassName,
  }: {
    rowName: any;
    actualDollars?: any;
    actualPercent?: any;
    plannedDollars?: number;
    plannedPercent?: number | string;
    noData?: boolean;
    style?: string;
    titleClassName?: string;
    clickEvent?: VoidFunction;
  }) => (
    <TableRow
      onClick={() => {
        if (clickEvent) {
          clickEvent();
        }
      }}
    >
      <TableCell className={`${style} ${titleClassName}`}>{rowName}</TableCell>
      <TableCell className={`${style} text-center`}>
        {!noData && (
          <Box className="flex">
            <Box className="w-1/2">{actualDollars || 0}</Box>
            <Box className="w-1/2 text-center">{actualPercent || 0}</Box>
          </Box>
        )}
      </TableCell>
      <TableCell className={`${style} ${styles.lastColumn}`}>
        {!noData && (
          <Box className="flex">
            <Box className="w-1/2 text-center">
              {getWholeDollars(plannedDollars || 0)}
            </Box>
            <Box className="w-1/2 text-center">{plannedPercent || 0}</Box>
          </Box>
        )}
      </TableCell>
    </TableRow>
  );

  const summaryRows = getSummaryRows.rows;

  return (
    <>
      <Box className={styles.tabs}>
        <SimpleTabs
          list={
            isCurrentStudent
              ? yearList
              : [
                  "This Month",
                  "Last Month",
                  // "Monthly Average",
                  "Last 12 Months",
                ]
          }
          value={currentTab}
          onChange={selectTab}
        />
      </Box>
      <Card
        title="My Financial Plan Execution Summary"
        noPadding
        className={styles.tableBorder}
      >
        <Table size="small" className={styles.table}>
          <TableHead>
            <TableRow>
              <TableCell />
              <TableCell className={styles.columnHeaders}>Actual</TableCell>
              <TableCell className={styles.columnHeaders}>Planned</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <PlanTableRow
              rowName="Day-to-Day Money"
              noData
              style={styles.emphasis}
            />
            <PlanTableRow
              rowName="Total Income"
              actualDollars={getWholeDollars(summary.income)}
              actualPercent={"% Income"}
              plannedDollars={getPlannedValue(income)}
              plannedPercent={"% Income"}
              titleClassName={styles.indent}
              style={styles.row}
            />
            <PlanTableRow
              rowName="Day-to-Day Expenses"
              actualDollars={getWholeDollars(summary.expense)}
              actualPercent={formatExecutionPercent(
                summary.expense,
                summary.income
              )}
              plannedDollars={getPlannedValue(expenses)}
              plannedPercent={formatExecutionPercent(expenses, income)}
              titleClassName={styles.indent}
              style={styles.row}
            />

            {/*<PlanTableRow
              rowName="Remaining Cash"
              actualDollars={getWholeDollars(summary.income - summary.expense)}
              actualPercent={formatExecutionPercent(
                summary.income - summary.expense,
                summary.income
              )}
              plannedDollars={getPlannedValue(income - expenses)}
              plannedPercent={formatExecutionPercent(income - expenses, income)}
              style={styles.row}
            />*/}
            <PlanTableRow
              rowName="Money For Future Goals"
              noData
              style={styles.emphasis}
            />
            {["asset", "debt"].map((row: any, index: number) => {
              const rowName =
                row === "asset" ? "Asset Contributions" : "Debt Payments";
              const rowHead = (
                <Box className={styles.rowHeaderBox}>
                  <Icon
                    iconName={
                      isExpanded(index) ? "fb-chevron-down" : "fb-chevron-right"
                    }
                    style={{ cursor: "pointer" }}
                  ></Icon>{" "}
                  <span className={styles.dynamicRowHeader}>{rowName}</span>
                </Box>
              );
              return (
                <>
                  <PlanTableRow
                    rowName={rowHead}
                    actualDollars={getWholeDollars(
                      executionSummary[row].total[timeframe].actual
                    )}
                    actualPercent={summaryRows[index].columns[0]}
                    plannedDollars={
                      getPlannedCollectionPercent(summaryRows[index]) *
                      0.01 *
                      getPlannedValue(income)
                    }
                    plannedPercent={summaryRows[index].columns[1]}
                    style={styles.dynamicRowHeader}
                    clickEvent={() => handleToggle(index)}
                  />

                  {/* Child Rows */}
                  {isExpanded(index) && (
                    <>
                      <TableRow className={styles.zero}>
                        <TableCell className={styles.zero} />
                        <TableCell className={styles.zero} />
                        <TableCell
                          className={`${styles.lastColumn} ${styles.zero}`}
                        />
                      </TableRow>
                      {summaryRows[index].children.map((childRow: any) => {
                        const dollarColumn = (
                          <Box className="flex items-center justify-center">
                            {childRow.node}
                            {getWholeDollars(childRow.dollars)}
                          </Box>
                        );
                        return (
                          <>
                            <PlanTableRow
                              rowName={childRow.header}
                              actualDollars={dollarColumn}
                              actualPercent={childRow.formPercentActual}
                              plannedDollars={
                                childRow.percent *
                                0.01 *
                                getPlannedValue(income)
                              }
                              plannedPercent={formatPercent(childRow.percent)}
                              style={styles.dynamicRow}
                              titleClassName={styles.indent}
                            />
                          </>
                        );
                      })}
                      <TableRow className={styles.zero}>
                        <TableCell className={styles.zero} />
                        <TableCell className={styles.zero} />
                        <TableCell
                          className={`${styles.lastColumn} ${styles.zero}`}
                        />
                      </TableRow>
                    </>
                  )}
                </>
              );
            })}

            <PlanTableRow
              rowName="Risk Management"
              noData
              style={styles.emphasis}
            />
            <PlanTableRow
              rowName="Premium Payments"
              actualDollars={getWholeDollars(getSummaryRisk())}
              actualPercent={formatExecutionPercent(
                getSummaryRisk(),
                summary.income
              )}
              plannedDollars={getPlannedValue(risks)}
              plannedPercent={formatExecutionPercent(risks, income)}
              style={styles.row}
              titleClassName={styles.indent}
            />
          </TableBody>
        </Table>
        <Divider />
        <Box className={styles.footer}>
          <Typography paragraph className={styles.footRow}>
            Reflects amounts since your plan was implemented in {startDate}.
          </Typography>
          {summary.income <= 0 && (
            <Typography paragraph className={styles.footRow}>
              Your actual total income is needed to compute the figures in the
              "Actual" column. To update your income,{" "}
              <AdapterLink to={"/transactions"}>click here</AdapterLink>
            </Typography>
          )}
        </Box>
      </Card>
      <ReviewTransactionsDialog
        onClose={closeTransactionsDialog}
        open={showTransactionsDialog}
        query={transactionQuery}
        cashMessage={cashMagnifyingMessage}
        onRemove={refreshData}
      />
    </>
  );
};

export default PlanDetails;
