import { createAction } from "redux-actions";
import { START, SUCCESS } from "../common";

import { NewTransaction, Transaction } from "src/interfaces";

export interface EditTransactionPayload {
  id: number;
  update: Partial<Transaction> & { ignore?: any };
}

export interface GetTransactionsPayload {
  from: string;
  to: string;
}

export const ADD_UNCONFIRMED_TRANSACTION = "ADD_UNCONFIRMED_TRANSACTION";
export const GET_UNCONFIRMED_TRANSACTIONS = "GET_UNCONFIRMED_TRANSACTIONS";
export const GET_TRANSACTIONS = "GET_TRANSACTIONS";
export const SET_UPDATED_BREAKOUTS = "SET_UPDATED_BREAKOUTS";
export const CONFIRM_TRANSACTIONS = "CONFIRM_TRANSACTIONS";
export const ADD_TRANSACTION = "ADD_TRANSACTION";
export const EDIT_TRANSACTION = "EDIT_TRANSACTION";
export const REMOVE_TRANSACTION = "REMOVE_TRANSACTION";
export const UPDATE_TRANSACTION = "UPDATE_TRANSACTION";

export const addUnconfirmedTransaction = createAction<Transaction>(
  ADD_UNCONFIRMED_TRANSACTION
);
export const getUnconfirmedTransactions = createAction(
  GET_UNCONFIRMED_TRANSACTIONS + START
);
export const getTransactions = createAction<GetTransactionsPayload>(
  GET_TRANSACTIONS + START
);
export const setUpdatedBreakouts = createAction<Transaction[]>(
  SET_UPDATED_BREAKOUTS
);
export const confirmTransactions = createAction<number[]>(
  CONFIRM_TRANSACTIONS + START
);
export const markTransactionsConfirmed = createAction<number[]>(
  CONFIRM_TRANSACTIONS + SUCCESS
);
export const addTransaction = createAction<NewTransaction>(
  ADD_TRANSACTION + START
);
export const addTransactionSuccess = createAction<Transaction>(
  ADD_TRANSACTION + SUCCESS
);
export const editTransaction = createAction<EditTransactionPayload>(
  EDIT_TRANSACTION + START
);
export const removeTransaction = createAction<EditTransactionPayload>(
  REMOVE_TRANSACTION
);
export const updateTransaction = createAction<EditTransactionPayload>(
  UPDATE_TRANSACTION
);
