import React from "react";

import { Box, Typography } from "@material-ui/core";

import Button from "src/components/Button";
import CustomDialog from "src/components/Dialogs/CustomDialog";

export const FreeAccountInterstitial = ({
  open,
  onClose,
  gotoUpgrade,
}: any) => {
  return (
    <CustomDialog title="" size="sm" isOpen={open} onClose={onClose}>
      <Typography>
        Our powerful proprietary tools are available to premium FitBUX members
        only. Upgrade your account below.
      </Typography>
      <Box className="flex mt-10 justify-center">
        <Button fbColor="primary" onClick={gotoUpgrade}>
          Upgrade My Account
        </Button>
      </Box>
    </CustomDialog>
  );
};

export default FreeAccountInterstitial;
