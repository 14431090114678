import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Box, Typography, Grid } from "@material-ui/core";

import { getCurrentStep } from "src/store/planBuild/selector";
import { PLAN_BUILD_STEPS } from "src/store/planBuild/constants";
import Button from "src/components/Button";
import { setFurthestStep } from "src/store/planBuild/actions";
import { getIsCurrentStudent } from "src/store/system/selector";

interface BuildPopProps {
  close: (value: string) => void;
  transitionPopup: VoidFunction;
}

type buildProps = BuildPopProps;

const BuildPopup = ({ close, transitionPopup }: buildProps) => {
  const currentStep = useSelector(getCurrentStep);
  const dispatch = useDispatch();
  const isCurrentStudent = useSelector(getIsCurrentStudent);

  useEffect(() => {
    const instantClosePoint = isCurrentStudent
      ? PLAN_BUILD_STEPS.INCOME_AND_EXPENSES
      : PLAN_BUILD_STEPS.GRADUATED_LIFE_EVENTS_OVERVIEW;
    if (currentStep < instantClosePoint) {
      close("plan");
    }
    // reset furtheststep state back to base value when closing out of the plan build flow
    dispatch(setFurthestStep(1));
  }, []);

  return (
    <>
      <Grid item>
        <Box>
          <Typography variant="h1">Implement Your Plan</Typography>
        </Box>
      </Grid>

      <br />

      <Grid item>
        <Box>
          <Typography variant="h6">
            Would you like to implement and track the plan you just built?
          </Typography>
        </Box>
      </Grid>

      <br />

      <Grid item>
        <Grid container spacing={10}>
          <Grid item>
            <Button
              fbColor="primary"
              onClick={() => {
                transitionPopup();
              }}
            >
              Yes, I Want To Implement My Plan
            </Button>
          </Grid>

          <Grid item>
            <Button
              fbColor="secondary"
              onClick={() => {
                close("plan-list");
              }}
            >
              Close this plan
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};

export default BuildPopup;
