import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Redirect } from "react-router";

import {
  Avatar,
  Button,
  Grid,
  makeStyles,
  Theme,
  Typography,
} from "@material-ui/core";
import LockOpenOutlinedIcon from "@material-ui/icons/LockOpenOutlined";
import createStyles from "@material-ui/core/styles/createStyles";

import { logOut } from "src/store/system/actions";
import { loginStateSelector } from "src/store/system/selector";
import { AdapterLink } from "src/utils";
import { setPopup, setPopupActive } from "src/store/dashboard/actions";

const redirect = process.env.REACT_APP_OVERRIDE_REDIRECT !== "true";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    "@global": {
      body: {
        backgroundColor: theme.palette.common.white,
      },
    },
    paper: {
      marginTop: theme.spacing(8),
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
    },
    avatar: {
      margin: theme.spacing(1),
      backgroundColor: theme.palette.secondary.main,
    },
    form: {
      width: "100%", // Fix IE 11 issue.
      marginTop: theme.spacing(1),
    },
    submit: {
      margin: theme.spacing(3, 0, 2),
    },
  })
);

const ExtendedLogout = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { loggedIn } = useSelector(loginStateSelector);
  if (!loggedIn) {
    return <Redirect to="/login" />;
  }
  return (
    <>
      <div className={classes.paper}>
        <Avatar className={classes.avatar}>
          <LockOpenOutlinedIcon />
        </Avatar>
        <Typography component="h1" variant="h5">
          Log Out
        </Typography>
        <br />
        {/* TODO: expand this width of this component and tie to a condition*/}
        <Typography variant="h1">
          Before logging out, would you like to implement your plan?
        </Typography>
        {/* TODO: end condition here */}

        <br />

        {/* TODO: add condition to render this button or the basic logout button here */}
        <form>
          <Button
            fullWidth
            variant="contained"
            color="primary"
            href="#"
            size="large"
            className={classes.submit}
            onClick={() => {
              dispatch(setPopupActive(true));
              dispatch(setPopup("Logout"));
            }}
          >
            Yes, Implement A Plan
          </Button>
        </form>
        {/* TODO: end condition here */}

        <Grid container direction="row" justify="center" spacing={2}>
          <Grid item>
            {/* TODO: add condition to show button if other button didnt show */}
            <Button
              size="small"
              href="#"
              component={AdapterLink}
              to="/plan-summary"
              color="secondary"
              type="submit"
              onClick={() => {
                dispatch(logOut());
                if (redirect) {
                  window.location.replace(
                    "https://www.fitbux.com/refer-a-friend-log-out/"
                  );
                }
              }}
              //
            >
              Log Out Now
            </Button>
          </Grid>
          {/* TODO: end condition here */}

          <Grid item>
            <Button
              size="small"
              href="#"
              component={AdapterLink}
              to="/plan-summary"
              color="secondary"
            >
              Back to Home
            </Button>
          </Grid>
        </Grid>
      </div>
    </>
  );
};

export default ExtendedLogout;
