import produce from "immer";
import { handleActions } from "redux-actions";

import {
  FbAction,
  isIncomeTypeToConfirm,
  Transaction,
  TransactionState,
} from "src/interfaces";
import { FAIL, START, SUCCESS } from "../common";
import * as actions from "./actions";
import { LOG_OUT } from "../system/actions";

const initialState: TransactionState = {
  loadedTransactions: false,
  loadingTransactions: false,
  loadedUnconfirmedTransactions: false,
  loadingUnconfirmedTransactions: false,
  transactionError: null,
  transactions: [],
  unconfirmedTransactions: [],
};

const reducerDefinitions: any = {
  [actions.GET_UNCONFIRMED_TRANSACTIONS + START]: (state: TransactionState) =>
    produce(state, (draft) => {
      draft.loadingUnconfirmedTransactions = true;
    }),
  [actions.GET_UNCONFIRMED_TRANSACTIONS + SUCCESS]: (
    state: TransactionState,
    { payload }: FbAction<Transaction[]>
  ) =>
    produce(state, (draft) => {
      draft.loadingUnconfirmedTransactions = false;
      draft.loadedUnconfirmedTransactions = true;
      draft.unconfirmedTransactions = payload;
    }),
  [actions.GET_UNCONFIRMED_TRANSACTIONS + FAIL]: (
    state: TransactionState,
    { payload }: FbAction<any>
  ) =>
    produce(state, (draft) => {
      draft.transactionError = payload;
    }),
  [actions.ADD_UNCONFIRMED_TRANSACTION]: (
    state: TransactionState,
    { payload }: FbAction<Transaction>
  ) =>
    produce(state, (draft) => {
      draft.unconfirmedTransactions = [
        ...state.unconfirmedTransactions,
        payload,
      ];
    }),
  [actions.GET_TRANSACTIONS + START]: (state: TransactionState) =>
    produce(state, (draft) => {
      draft.loadingTransactions = true;
    }),
  [actions.GET_TRANSACTIONS + SUCCESS]: (
    state: TransactionState,
    { payload }: FbAction<Transaction[]>
  ) =>
    produce(state, (draft) => {
      draft.loadingTransactions = false;
      draft.loadedTransactions = true;
      draft.transactions = payload;
    }),
  [actions.GET_TRANSACTIONS + FAIL]: (
    state: TransactionState,
    { payload }: FbAction<any>
  ) =>
    produce(state, (draft) => {
      draft.transactionError = payload;
    }),
  [actions.SET_UPDATED_BREAKOUTS]: (
    state: TransactionState,
    { payload }: FbAction<Transaction[]>
  ) =>
    produce(state, (draft) => {
      const toAdd: any[] = [];
      payload.forEach((newTransaction) => {
        const existingTransactionIndex = state.transactions.findIndex(
          (found) => found.id === newTransaction.id
        );
        if (existingTransactionIndex >= 0) {
          draft.transactions[existingTransactionIndex] = newTransaction;
        } else if (!isIncomeTypeToConfirm(newTransaction.type)) {
          toAdd.unshift(newTransaction);
        }
      });
      if (toAdd.length) {
        draft.transactions.unshift(...toAdd);
      }
      draft.transactions = draft.transactions.filter((t) => {
        if (t.valid === false || t.done || isIncomeTypeToConfirm(t.type)) {
          return false;
        }
        return true;
      });
    }),
  [actions.CONFIRM_TRANSACTIONS + START]: (state: TransactionState) =>
    produce(state, (draft) => {
      draft.loadingUnconfirmedTransactions = true;
    }),
  [actions.CONFIRM_TRANSACTIONS + SUCCESS]: (
    state: TransactionState,
    { payload }: FbAction<number[]>
  ) =>
    produce(state, (draft) => {
      draft.loadingUnconfirmedTransactions = false;
      draft.unconfirmedTransactions = state.unconfirmedTransactions.filter(
        (transaction) => payload.indexOf(transaction.id) < 0
      );
    }),
  [actions.CONFIRM_TRANSACTIONS + FAIL]: (
    state: TransactionState,
    { payload }: FbAction<any>
  ) =>
    produce(state, (draft) => {
      draft.loadingUnconfirmedTransactions = false;
      draft.transactionError = payload;
    }),
  [actions.ADD_TRANSACTION + START]: (state: TransactionState) =>
    produce(state, (draft) => {
      draft.loadingUnconfirmedTransactions = true;
    }),
  [actions.ADD_TRANSACTION + SUCCESS]: (
    state: TransactionState,
    { payload }: FbAction<Transaction>
  ) =>
    produce(state, (draft) => {
      draft.loadingUnconfirmedTransactions = false;
      draft.transactions.unshift(payload);
    }),
  [actions.ADD_TRANSACTION + FAIL]: (
    state: TransactionState,
    { payload }: FbAction<any>
  ) =>
    produce(state, (draft) => {
      draft.loadingUnconfirmedTransactions = false;
      draft.transactionError = payload;
    }),
  [actions.UPDATE_TRANSACTION]: (
    state: TransactionState,
    { payload }: FbAction<actions.EditTransactionPayload>
  ) =>
    produce(state, (draft) => {
      if (payload.update.ignore === "duplicate") {
        draft.transactions = state.transactions.filter(
          (item) => item.id !== payload.id
        );
      } else {
        const transactionIndex = draft.transactions.findIndex(
          (item) => item.id === payload.id
        );
        if (transactionIndex >= 0) {
          const transaction = draft.transactions[transactionIndex];
          draft.transactions[transactionIndex] = {
            ...transaction,
            ...payload.update,
          };
        }
      }
    }),
  [actions.REMOVE_TRANSACTION]: (
    state: TransactionState,
    { payload }: FbAction<number>
  ) =>
    produce(state, (draft) => {
      draft.transactions = state.transactions.filter(
        (transaction) => transaction.id !== payload
      );
    }),
  [LOG_OUT]: () => initialState,
};

const transactionReducer = handleActions<TransactionState, any>(
  reducerDefinitions,
  initialState
);

export default transactionReducer;
