import { HSALimit } from "src/constants";
import { PLAN_BUILD_STEPS } from "src/store/planBuild/constants";
import { formatWholeDollars } from "src/utils";

export enum RECOMMENDATION_CATEGORY {
  VACATION_FUN_MONEY,
  ST_GOALS_VS_PAYOFF,
  PAYOFF_NOT_OPTIMAL,
  MISSING_ST_SAVINGS,
  ROTH_IRA_MIN,
  ROTH_IRA_MIN_MARRIED,
  IRA_EARNED_INCOME,
  PRE_TAX_RETIREMENT_MIN,
  ROTH_IRA_MAX,
  ROTH_IRA_TAX,
  IRA_CONTRIBUTION_LIMIT,
  HSA_CONTRIBUTION_LIMIT,
  "401K_CONTRIBUTION_LIMIT",
  IDR_TAX_BOMB,
  IDR_TAX_BOMB_PSLF,
}

export const notifications = {
  [RECOMMENDATION_CATEGORY.VACATION_FUN_MONEY]: {
    message:
      "Your vacation/fun money is negative meaning that you have exceeded your budget. Return to Steps 2 or 3 and make adjustments to your plan so that there is some vacation/fun money left over.",
  },
  [RECOMMENDATION_CATEGORY.ST_GOALS_VS_PAYOFF]: {
    planStep: [PLAN_BUILD_STEPS.ASSETS_AND_DEBTS],
    message:
      "You have short term goals but are making prepayments to debt.  FitBUX recommends you make minimum payments on debt until your short-term goals are met.",
  },
  [RECOMMENDATION_CATEGORY.PAYOFF_NOT_OPTIMAL]: {
    planStep: [PLAN_BUILD_STEPS.ASSETS_AND_DEBTS],
    message:
      "You have higher interest rate debts but you indicated that you would like to save more aggressively or would like to pay off lower interest rate debts more aggressively.  We recommend focusing on paying off the high interest rate debt first.",
  },
  [RECOMMENDATION_CATEGORY.MISSING_ST_SAVINGS]: {
    planStep: [PLAN_BUILD_STEPS.ASSETS_AND_DEBTS],
    message:
      "You have a short-term savings goal. Based on your plan, it looks like you are not saving enough to achieve it by your estimated goal date. Consider allocating more contributions to your Savings in Step 2.",
  },
  [RECOMMENDATION_CATEGORY.ROTH_IRA_MIN]: {
    planStep: [PLAN_BUILD_STEPS.ASSETS_AND_DEBTS],
    message:
      "You do not have any contributions to your Roth IRA. We recommend making at least a $50 contribution per month.",
  },
  [RECOMMENDATION_CATEGORY.ROTH_IRA_MIN_MARRIED]: {
    planStep: [PLAN_BUILD_STEPS.ASSETS_AND_DEBTS],
    message:
      "You and/or your spouse do not have any contributions to your Roth IRA. We recommend making at least a $50 contribution per person, per month.",
  },
  [RECOMMENDATION_CATEGORY.IRA_EARNED_INCOME]: {
    planStep: [PLAN_BUILD_STEPS.ASSETS_AND_DEBTS],
    message:
      "You must have earned income in order to contribute to a Roth or Traditional IRA. We recommend speaking to an accountant to see if you can contribute.",
  },
  [RECOMMENDATION_CATEGORY.PRE_TAX_RETIREMENT_MIN]: {
    planStep: [PLAN_BUILD_STEPS.ASSETS_AND_DEBTS],
    message:
      "You don't have any contributions to your employer's retirement plan or a traditional IRA.  FitBUX recommends making at least a $50 contribution per month to one of these accounts.",
  },
  [RECOMMENDATION_CATEGORY.ROTH_IRA_MAX]: {
    planStep: [PLAN_BUILD_STEPS.ASSETS_AND_DEBTS],
    message:
      "If your modified adjusted gross income is greater than $144,000 ($214,000 if married), you can not contribute to a Roth IRA. Your gross income is above or close to that amount. We recommend speaking to an accountant to see if you can contribute.",
  },
  [RECOMMENDATION_CATEGORY.ROTH_IRA_TAX]: {
    planStep: [PLAN_BUILD_STEPS.ASSETS_AND_DEBTS],
    message:
      "If your modified adjusted gross income is greater than $10,000 or you are married but file your taxes separately, you may not be able to contribute to a Roth IRA. We recommend speaking to an accountant to see if you can contribute.",
  },
  [RECOMMENDATION_CATEGORY.IRA_CONTRIBUTION_LIMIT]: {
    planStep: [PLAN_BUILD_STEPS.ASSETS_AND_DEBTS],
    message:
      "The annual limit to contribute to the combination of Roth and Traditional IRAs this year is $6,000 per person per year (or $7,000 if age 50 or older). Adjust your plan on Step 2 to ensure you do not exceed these thresholds.",
  },
  [RECOMMENDATION_CATEGORY.HSA_CONTRIBUTION_LIMIT]: {
    planStep: [PLAN_BUILD_STEPS.ASSETS_AND_DEBTS],
    message: `The maximum you can contribute to an HSA this year is ${formatWholeDollars(
      HSALimit
    )} for individuals and $7,300 for families (with an extra $1,000 allowed for those 55 and older).  Adjust your plan on Step 2 to ensure you do not exceed these thresholds.`,
  },
  [RECOMMENDATION_CATEGORY["401K_CONTRIBUTION_LIMIT"]]: {
    planStep: [PLAN_BUILD_STEPS.ASSETS_AND_DEBTS],
    message:
      "The maximum you can contribute to the combination of Roth and Traditional 401(k)/403(b)/457/TSPs this year is $20,500 per person (or $26,000 if age 50 or older). Adjust your plan on Step 2 to ensure you do not exceed these thresholds.",
  },
  [RECOMMENDATION_CATEGORY.IDR_TAX_BOMB]: {
    planStep: [PLAN_BUILD_STEPS.ASSETS_AND_DEBTS],
    message:
      "You chose an income-driven repayment plan for your student loans.  Currently, you are not saving enough for the tax liability.",
    help: [
      {
        header: "IDR Tax",
        body: [
          "When you are on a loan forgiveness plan (IDR, IBR, PAYE, REPAYE) your loans will be forgiven in 20 or 25 years.",
          "However, you have to claim the amount forgiven as income in that tax year which means you will pay income taxes on the money.",
          "For example, if you still owe $200,000 in 20 years and are on PAYE, your loans will be forgiven meaning you don't owe $200,000.  However, you have to claim the $200,000 as income in that tax year.",
          "Therefore, we highly recommend you begin saving for the tax liability.",
        ],
      },
    ],
  },
  [RECOMMENDATION_CATEGORY.IDR_TAX_BOMB_PSLF]: {
    planStep: [PLAN_BUILD_STEPS.ASSETS_AND_DEBTS],
    message:
      "You are pursuing PSLF for your Federal student loans.  Would you like to save for the tax liability should you not qualify for PSLF in the future?",
    help: [
      {
        header: "PSLF & Saving For A Tax?",
        body: [
          "When you qualify for PSLF, you DO NOT pay a tax when your loans are forgiven.",
          "However, we often times see FitBUX Members discontinue working at a non-profit within 2 - 4 years of their start date.",
          "If you do not go to another non-profit, you still qualify for forgiveness in 20-25 years.  However, when your loans are forgiven, you will owe taxes.",
          "Therefore, FitBUX recommends saving for 2 - 4 years as though you aren't on PSLF.  Doing so will give you peace of mind and provide you with greater financial independence.",
        ],
      },
    ],
  },
};
