import React, { useEffect } from "react";
import { useDispatch } from "react-redux";

import { Box, Typography } from "@material-ui/core";

import { editTransactionApi, getBreakoutsApi } from "src/apiService";
import { Breakout, INCOME_BREAKOUTS } from "src/interfaces";
import { updateTransaction } from "src/store/transaction/actions";

const IncomeExplainer = ({ formValues, setFormValues, transaction }: any) => {
  const dispatch = useDispatch();
  useEffect(() => {
    if (!formValues.deductions || !formValues.incomes) {
      let promise = Promise.resolve();
      if (
        formValues.type !== transaction.type ||
        formValues.description !== transaction.description
      ) {
        const update = {
          type: formValues.type,
          description: formValues.description,
        };
        promise = editTransactionApi(transaction.id, update).then(() => {
          dispatch(updateTransaction({ id: transaction.id, update }));
        });
      }
      promise.then(() => {
        getBreakoutsApi(transaction.id)
          .then((data: Breakout[]) => {
            const incomes: Breakout[] = [];
            const deductions: Breakout[] = [];
            data.forEach((item: Breakout) => {
              if (!("" + item.type in INCOME_BREAKOUTS)) {
                deductions.push(item);
              } else {
                incomes.push(item);
              }
            });
            if (transaction.type === 7000 || transaction.type === 7001) {
              if (!incomes.length) {
                incomes.push({ type: 1001, amount: 0, id: Math.random() });
              }
              if (!deductions.length) {
                deductions.push(
                  { type: 12302, amount: 0, id: Math.random() },
                  { type: 15000, amount: 0, id: Math.random() },
                  { type: 13102, amount: 0, id: Math.random() }
                );
              }
            } else if (transaction.type === 7002) {
              incomes.push({ type: 1008, amount: 0, id: Math.random() });
            } else if (transaction.type === 7003) {
              incomes.push({ type: 1010, amount: 0, id: Math.random() });
            }
            setFormValues((current: typeof formValues) => ({
              ...current,
              incomes,
              deductions,
            }));
          })
          .catch(console.error);
      });
    }
  }, []);

  return (
    <Box>
      <img
        src="/assets/images/animations/wave.gif"
        alt="Waving Hand"
        style={{ display: "block", width: "200px", margin: "0 auto" }}
      />
      <Typography variant="h2" component="h2">
        Income Transactions
      </Typography>
      <Typography paragraph variant="body1">
        Your Total Income is key to tracking your plan. Please enter your Total
        Income and your deductions from your paycheck on the next two screens.
        Be sure that the Net Income calculated (Net Income = Total Income minus
        Deductions) matches the take home pay on your paycheck.
      </Typography>
      <Typography paragraph variant="body1">
        For recurring transactions of a similar amount, you will only need to do
        this once.
      </Typography>
    </Box>
  );
};

export default IncomeExplainer;
