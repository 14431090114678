import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import { cloneDeep, map, pick } from "lodash";

import {
  Box,
  Grid,
  makeStyles,
  Typography,
  FormControl,
  FormLabel,
  MenuItem,
  Select,
} from "@material-ui/core";

import { profileBuilderStyles } from "src/theme";

import CenterContainer from "../../../Components/CenterContainer";
import Button from "src/components/Button";
import { getLiabilities, updateCurrentPlan } from "src/store/planBuild/actions";
import { getSpouseFedLoanPayments } from "src/store/account/selector";
import {
  currentPlanIncomeTotal,
  getCurrentPlan,
  getLiabilities as selectLiabilities,
  getRawIncomes,
  getStudentLoanData,
} from "src/store/planBuild/selector";
import { getProfileRepayment } from "src/store/profileBuild/selector";
import { getIsMarried } from "src/store/system/selector";
import {
  DEBT_TYPES,
  Plan,
  PlanViewComponent,
  REPAYMENT_PLANS,
  SidebarContent,
  SPECIAL_REPAYMENTS,
} from "src/interfaces";
import PercentBreakdown from "src/components/PercentBreakdown";

const useStyles = makeStyles(profileBuilderStyles);

const blowout: SidebarContent[] = [
  {
    header: "There are 2 primary repayment strategies:",
    body: [
      "1) You can elect to pay off your loans.",
      'You can either pay off your loan(s) "as is" using the Standard 10 year plan or extend your loans to reduce your required monthly payment by using the Extended Fixed plan. The latter provides more flexibility, but increases the total interest paid over time if you take longer than 10 years to payback your loan(s).',
      "2) Loan forgiveness strategies (IBR, PAYE, and REPAYE).",
      "If you wish to pursue Public Service Loan Forgiveness (PSLF), you must select one of the income-driven plans above as PSLF is only eligible with certain repayment plans.",
    ],
  },
];

const AddOrEditStudentLoanSpouse: PlanViewComponent = ({
  onClose,
  onSave,
  render,
}) => {
  const styles = useStyles();
  const dispatch = useDispatch();
  const plan: Plan = useSelector(getCurrentPlan);
  const isMarried = useSelector(getIsMarried);
  const totalIncome = useSelector(currentPlanIncomeTotal);
  const incomes = useSelector(getRawIncomes);
  const studentloan = useSelector(getStudentLoanData);
  const profileRepayment = useSelector(getProfileRepayment);
  const liabilities = useSelector(selectLiabilities);
  const spouseFedLoanPayments = useSelector(getSpouseFedLoanPayments);
  const spouseStudentLoanLiability = Math.max(
    spouseFedLoanPayments,
    liabilities.min.solo[1].fed_loan || 0
  );
  const myStudentLoanEntry = plan.studentloan[0];
  const initialFormValues = {
    contribution: plan.allocations[0].solo[1]?.fed_loan || 0,
    repayplan: myStudentLoanEntry?.repayplan || profileRepayment || "std_plan",
    filing_jointly: plan.profile.filing_jointly ? "y" : "n",
    idroption: myStudentLoanEntry?.idroption || "standard",
    strategy: myStudentLoanEntry?.strategy || "high_interest_rate",
    who: "spouse",
  };
  const [formValues, setFormValues] = useState<any>(initialFormValues);
  useEffect(() => {
    // Initialize the min payments with the current values on the form
    estimateMinPayments("repayplan", formValues);
  }, []);

  const inschool = !!plan.education;

  const save = () => {
    const soloAllocations = [...plan.allocations[0].solo];
    const whoIndex = formValues.who === "applicant" ? 0 : 1;
    soloAllocations[whoIndex] = {
      ...soloAllocations[whoIndex],
      fed_loan: formValues.contribution,
    };
    const planAllocations = [...plan.allocations];
    planAllocations[0] = { ...planAllocations[0] };
    planAllocations[0].solo = soloAllocations;
    planAllocations[0].fed_loan =
      (soloAllocations[0]?.fed_loan || 0) + (soloAllocations[1]?.fed_loan || 0);
    const updatedStudentloan: any = [...plan.studentloan];
    updatedStudentloan[whoIndex] = {
      ...pick(studentloan[whoIndex], [
        "perkinscancel",
        "contribution",
        "repayplan",
        "idroption",
        "strategy",
        "who",
        "start",
      ]),
      ...pick(formValues, ["repayplan", "idroption", "strategy", "who"]),
    };
    const planProfile = { ...plan.profile };
    planProfile.filing_jointly = formValues.filing_jointly === "y";
    dispatch(
      updateCurrentPlan({
        allocations: planAllocations,
        studentloan: updatedStudentloan,
        profile: planProfile,
      })
    );
    onSave();
  };

  const setFormValue = (e: React.ChangeEvent<any>) => {
    const field = e.target.name;
    const value = e.target.value;
    const newFormValues = { ...formValues, [field]: value };
    setFormValues(newFormValues);
    estimateMinPayments(field, newFormValues);
  };

  const estimateMinPayments = (field: string, newFormValues: any) => {
    if (
      field === "repayplan" ||
      field === "idroption" ||
      field === "strategy" ||
      field === "filing_jointly"
    ) {
      const studentLoanDetails = [
        cloneDeep(studentloan[0]),
        cloneDeep(studentloan[1]),
      ];
      const selectedDetails =
        formValues.who === "spouse"
          ? studentLoanDetails[1]
          : studentLoanDetails[0];
      selectedDetails.repayplan = newFormValues.repayplan;
      selectedDetails.idroption = newFormValues.idroption;
      selectedDetails.strategy = newFormValues.strategy;
      dispatch(
        getLiabilities({
          filing_jointly: newFormValues.filing_jointly === "y",
          inschool,
          incomes: incomes || [],
          studentloan: studentLoanDetails,
          allocations: plan.allocations[0],
        })
      );
    }
  };

  const min = spouseStudentLoanLiability;
  // min = (Math.ceil(min * 100) / 100);

  const nextDisabled = (formValues.contribution || 0) < min;

  return render({
    component: (
      <CenterContainer
        scrollable
        title="Federal Student Loan"
        iconName="fb-scales-tripped"
        help={blowout}
      >
        <Grid container spacing={2} className="mt-4">
          <Grid item xs={6}>
            <FormControl component="fieldset" fullWidth>
              <FormLabel component="legend" className="mb-4">
                <Typography style={{ fontSize: "13px" }}>
                  {" "}
                  Choose your spouse's student loan repayment plan:
                </Typography>
              </FormLabel>
              <Select
                variant="outlined"
                fullWidth
                value={formValues.repayplan}
                name="repayplan"
                placeholder="Select"
                onChange={setFormValue}
              >
                {map(REPAYMENT_PLANS, (label: string, key: string) => (
                  <MenuItem key={key} value={key}>
                    {label}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          {/* {SPECIAL_REPAYMENTS.indexOf(formValues.repayplan) < 0 &&
          <Grid item xs={6}>
            <FormControl component="fieldset" fullWidth>
              <FormLabel component="legend" className="mb-4">Choose a repayment method:</FormLabel>
              <Select
                variant="outlined"
                fullWidth
                value={formValues.strategy}
                name="strategy"
                placeholder="Select"
                onChange={setFormValue}
              >
                <MenuItem value="high_interest_rate">High interest rate</MenuItem>
                <MenuItem value="low_balance">Low balance</MenuItem>
              </Select>
            </FormControl>
          </Grid>
          } */}
          {SPECIAL_REPAYMENTS.indexOf(formValues.repayplan) >= 0 && (
            <>
              <Grid item xs={6}>
                <FormControl component="fieldset" fullWidth>
                  <FormLabel component="legend" className="mb-4">
                    Select one:
                  </FormLabel>
                  <Select
                    variant="outlined"
                    fullWidth
                    value={formValues.idroption}
                    name="idroption"
                    placeholder="Select"
                    onChange={setFormValue}
                  >
                    <MenuItem value="standard">
                      Pursuing forgiveness and facing tax liability
                    </MenuItem>
                    <MenuItem value="pslf">Pursuing PSLF</MenuItem>
                    <MenuItem value="payoff">
                      Will eventually pay off my loans
                    </MenuItem>
                  </Select>
                </FormControl>
              </Grid>
              {isMarried && (
                <Grid item xs={12}>
                  <FormControl component="fieldset" fullWidth>
                    <FormLabel component="legend" className="mb-4">
                      How do you and your spouse plan on filing your taxes?
                    </FormLabel>
                    <Select
                      variant="outlined"
                      fullWidth
                      value={formValues.filing_jointly}
                      name="filing_jointly"
                      placeholder="Select"
                      onChange={setFormValue}
                    >
                      <MenuItem value="y">Jointly</MenuItem>
                      <MenuItem value="n">Separately</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
              )}
            </>
          )}
        </Grid>

        <Box className="my-6">
          <PercentBreakdown
            min={min}
            income={totalIncome}
            onChange={setFormValues}
            values={formValues}
            label={DEBT_TYPES["fed_loan"]}
            isDebt
          />
        </Box>

        <Button
          variant="outlined"
          fbColor="primary"
          onClick={onClose}
          className={styles.btnCancel}
        >
          Cancel
        </Button>
      </CenterContainer>
    ),
    nextDisabled,
    nextLabel: "Save",
    nextTooltip: nextDisabled
      ? "You must enter at least the minimum contribution."
      : undefined,
    onNext: save,
  });
};

export default AddOrEditStudentLoanSpouse;
