import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setPopup } from "src/store/dashboard/actions";

import { Box, Container, IconButton, Typography } from "@material-ui/core";

import { PlanListRecord } from "src/interfaces";
import { deleteImplementedPlan } from "src/store/system/actions";
import {
  deletePlan,
  fetchSavedPlanProjections,
  implementPlan,
  setBuildStep,
  setCurrentPlan,
  setFurthestStep,
} from "src/store/planBuild/actions";
import { PLAN_BUILD_STEPS } from "src/store/planBuild/constants";
import {
  getLivePlan,
  getLoadedSavedProjections,
  getLoadingSavedProjections,
  getPlans,
  getSavedProjectionsError,
  savedPlanProjectionData,
} from "src/store/planBuild/selector";
import { getHasGraduated } from "src/store/profileBuild/selector";
import { getRefreshNeeded } from "src/store/dashboard/selector";
import {
  getIsCurrentStudent,
  getIsSubscribed,
} from "src/store/system/selector";
import { planListText } from "src/text";
import { AdapterLink } from "src/utils";

import Button from "src/components/Button";
import Chart from "src/components/Chart";
import Icon from "src/components/Icon";
import { push } from "connected-react-router";

const PlanList = () => {
  const dispatch = useDispatch();
  const loadedProjections = useSelector(getLoadedSavedProjections);
  const loadingProjections = useSelector(getLoadingSavedProjections);
  const projectionsError = useSelector(getSavedProjectionsError);
  const projections = useSelector(savedPlanProjectionData);
  const isCurrentStudent = useSelector(getIsCurrentStudent);
  const plans = useSelector(getPlans);
  const livePlan = useSelector(getLivePlan);
  const refreshNeeded = useSelector(getRefreshNeeded);
  const isSubscribed = useSelector(getIsSubscribed);
  const hasGraduated = useSelector(getHasGraduated);

  useEffect(() => {
    if (
      !loadingProjections &&
      ((!loadedProjections && !projectionsError) || refreshNeeded.projection)
    ) {
      dispatch(fetchSavedPlanProjections());
    }
  }, [loadedProjections, loadingProjections, projectionsError]);

  const openPlanForEditing = (plan: PlanListRecord) => {
    dispatch(setCurrentPlan({ planId: plan.id, keepId: false }));
    dispatch(
      setBuildStep(
        isCurrentStudent
          ? PLAN_BUILD_STEPS.EDUCATION_FUNDING
          : PLAN_BUILD_STEPS.GRADUATED_LIFE_EVENTS_OVERVIEW
      )
    );
    dispatch(
      setFurthestStep(
        isCurrentStudent
          ? PLAN_BUILD_STEPS.REVIEW
          : PLAN_BUILD_STEPS.GRADUATED_REVIEW
      )
    );
    dispatch(setPopup("Build"));
    const destination = plan.questionnaire
      ? "plan-builder-optimized"
      : "/plan-builder";
    dispatch(push(destination));
  };

  const openPlanForImplementing = (plan: PlanListRecord) => {
    if (isSubscribed) {
      dispatch(implementPlan(plan.id));
    } else {
      dispatch(setCurrentPlan({ planId: plan.id, keepId: false }));
      dispatch(setBuildStep(PLAN_BUILD_STEPS.IMPLEMENT));
    }
  };

  const handleDeletePlan = (id: number) => {
    if (id === livePlan?.id) {
      dispatch(deleteImplementedPlan());
    }
    dispatch(deletePlan(id));
  };

  return (
    <Container className="p-10 pr-0 mr-0">
      <Box className="w-full">
        <Typography variant="h2" component="h2" className="mb-3">
          Your FitBUX Plan
        </Typography>
        <Typography component="p" className="my-6" variant="body1">
          {planListText}
        </Typography>
        {/*plans.length < 3 && !hasGraduated && (
          <Button
            className="mr-4"
            fbColor="primary"
            component={AdapterLink}
            to="/plan-builder"
            onClick={loadDefaultPlan}
          >
            Build a New Plan
          </Button>
        )}
        {hasGraduated && (
          <Button className="mr-4" fbColor="primary" onClick={openGradDialog}>
            Build a New Plan
          </Button>
        )}
        {/*plans.length >= 1 && (
          <Button
            disabled={!compareReady}
            fbColor="secondary"
            to="/plan-builder"
            onClick={launchCompare}
          >
            Compare Plans
          </Button>
        )*/}
      </Box>
      <Box className="flex mt-24">
        {plans.map((plan: PlanListRecord, i: number) => {
          // hide if plan has messages and the questionnaire is dropped;
          return (
            <Box key={plan.id} className="mr-6">
              <Chart
                // isLocked={disabled}
                dollars
                actions={
                  <Box className="flex justify-between items-center w-10/12 my-4 mx-auto">
                    <Button
                      disabled={isCurrentStudent && hasGraduated}
                      style={{ display: "block", textAlign: "center" }}
                      fbColor="secondary"
                      component={AdapterLink}
                      onClick={() => openPlanForEditing(plan)}
                    >
                      Edit
                    </Button>
                    <Button
                      disabled={
                        plan.implemented || (isCurrentStudent && hasGraduated)
                      }
                      style={{ display: "block", textAlign: "center" }}
                      fbColor="primary"
                      component={AdapterLink}
                      to="/plan-builder"
                      onClick={() => openPlanForImplementing(plan)}
                    >
                      {plan.implemented ? "Implemented" : "Implement"}
                    </Button>
                    <IconButton
                      onClick={() => {
                        handleDeletePlan(plan.id);
                      }}
                    >
                      <Icon iconName="fb-trash-can" />
                    </IconButton>
                  </Box>
                }
                data={projections[i]}
                loading={loadingProjections && !projections[i]}
                title={plan.name}
                headComponent={
                  undefined /*
                plans.length < 2 ? undefined : (
                  <FormControlLabel
                    disabled={!compareSelections[i] && compareReady}
                    control={
                      <Checkbox
                        checked={compareSelections[i]}
                        onClick={() => toggleSelected(i)}
                      />
                    }
                    label="Compare"
                  />
                )
              */
                }
                keys={["net"]}
                keyLabels={{
                  net: "Net Wealth",
                }}
                xKey="month"
              />
            </Box>
          );
        })}
      </Box>
    </Container>
  );
};

export default PlanList;
