import React from "react";
import { useSelector } from "react-redux";

import { makeStyles } from "@material-ui/core/styles";

import Chart from "src/components/Chart";
import {
  getDashboardScoreProjection,
  getLoadingState,
} from "src/store/planBuild/selector";
import { getHasPlan, getIsSubscribed } from "src/store/system/selector";
import { chartStyles } from "src/theme/base";
import { AdapterLink } from "src/utils";

const useStyles = makeStyles(chartStyles);

const PlanSummaryChart = () => {
  const styles = useStyles();
  const subscribed = useSelector(getIsSubscribed);
  const hasPlan = useSelector(getHasPlan);
  const scoreProjection = useSelector(getDashboardScoreProjection);
  const loading = useSelector(getLoadingState);
  const mockData = [
    {
      month: 0,
      uv: 4000,
      pv: 2400,
      amt: 2400,
    },
    {
      month: 12,
      uv: 3000,
      pv: 1398,
      amt: 2210,
    },
    {
      month: 24,
      uv: 2000,
      pv: 9800,
      amt: 2290,
    },
    {
      month: 36,
      uv: 2780,
      pv: 3908,
      amt: 2000,
    },
    {
      month: 48,
      uv: 1890,
      pv: 4800,
      amt: 2181,
    },
    {
      month: 60,
      uv: 2390,
      pv: 3800,
      amt: 2500,
    },
    {
      month: 72,
      uv: 3490,
      pv: 4300,
      amt: 2100,
    },
  ];

  if (!subscribed || !hasPlan) {
    return (
      <Chart
        isLocked
        lockedButton={{
          label: "Build and Implement My Plan",
          to: "/plan-list",
        }}
        title="My Financial Plan Summary"
        data={mockData}
        xKey="name"
        keys={["uv", "pv", "amt"]}
        keyLabels={{
          uv: "UV",
          pv: "PV",
          amt: "AMT",
        }}
        width="100%"
      />
    );
  }
  return (
    <Chart
      loading={loading.projection}
      title="Projected FitBUX Score"
      headComponent={
        <AdapterLink to="/plan-list" className={styles.chartLink}>
          Explore My Plan
        </AdapterLink>
      }
      data={scoreProjection}
      height="244px"
      keys={["score", "min"]}
      xKey="month"
      keyLabels={{
        min: "Plan Estimated",
        score: "Current Projected",
      }}
      width="100%"
    />
  );
};

export default PlanSummaryChart;
