import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import fbColors from "src/theme/colors";
import { Box, makeStyles } from "@material-ui/core";

import Button from "src/components/Button";
import {
  ASSET_ALLOCATIONS,
  DEBT_ALLOCATIONS,
  SPECIAL_ALLOCATIONS,
  TAX_DEFERRED_INVESTMENT_TYPES,
  PlanViewComponent,
  HouseholdMinLiabilities,
} from "src/interfaces";
import {
  savePlan,
  updateAllocations,
  estimateCurrentPlanTaxes,
} from "src/store/planBuild/actions";
import {
  currentPlanIncomeTotal,
  getCurrentPlan,
  getLiabilities,
} from "src/store/planBuild/selector";
import { getIsMarried } from "src/store/system/selector";

import AssetCategories from "./AssetCategories";
import AddEdit401k from "./AddEdit401k";
import AddEditIRA from "./AddEditIRA";
import FedStudentLoan from "./FedStudentLoan";
import FedStudentLoanSpouse from "./CuratedSpouseViews/FedStudentLoanSpouse";
import CenterContainer from "../../Components/CenterContainer";
import ShortTemSavingsGoal from "./ShortTermSavingsGoal";
import Hsa from "./Hsa";
import InvestVSPayDebt from "./InvestVSPayDebt";
import { CURRATED_PLAN_BUILD_STEPS } from "./common";
import DebtPrepayment from "./DebtPrepayments";
import PerkinsLoan from "./Debts/PerkinsLoan";
import PrivateLoan from "./Debts/PrivateLoans";
import CreditCardLoan from "./Debts/CreditCardLoan";
import PersonalLoan from "./Debts/PersonalLoan";
import PrimaryMortgage from "./Debts/PrimaryMortgage";
import InvestmentProperty from "./Debts/InvestmentProperty";
import AutoLoan from "./Debts/AutoLoan";
import OtherDebt from "./Debts/OtherDebt";
import RothIRA from "./RothIRA";
import RothIRASpouse from "./CuratedSpouseViews/RothIRASpouse";

import AddOrEdit401kSpouse from "./CuratedSpouseViews/AddEdit401kSpouse";
import { HSALimit, HSALimitMarried } from "src/constants";
import PercentBreakdown from "src/components/PercentBreakdown";

const curatedSteps = {
  [CURRATED_PLAN_BUILD_STEPS.SHORT_TERM_GOAL]: ShortTemSavingsGoal,
  [CURRATED_PLAN_BUILD_STEPS.EMPLOYER_RETIREMENT]: AddEdit401k,
  [CURRATED_PLAN_BUILD_STEPS.EMPLOYER_RETIREMENT_SPOUSE]: AddOrEdit401kSpouse,
  [CURRATED_PLAN_BUILD_STEPS.HSA]: Hsa,
  [CURRATED_PLAN_BUILD_STEPS.ROTH_IRA]: RothIRA,
  [CURRATED_PLAN_BUILD_STEPS.ROTH_IRA_SPOUSE]: RothIRASpouse,
  [CURRATED_PLAN_BUILD_STEPS.INVEST_OR_PAYOFF]: InvestVSPayDebt,
  [CURRATED_PLAN_BUILD_STEPS.STUDENT_LOAN]: FedStudentLoan,
  [CURRATED_PLAN_BUILD_STEPS.STUDENT_LOAN_SPOUSE]: FedStudentLoanSpouse,
  [CURRATED_PLAN_BUILD_STEPS.HIGH_LOW]: DebtPrepayment,
  // [CURRATED_PLAN_BUILD_STEPS.MORTGAGE_DEBT]: MortgageDebt,
  [CURRATED_PLAN_BUILD_STEPS.PERKINS_LOANS]: PerkinsLoan,
  [CURRATED_PLAN_BUILD_STEPS.PRIVATE_LOANS]: PrivateLoan,
  [CURRATED_PLAN_BUILD_STEPS.CREDIT_CARD_LOANS]: CreditCardLoan,
  [CURRATED_PLAN_BUILD_STEPS.PERSONAL_LOANS]: PersonalLoan,
  [CURRATED_PLAN_BUILD_STEPS.PRIMARY_MORTGAGE]: PrimaryMortgage,
  [CURRATED_PLAN_BUILD_STEPS.INVESTMENT_PROPERTY_MORTGAGE]: InvestmentProperty,
  [CURRATED_PLAN_BUILD_STEPS.AUTO_DEBT]: AutoLoan,
  [CURRATED_PLAN_BUILD_STEPS.OTHER_DEBT]: OtherDebt,
};

const AddAssetOrDebt: PlanViewComponent = ({
  editingSpecialType,
  isDebt,
  onClose,
  curatedIndex,
  render,
}) => {
  const dispatch = useDispatch();
  const styles = useStyles();

  const isMarried = useSelector(getIsMarried);
  const minLiabilities = useSelector(getLiabilities).min;
  const totalIncome = useSelector(currentPlanIncomeTotal);
  const plan = useSelector(getCurrentPlan);
  const types = isDebt ? DEBT_ALLOCATIONS : ASSET_ALLOCATIONS;
  const [formState, setFormState] = useState<any>({
    type: "cash_value",
    percent: "",
  });
  const [showCategories, setShowCategories] = useState(!editingSpecialType);
  const [addingSpecialType, setAddingSpecialType] = useState<any>(
    editingSpecialType
  );
  const allocationType: any =
    types[formState.type as keyof typeof types] || ASSET_ALLOCATIONS.cash_value;
  const typeLabel: string = allocationType.label;
  const unlimitedType = typeLabel.includes("ira");

  const save = () => {
    if (!showCategories && !addingSpecialType) {
      if (formState.percent) {
        const newAllocations = {
          ...plan.allocations[0],
          [formState.type]: formState.percent,
        };
        dispatch(updateAllocations(newAllocations));
        if (formState.type in TAX_DEFERRED_INVESTMENT_TYPES) {
          dispatch(estimateCurrentPlanTaxes());
        }
        dispatch(savePlan(null));
      }
    }
    onClose();
  };

  const selectCategory = (category: any) => {
    setShowCategories(false);
    const specialAllocation = [
      ...SPECIAL_ALLOCATIONS,
      "cash_value",
      "hsa_value",
    ];
    if (specialAllocation.indexOf(category.key) >= 0) {
      setAddingSpecialType(category);
    }
    setFormState({ type: category.key, percent: "" });
  };

  const renderSpecialType = () => {
    let SubComponent = null;
    switch (addingSpecialType.key || addingSpecialType.type) {
      case "fed_loan":
        SubComponent = FedStudentLoan;
        break;
      case "roth_401k_value":
      case "401k_value":
        SubComponent = AddEdit401k;
        break;
      case "cash_value":
        SubComponent = ShortTemSavingsGoal;
        break;
      case "hsa_value":
        SubComponent = Hsa;
        break;
      case "roth_ira_value":
      case "ira_value":
      default:
        SubComponent = AddEditIRA;
    }
    return (
      <SubComponent
        initialValues={editingSpecialType}
        onClose={closeSpecialType}
        onSave={save}
        render={render}
        type={addingSpecialType.key || addingSpecialType.type}
      />
    );
  };

  const renderCuratedType = () => {
    const SubComponent =
      curatedSteps[curatedIndex as keyof typeof curatedSteps];
    let type =
      curatedIndex === CURRATED_PLAN_BUILD_STEPS.ROTH_IRA ||
      curatedIndex === CURRATED_PLAN_BUILD_STEPS.ROTH_IRA_SPOUSE
        ? "ira_value"
        : "";
    type =
      curatedIndex === CURRATED_PLAN_BUILD_STEPS.EMPLOYER_RETIREMENT ||
      curatedIndex === CURRATED_PLAN_BUILD_STEPS.EMPLOYER_RETIREMENT_SPOUSE
        ? "401k_value"
        : "";
    return (
      <SubComponent
        // initialValues={editingSpecialType}
        onClose={onClose}
        onSave={save}
        render={render}
        curatedIndex={curatedIndex}
        type={type}
      />
    );
  };

  const renderCategories = () => (
    <AssetCategories isDebt={isDebt} onSelect={selectCategory} />
  );

  const closeSpecialType = () => {
    if (editingSpecialType) {
      onClose();
    } else {
      setAddingSpecialType(null);
      setShowCategories(true);
    }
  };

  if (addingSpecialType && (addingSpecialType.type || addingSpecialType.key)) {
    return renderSpecialType();
  } else if (
    curatedIndex &&
    curatedIndex <= CURRATED_PLAN_BUILD_STEPS.OTHER_DEBT
  ) {
    return renderCuratedType();
  }

  let max = 0;
  if (formState.type === "hsa_value") {
    const maxDollars = isMarried ? HSALimitMarried : HSALimit;
    max = maxDollars;
  }
  let min = 0;
  if (Object.prototype.hasOwnProperty.call(minLiabilities, formState.type)) {
    min =
      minLiabilities[formState.type as keyof HouseholdMinLiabilities];
  }

  const nextDisabled =
    !showCategories &&
    (formState.percent / 100 * totalIncome < min || (!!max && formState.percent / 100 * totalIncome > max));

  return render({
    component: showCategories ? (
      renderCategories()
    ) : (
      <CenterContainer
        scrollable
        title={typeLabel}
        iconName={isDebt ? "fb-scales-tripped" : "fb-model"}
      >
        <Box className="mt-5">
          <PercentBreakdown
            min={min}
            income={totalIncome}
            onChange={setFormState}
            values={formState}
            label={typeLabel}
            isDebt={isDebt}
            unlimited={unlimitedType}
            incomeType={unlimitedType ? "Eligible" : "Total"}
          />
        </Box>
        <Button
          variant="outlined"
          fbColor="primary"
          onClick={() => {
            setShowCategories(true);
          }}
          className={styles.btnCancel}
        >
          Cancel
        </Button>
      </CenterContainer>
    ),
    nextDisabled,
    nextLabel: "Save",
    onNext: save,
  });
};

export default AddAssetOrDebt;

const useStyles = makeStyles({
  cell: {
    width: "30%",
    fontSize: 12,
  },
  headerCell: {
    fontWeight: "bolder",
    width: "30%",
    fontSize: 12,
    marginBottom: 15,
    color: fbColors.brandingBlue1,
  },
  row: {
    display: "flex",
    alignItems: "center",
  },
  btnCancel: {
    display: "block",
    margin: "20px auto",
    width: "calc(100% - 50px)",
  },
});
