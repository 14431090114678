export enum EXAMPLE_ENUM {
  ENUM1 = "ENUM1",
  ENUM2 = "ENUM2",
}

export const NOT_APPLICABLE = "N/A";

export const MAX_401K_ANNUAL_CONTRIBUTION = 22500;
export const MAX_IRA_ANNUAL_CONTRIBUTION = 6500;
export const ROTH_IRA_MAGI_MAX = 153000;
export const ROTH_IRA_MAGI_MARRIED_MAX = 228000;
export const ROTH_IRA_MAGI_SEPARATED_MAX = 10000;
export const HSALimit = 3850;
export const HSALimitMarried = 7750;

export const MONTHS = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];

export const DAYS_IN_MONTH = [
  0,
  31,
  29,
  31,
  30,
  31,
  30,
  31,
  31,
  30,
  31,
  30,
  31,
];

export const Months = [
  {
    abbreviation: "Jan",
    name: "January",
  },
  {
    abbreviation: "Feb",
    name: "February",
  },
  {
    abbreviation: "Mar",
    name: "March",
  },
  {
    abbreviation: "Apr",
    name: "April",
  },
  {
    abbreviation: "May",
    name: "May",
  },
  {
    abbreviation: "Jun",
    name: "June",
  },
  {
    abbreviation: "Jul",
    name: "July",
  },
  {
    abbreviation: "Aug",
    name: "August",
  },
  {
    abbreviation: "Sep",
    name: "September",
  },
  {
    abbreviation: "Oct",
    name: "October",
  },
  {
    abbreviation: "Nov",
    name: "November",
  },
  {
    abbreviation: "Dec",
    name: "December",
  },
];

const currentYear = new Date().getFullYear();
export const YEARS_FROM_NOW: number[] = [];
for (let i = 0; i < 30; i++) {
  YEARS_FROM_NOW.push(currentYear + i);
}

export const STATES = [
  "AL",
  "AK",
  "AZ",
  "AR",
  "CA",
  "CO",
  "CT",
  "DC",
  "DE",
  "FL",
  "GA",
  "HI",
  "ID",
  "IL",
  "IN",
  "IA",
  "KS",
  "KY",
  "LA",
  "ME",
  "MD",
  "MA",
  "MI",
  "MN",
  "MS",
  "MO",
  "MT",
  "NE",
  "NV",
  "NH",
  "NJ",
  "NM",
  "NY",
  "NC",
  "ND",
  "OH",
  "OK",
  "OR",
  "PA",
  "RI",
  "SC",
  "SD",
  "TN",
  "TX",
  "UT",
  "VT",
  "VA",
  "WA",
  "WV",
  "WI",
  "WY",
];
