import React, { useEffect, useMemo, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { push } from "connected-react-router";
import { cloneDeep, omit, sortBy } from "lodash";

import {
  Box,
  CircularProgress,
  makeStyles,
  Tooltip,
  Typography,
} from "@material-ui/core";

import {
  fetchAccountTickersApi,
  getTargetPortfolioPerformanceApi,
} from "src/apiService";
import Button from "src/components/Button";
import {
  getCashTotal,
  getInvestmentAccounts,
} from "src/store/account/selector";
import { CASH_TICKER } from "src/store/investments/constants";
import { AdapterLink } from "src/utils";

import AnalysisInput from "./AnalysisInput";
import AnalysisResults from "./AnalysisResults";

const useStyles = makeStyles({
  actions: {
    display: "flex",
    justifyContent: "center",
    marginBottom: 20,
    marginTop: 20,
  },
  content: {
    margin: "0 auto",
    maxWidth: 1200,
  },
  progressContainer: {},
  fixedBottom: {
    background: "white",
    display: "flex",
    justifyContent: "space-between",
    position: "fixed",
    height: 60,
    bottom: 0,
    left: 0,
    padding: "10px 40px",
    width: "100%",
  },
});

export const Analysis = ({ goBack }: any) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const accounts = useSelector(getInvestmentAccounts);
  const cashTotal = useSelector(getCashTotal);
  const [tickerBalances, setTickerBalances] = useState<any>({});
  const [total, setTotal] = useState(0);
  const [allocations, setAllocations] = useState<any>({});
  const [allocationsInitialized, setAllocationsInitialized] = useState(false);
  const [results, setResults] = useState<any>(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const scrollRef = useRef<any>(null);

  useEffect(() => {
    if (results && scrollRef.current) {
      scrollRef.current.scrollIntoView({ behavior: "smooth" });
    }
  }, [results, scrollRef]);

  const getCurrentBalances = (overwrite: boolean) => {
    let investedTotal = cashTotal;
    const newTickerBalances: any = {};
    Promise.all(
      accounts.map((account) =>
        fetchAccountTickersApi(account.id).then((results) => {
          results.forEach((item: any) => {
            const ticker = item.ticker_symbol;
            const amount = item.quantity * item.close_price;
            if (!newTickerBalances[ticker]) {
              newTickerBalances[ticker] = { amount };
            } else {
              newTickerBalances[ticker].amount += amount;
            }
            investedTotal += amount;
          });
        })
      )
    ).then(() => {
      if (!newTickerBalances[CASH_TICKER]) {
        newTickerBalances[CASH_TICKER] = { amount: cashTotal };
      } else {
        newTickerBalances[CASH_TICKER].amount += cashTotal;
      }
      Object.keys(newTickerBalances).forEach((key: string) => {
        const item = newTickerBalances[key];
        item.allocation = (item.amount / investedTotal) * 100;
      });
      setTickerBalances(newTickerBalances);
      if (overwrite || !allocationsInitialized) {
        setAllocations(newTickerBalances);
      }
      setTotal(investedTotal);
      setAllocationsInitialized(true);
    });
  };

  useEffect(() => getCurrentBalances(false), [accounts]);

  const addAllocation = (ticker: string) => {
    if (allocations[ticker]) {
      return;
    }
    const newAllocations = cloneDeep(allocations);
    newAllocations[ticker] = { allocation: 0, amount: 0 };
    setAllocations(newAllocations);
  };

  const editAllocation = (ticker: string, value: number) => {
    const newAllocations = cloneDeep(allocations);
    newAllocations[ticker].allocation = value;
    newAllocations[ticker].amount = (value * total) / 100;
    setAllocations(newAllocations);
  };

  const removeAllocation = (ticker: string) =>
    setAllocations(omit(allocations, ticker));
  const reset = () => setAllocations(tickerBalances);

  const run = () => {
    setResults(null);
    setLoading(true);
    const tickerList = Object.keys(allocations).map((ticker) => ({
      ticker,
      weight: allocations[ticker].allocation / 100,
    }));
    getTargetPortfolioPerformanceApi(tickerList)
      .then((response: any) => {
        setResults(response.inception);
      })
      .catch(() => {
        setError(true);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const backButton = (
    <Button fbColor="secondary" onClick={goBack}>
      Back
    </Button>
  );

  const allocationTotal: any = useMemo(() => {
    const raw = Object.values(allocations).reduce(
      (result, allocation: any) => result + allocation.amount,
      0
    ) as number;
    return Math.floor(raw * 100) / 100;
  }, [allocations]);

  const allocationPercent = (allocationTotal / total) * 100;
  const ready = Math.round(allocationPercent * 100) === 10000;

  const displayBalances = useMemo(
    () =>
      sortBy(
        Object.keys(tickerBalances).map((ticker: string) => {
          const item = tickerBalances[ticker];
          return { ...item, ticker };
        }),
        (item) => -item.allocation
      ),
    [tickerBalances]
  );

  const displayAllocations = useMemo(
    () =>
      sortBy(
        Object.keys(allocations).map((ticker: string) => {
          const item = allocations[ticker];
          return { ...item, ticker };
        }),
        (item) =>
          tickerBalances[item.ticker]
            ? -tickerBalances[item.ticker].allocation
            : Infinity
      ),
    [displayBalances, allocations]
  );

  if (error) {
    return (
      <Box className={classes.content}>
        <Box>
          <Typography variant="body1" paragraph>
            One or more of your investments do not have a long enough history
            for analysis.
          </Typography>
          <Typography variant="body1" paragraph>
            Our investment team has been notified and will make the appropriate
            adjustment to run the analysis.
          </Typography>
          <Typography variant="body1" paragraph>
            Come back and check this screen within 24 business hours and the
            analysis will be ready.
          </Typography>
          <Typography variant="body1" paragraph>
            Sorry for the inconvenience.
          </Typography>
        </Box>
        <Box className={classes.actions}>
          {backButton}
          <Button
            fbColor="primary"
            onClick={() => {
              setError(false);
              setResults(null);
            }}
            className="ml-4"
          >
            Close
          </Button>
        </Box>
      </Box>
    );
  }

  return (
    <Box className={classes.content}>
      <AnalysisInput
        allocations={displayAllocations}
        addAllocation={addAllocation}
        editAllocation={editAllocation}
        removeAllocation={removeAllocation}
        tickerBalances={displayBalances}
        allocationTotal={allocationTotal}
        reset={reset}
        total={total}
        updateBalances={() => dispatch(push("/investment-allocations?#tools"))}
      />
      <Box className={classes.actions}>
        {!results && backButton}
        {!ready && (
          <Tooltip title="Updated allocations must sum to 100%.">
            <span>
              <Button
                disabled
                fbColor="primary"
                style={results ? {} : { marginLeft: 20 }}
              >
                Run Analysis
              </Button>
            </span>
          </Tooltip>
        )}
        {!!ready && (
          <Button
            fbColor="primary"
            onClick={run}
            disabled={!ready}
            style={results ? {} : { marginLeft: 20 }}
          >
            Run Analysis
          </Button>
        )}
      </Box>
      {loading && (
        <Box className={classes.progressContainer}>
          <CircularProgress size={80} />
        </Box>
      )}
      {!!results && (
        <>
          <AnalysisResults results={results} setError={setError} />
          <div ref={scrollRef} className={`mb-16 ${classes.actions}`}>
            {backButton}
          </div>
          <Box className={classes.fixedBottom}>
            <Typography variant="h2" component="h2">
              Want FitBUX To Allocate Money For You? Open An Investment Account
              With Us Today!
            </Typography>
            <Button
              fbColor="primary"
              component={AdapterLink}
              to="/betterment/create"
            >
              Open
            </Button>
          </Box>
        </>
      )}
    </Box>
  );
};

export default Analysis;
