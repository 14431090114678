import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { push } from "connected-react-router";

import { Box, makeStyles } from "@material-ui/core";

import { Earning, PlanViewComponent, PLAN_INCOME_TYPES } from "src/interfaces";
import { setMiscState, setRefreshNeeded } from "src/store/dashboard/actions";
import { getMiscState } from "src/store/dashboard/selector";
import { getLiabilities } from "src/store/planBuild/actions";
import { PLAN_BUILD_STEPS } from "src/store/planBuild/constants";
import {
  getCurrentPlan,
  getCurrentStep,
  getPlanBuilderReady,
  getRawIncomes,
  getStudentLoanData,
} from "src/store/planBuild/selector";
import { getIsCurrentStudent } from "src/store/system/selector";
import LeftSidebar from "./Components/LeftSidebar";
import RightSidebar from "./Components/RightSidebar";
import ReviewSidebar from "./Components/ReviewSidebar";
import introViews from "./Views/Introduction";
import incomeAndExpensesViews from "./Views/DayToDayMoney";
import expensesAndInsuranceViews from "./Views/ExpensesAndInsurance";
import futureQuestionsViews from "./Views/FutureQuestions";
import assetsAndDebtsViews from "./Views/MoneyForFutureGoals";
import studentAssetsAndDebtsViews from "./Views/StudentAssetsAndDebts";
import riskManagementViews from "./Views/RiskManagement";
import educationFundingViews from "./Views/EducationFunding";
import lifeEventsViews from "./Views/LifeEvents";
import graduatedLifeEventsViews from "./Views/GraduatedLifeEvents";
import emergencyFundViews from "./Views/EmergencyFund";
import recommendedViews from "./Views/Review";
import reviewViews from "./Views/Review";
import implementViews from "./Views/Implement";

import { planBuilderStyles } from "src/theme";

const useStyles = makeStyles(planBuilderStyles);

const graduateRenderFunctions: { [key: number]: PlanViewComponent } = {
  ...introViews,
  ...graduatedLifeEventsViews,
  ...incomeAndExpensesViews,
  ...assetsAndDebtsViews,
  ...riskManagementViews,
  ...emergencyFundViews,
  ...recommendedViews,
  ...reviewViews,
  ...implementViews,
};

const studentRenderFunctions: { [key: number]: PlanViewComponent } = {
  ...introViews,
  ...educationFundingViews,
  ...expensesAndInsuranceViews,
  ...studentAssetsAndDebtsViews,
  ...futureQuestionsViews,
  ...lifeEventsViews,
  ...reviewViews,
  ...implementViews,
};

export const Content = () => {
  const dispatch = useDispatch();
  const styles = useStyles();

  const step: PLAN_BUILD_STEPS = useSelector(getCurrentStep);
  const currentPlan = useSelector(getCurrentPlan);
  const incomes = useSelector(getRawIncomes);
  const ready = useSelector(getPlanBuilderReady);
  const miscState = useSelector(getMiscState);
  const isCurrentStudent = useSelector(getIsCurrentStudent);
  const studentloan = useSelector(getStudentLoanData);

  const inschool = !!currentPlan.education;

  useEffect(() => {
    if (miscState && !miscState.seenPlanIntro) {
      // dispatch(
      //   setVideo(isCurrentStudent ? "planIntroStudent" : "planIntroGraduated")
      // );
      dispatch(setMiscState({ ...miscState, seenPlanIntro: true }));
    }
    dispatch(setRefreshNeeded({ projection: true }));
  }, []);

  useEffect(() => {
    if (!ready) {
      dispatch(push("/dashboard"));
    }
  }, []);

  const emptyView: PlanViewComponent = ({ render }) =>
    render({ component: <div>No view</div> });

  useEffect(() => {
    const liabilityIncomes: Earning[] = isCurrentStudent
      ? ((currentPlan.education || []).filter(
          (item) =>
            !!PLAN_INCOME_TYPES[item.type] &&
            +(item.date || 0) === new Date().getFullYear()
        ) as Earning[])
      : incomes || [];
    dispatch(
      getLiabilities({
        filing_jointly: currentPlan.profile.filing_jointly,
        inschool,
        incomes: liabilityIncomes,
        studentloan,
        allocations: currentPlan.allocations[0],
      })
    );
    // Initialize the fixed (non-fedloan) liabilities
  }, []);

  const renderFunctions: any = isCurrentStudent
    ? studentRenderFunctions
    : graduateRenderFunctions;

  // This is why the first step enumeration starts at 1. If it started at 0 then this const would evaluate to false and always render "emptyView"
  const View: PlanViewComponent = step ? renderFunctions[step] : emptyView;

  return (
    <Box className={styles.content}>
      <View
        render={({
          component,
          onNext,
          nextDisabled,
          nextLabel,
          nextTooltip,
          hideSidebars,
          displayReviewSidebar,
        }) => (
          <>
            {!hideSidebars && <LeftSidebar />}
            {displayReviewSidebar && <ReviewSidebar />}
            <Box className={styles.main}>{component}</Box>
            {!hideSidebars && (
              <RightSidebar
                onNext={onNext}
                nextLabel={nextLabel}
                nextDisabled={nextDisabled}
                nextTooltip={nextTooltip}
              />
            )}
          </>
        )}
      />
    </Box>
  );
};
