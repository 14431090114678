import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import {
  Typography,
  FormControl,
  FormControlLabel,
  FormLabel,
  RadioGroup,
} from "@material-ui/core";

import CenterContainer from "../../../Components/CenterContainer";
import Button from "src/components/Button";
import Radio from "src/components/Radio";
import {
  savePlan,
  updateAllocations,
  estimateCurrentPlanTaxes,
} from "src/store/planBuild/actions";
import {
  getCurrentPlan,
  getMy401kEligibleIncome,
  getSpouse401kEligibleIncome,
  getSpouseMaxRothIRADollarContribution,
} from "src/store/planBuild/selector";
import { getIsMarried } from "src/store/system/selector";
import { Plan } from "src/interfaces/plan.interface";
import { PlanViewComponent } from "src/interfaces/viewComponent.interface";
import {
  ASSET_TYPES,
  SidebarContent,
  TAX_DEFERRED_INVESTMENT_TYPES,
} from "src/interfaces";

import { useStyles } from "../styles";
import PercentBreakdown from "src/components/PercentBreakdown";

const roth_ira_blowout: SidebarContent[] = [
  {
    header: "Recommendation For ROTH IRA",
    body: [
      "If you are eligible to contribute to a Roth IRA, we highly recommend contributing $50 monthly.",
      "Diversifiying your retirement income and using different account types will help reduce the risk to that income. For instance, if the government changes the tax treatment of assets held in a specific type of account negatively, assets held in a different type of account won't be affeced, all else equal.",
    ],
  },
  {
    header: "What Is A Roth IRA?",
    body: [
      "Roth IRAs are tax-advantaged accounts with special features for withdrawals in retirement:",
      "1) Contributions you make each year do not reduce how much you pay in taxes in the tax year the contribution is made.",
      "2) If you withdraw the money after age 59 1/2, you pay no taxes and no penalties.",
      "3) Early withdrawals are subject to taxes and penalties. However, there are special withdrawals that may not incur taxes, such as using Roth IRA money for a down payment on a house.",
    ],
  },
];

const RothIRASpouse: PlanViewComponent = ({
  initialValues,
  onClose,
  onSave,
  render,
  curatedIndex,
}) => {
  const styles = useStyles();
  const dispatch = useDispatch();
  const plan: Plan = useSelector(getCurrentPlan);
  const isMarried = useSelector(getIsMarried);
  const myEligibleIncome = useSelector(getMy401kEligibleIncome);
  const spouseEligibleIncome = useSelector(getSpouse401kEligibleIncome);
  const type = "roth_ira_value";
  const initialFormValues: any = {
    who: "spouse",
  };
  if (initialValues) {
    initialFormValues.who = initialValues.who;
    const itemIndex = isMarried && initialValues.who === "spouse" ? 1 : 0;
    const soloAllocation = plan.allocations[0].solo[itemIndex];
    initialFormValues.contribution = soloAllocation[type];
  }
  const [formValues, setFormValues] = useState<any>(initialFormValues);
  const maxSelector = getSpouseMaxRothIRADollarContribution;
  const maxDollar = useSelector(maxSelector);
  const [prompt, setPrompt] = useState("n");

  const help = roth_ira_blowout;

  const eligibleIncome = spouseEligibleIncome;

  const save = () => {
    if (prompt === "y") {
      const whoIndex = isMarried && formValues.who === "spouse" ? 1 : 0;
      const soloAllocations = plan.allocations[0].solo;
      const newSoloAllocations = [...soloAllocations];
      newSoloAllocations[whoIndex] = {
        ...soloAllocations[whoIndex],
        [type]: formValues.contribution || 0,
      };
      if (initialValues && formValues.who !== initialValues.who) {
        const otherIndex = whoIndex ? 0 : 1;
        newSoloAllocations[otherIndex] = {
          ...newSoloAllocations[otherIndex],
          [type]: 0,
        };
      }
      let combinedContribution =
        ((newSoloAllocations[0][type as "ira_value" | "roth_ira_value"] || 0) /
          100) *
        myEligibleIncome;
      if (isMarried) {
        combinedContribution +=
          ((newSoloAllocations[1][type as "ira_value" | "roth_ira_value"] ||
            0) /
            100) *
          spouseEligibleIncome;
      }
      const newAllocations = {
        ...plan.allocations[0],
        solo: newSoloAllocations,
        [type]: (combinedContribution / eligibleIncome) * 100,
      };
      dispatch(updateAllocations(newAllocations));
      if (type in TAX_DEFERRED_INVESTMENT_TYPES) {
        dispatch(estimateCurrentPlanTaxes());
      }
    }
    dispatch(savePlan(null));
    onSave();
  };

  const handlePrompt = (event: React.ChangeEvent<HTMLInputElement>) => {
    setPrompt((event.target as HTMLInputElement).value);
  };

  return render({
    component: (
      <CenterContainer
        scrollable
        title={"Roth IRA"}
        iconName={"fb-model"}
        help={help}
      >
        {
          <FormControl component="fieldset" className="mt-5">
            <FormLabel component="legend">
              <Typography style={{ fontSize: "13px" }}>
                Will your spouse be contributing to a Roth IRA?
              </Typography>
            </FormLabel>
            <RadioGroup
              row
              aria-label="prompt"
              name="prompt"
              value={prompt}
              onChange={handlePrompt}
            >
              <FormControlLabel value="n" control={<Radio />} label="No" />
              <FormControlLabel value="y" control={<Radio />} label="Yes" />
            </RadioGroup>
          </FormControl>
        }

        {prompt === "y" && (
          <PercentBreakdown
            max={maxDollar}
            income={eligibleIncome}
            onChange={setFormValues}
            values={formValues}
            label={ASSET_TYPES["roth_ira_value"]}
            isDebt
            incomeType="Eligible"
            who="spouse"
            unlimited
          />
        )}
        {!curatedIndex && (
          <Button
            variant="outlined"
            fbColor="primary"
            onClick={onClose}
            className={styles.btnCancel}
          >
            Cancel
          </Button>
        )}
      </CenterContainer>
    ),
    nextLabel: "Save",
    onNext: save,
  });
};

export default RothIRASpouse;
