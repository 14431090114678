import React from "react";
import { map } from "lodash";

import {
  Container,
  Grid,
  Typography,
  Button,
  makeStyles,
} from "@material-ui/core";

import { LIFE_EVENTS } from "src/interfaces/lifeEvents.interface";
import Icon from "src/components/Icon";
import colors from "src/theme/colors";
import shadows from "src/theme/shadows";
interface LifeEventCategoriesProps {
  onSelect: (type: string) => void;
}

const LifeEventCategories = ({ onSelect }: LifeEventCategoriesProps) => {
  const styles = useStyles();
  return (
    <Container maxWidth="sm" className={styles.main}>
      <Grid container spacing={2}>
        {map(LIFE_EVENTS, (item, type) => (
          <Grid item xs={4}>
            <Button
              fullWidth
              className={styles.card}
              onClick={() => onSelect(type)}
            >
              <Icon iconName={item.icon} />
              <Typography className={styles.cardText}>
                {item.buttonLabel}
              </Typography>
            </Button>
          </Grid>
        ))}
      </Grid>
    </Container>
  );
};

export default LifeEventCategories;

const useStyles = makeStyles({
  main: {
    height: "calc(100vh - 210px)",
    padding: 50,
  },
  card: {
    boxShadow: shadows[2],
    height: 110,
    border: `1px solid ${colors.blueGray7}`,
    "&>span": {
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
      color: colors.brandingBlue1,
      cursor: "pointer",
    },
  },
  cardText: {
    fontSize: 12,
    color: colors.brandingBlue1,
    fontWeight: 500,
    textAlign: "center",
    marginTop: 5,
    height: 24,
  },
});
