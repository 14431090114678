import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";

import { makeStyles } from "@material-ui/core/styles";

import Card from "src/components/Card";
import Accordion from "src/components/Accordion";
import { Account, DEBT_TYPES } from "src/interfaces";
import { getDebtSummary } from "src/store/account/selector";
import { formatThousands } from "src/utils";
import { Button } from "@material-ui/core";
import Icon from "src/components/Icon";
import AddEditManualAccountDialog from "src/pages/Dashboard/MyProfile/Accounts/components/AddEditManualAccountDialog";

const DebtSummary = () => {
  const styles = useStyles();
  const [dialogOpen, setDialogOpen] = useState(false);
  const [selectedAccount, setSelectedAccount] = useState<Account | undefined>();

  useEffect(() => {
    if (!dialogOpen) {
      setSelectedAccount(undefined);
    }
  }, [dialogOpen]);

  const summary = useSelector(getDebtSummary);
  const headButton = () => (
    <Button
      variant="outlined"
      className={styles.button}
      color="primary"
      onClick={() => {
        setSelectedAccount(undefined);
        setDialogOpen(true);
      }}
      endIcon={<Icon iconName="fb-add-alt" />}
    >
      Add account
    </Button>
  );

  const sections: any[] = [];

  if (summary.studentLoans) {
    const studentLoanSection: any = {
      title: "Student Loans",
      titleDetail: formatThousands(summary.studentLoans),
      contentRows: [],
    };
    const studentLoanTypes = [
      {
        type: "fed",
        label: "Federal Loans",
      },
      {
        type: "perkins",
        label: "Perkins Loan",
      },
      {
        type: "priv",
        label: "Private Loan",
      },
    ];
    studentLoanTypes.forEach((loanType) => {
      if (summary[`${loanType.type}LoansTotalMine`]) {
        if (loanType.type === "fed") {
          studentLoanSection.contentRows.push({
            text: "My Federal Loans",
            total: formatThousands(summary[`${loanType.type}LoansTotalMine`]),
            action: (
              <Icon
                iconName="fb-edit"
                className="text-blue text-base cursor-pointer"
                onClick={() => {
                  setSelectedAccount(
                    summary[`${loanType.type}LoansDetailMine`][0]
                  );
                  setDialogOpen(true);
                }}
              />
            ),
          });
        } else if (summary[`${loanType.type}LoansDetailMine`]) {
          summary[`${loanType.type}LoansDetailMine`].forEach(
            (account: Account) => {
              studentLoanSection.contentRows.push({
                text: account.name || loanType.label,
                total: formatThousands(account.balance || 0),
                action: (
                  <Icon
                    iconName="fb-edit"
                    className="text-blue text-base cursor-pointer"
                    onClick={() => {
                      setSelectedAccount(account);
                      setDialogOpen(true);
                    }}
                  />
                ),
              });
            }
          );
        }
      }
      if (summary[`${loanType.type}LoansTotalSpouse`]) {
        if (loanType.type === "fed") {
          studentLoanSection.contentRows.push({
            text: "My Spouse's Federal Loans",
            total: formatThousands(summary[`${loanType.type}LoansTotalSpouse`]),
            action: (
              <Icon
                iconName="fb-edit"
                className="text-blue text-base cursor-pointer"
                onClick={() => {
                  setSelectedAccount(
                    summary[`${loanType.type}LoansDetailSpouse`][0]
                  );
                  setDialogOpen(true);
                }}
              />
            ),
          });
        } else if (summary[`${loanType.type}LoansDetailSpouse`]) {
          summary[`${loanType.type}LoansDetailSpouse`].forEach(
            (account: Account) => {
              studentLoanSection.contentRows.push({
                text: account.name || loanType.label,
                total: formatThousands(account.balance || 0),
                action: (
                  <Icon
                    iconName="fb-edit"
                    className="text-blue text-base cursor-pointer"
                    onClick={() => {
                      setSelectedAccount(account);
                      setDialogOpen(true);
                    }}
                  />
                ),
              });
            }
          );
        }
      }
    });
    sections.push(studentLoanSection);
  }

  const sectionNames = [
    {
      section: "creditCards",
      label: "Credit Cards",
    },
    {
      section: "auto",
      label: "Auto Loans",
    },
    {
      section: "mortgage",
      label: "Mortgages",
    },
    {
      section: "personalLoans",
      label: "Personal Loans",
    },
    {
      section: "other",
      label: "Other",
    },
  ];
  sectionNames.forEach((item) => {
    const sectionName = item.section;
    if (summary[`${sectionName}Total`]) {
      sections.push({
        title: item.label,
        titleDetail: formatThousands(summary[`${sectionName}Total`]),
        contentRows: summary[`${sectionName}Detail`]
          ? summary[`${sectionName}Detail`].map((account: Account) => ({
              text:
                account.name ||
                DEBT_TYPES[account.variable as keyof typeof DEBT_TYPES] ||
                "Other",
              total: formatThousands(
                account.balance_live || account.balance || 0
              ),
              action: (
                <Icon
                  iconName="fb-edit"
                  className="text-blue text-base cursor-pointer"
                  onClick={() => {
                    setSelectedAccount(account);
                    setDialogOpen(true);
                  }}
                />
              ),
            }))
          : undefined,
      });
    }
  });
  return (
    <Card title="Debt Summary" headComponent={headButton}>
      <div className="mt-2">
        <Accordion
          sections={sections}
          footer={{
            text: "Total Debts",
            total: formatThousands(summary.grandTotal),
          }}
        />
      </div>
      <AddEditManualAccountDialog
        open={dialogOpen}
        closeOnSave
        isLinked={false}
        accountTypes={DEBT_TYPES}
        selected={selectedAccount}
        setSelected={setSelectedAccount}
        onClose={() => {
          setDialogOpen(false);
        }}
      />
    </Card>
  );
};

export default DebtSummary;

const useStyles = makeStyles({
  button: {
    width: 200,
    "&>span": {
      width: "100%",
      display: "flex",
      justifyContent: "space-between",
      fontSize: 12,
      fontWeight: 500,
    },
  },
});
