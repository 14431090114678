import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { Finance } from "financejs";
import moment from "moment";

import {
  Box,
  FormControlLabel,
  FormLabel,
  ListSubheader,
  makeStyles,
  MenuItem,
  Select,
} from "@material-ui/core";

import Button from "src/components/Button";
import Checkbox from "src/components/Checkbox";
import ConfirmDialog from "src/components/Dialogs/ConfirmDialog";
import CustomDialog from "src/components/Dialogs/CustomDialog";
import TextField from "src/components/TextField";
import FedLoansForm from "./FedLoansForm";
import InvestmentAllocations from "./InvestmentAllocations";

import { addAccountApi } from "src/apiService";
import {
  Account,
  ASSET_TYPES,
  DEBT_TYPES,
  FbAction,
  INVESTMENT_TYPES,
  isInvestmentType,
  MappedAccount,
  REPAYMENT_PLANS,
  YesNo,
} from "src/interfaces";
import {
  getAccountsLoaded,
  getAccountsLoading,
  getFormattedAssetsAndDebts,
  getLinkedAccounts,
} from "src/store/account/selector";
import {
  addAccount,
  addAccountSuccess,
  getAccounts,
  removeAccount,
  updateAccount,
  UpdateAccountPayload,
} from "src/store/account/actions";
import { PROFILE_TYPE } from "src/store/profileBuild/constants";
import {
  getDebts,
  getSpouseDebts,
  getStudentStatus,
  getSpouseStudentStatus,
  getSpouseInSchool,
  getUserInSchool,
} from "src/store/profileBuild/selector";
import { getIsMarried } from "src/store/system/selector";
import { updateDebts } from "src/store/profileBuild/actions";
import { getAccountApi, removeLinkedAccountApi } from "src/apiService";
import { DollarTextField, PercentTextField } from "src/utils";

const finance = new Finance();

interface FormValues extends Account {
  is_included?: YesNo;
  fed_repayment_plan?: string;
  months?: number;
  years?: number;
  fed_loans?: MappedAccount[];
  manual?: {
    balance: boolean;
    payment: boolean;
    rate: boolean;
  };
  toRemove?: number[];
}

interface IProps {
  open: boolean;
  onClose: any;
  isLinked: boolean;
  selected?: Account;
  setSelected: (account: any) => void;
  accountTypes?: any;
  closeOnSave?: boolean;
}

const initialValues: FormValues = {
  id: 0,
  type: "cash_value",
  firm: "",
  name: "",
  who: PROFILE_TYPE.APPLICANT,
  balance: 0,
  balance_live: 0,
  rate: 0,
  payment: 0,
  carrying: "y",
  "asset-balance": 0,
  is_included: "n",
  months: 1,
  years: 0,
  fed_loans: [],
  fed_repayment_plan: Object.keys(REPAYMENT_PLANS)[0],
};

const isRawAssetType = (type: string) => {
  return ["auto_value", "home_value", "property_value"].indexOf(type) >= 0;
};

const AddEditManualAccountDialog = ({
  open,
  onClose,
  isLinked,
  selected,
  setSelected,
  closeOnSave,
  accountTypes = { ...ASSET_TYPES, ...DEBT_TYPES },
}: IProps) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { debts }: { debts: MappedAccount[] } = useSelector(
    getFormattedAssetsAndDebts
  );
  const loading = useSelector(getAccountsLoading);
  const loaded = useSelector(getAccountsLoaded);
  const isMarried = useSelector(getIsMarried);
  const myProfileDebts = useSelector(getDebts);
  const spouseProfileDebts = useSelector(getSpouseDebts);
  const myMonthsSinceGraduation = useSelector(getStudentStatus);
  const spouseMonthsSinceGraduation = useSelector(getSpouseStudentStatus);
  const userInSchool = useSelector(getUserInSchool);
  const spouseInSchool = useSelector(getSpouseInSchool);

  const [formValues, setFormValues] = useState<FormValues>(initialValues);
  const [
    deleteConfirmDialogVisible,
    setDeleteConfirmDialogVisible,
  ] = useState<boolean>(false);
  const [step, setStep] = useState(0);
  const showAllocations = !!selected && isInvestmentType(formValues.type || "");

  const close = () => {
    onClose();
    setFormValues(initialValues);
    setDeleteConfirmDialogVisible(false);
    setStep(0);
  };

  const myFedLoans = (debts || []).filter(
    (account) =>
      account.variable === "fed_loan" &&
      (account.whose || account.who) !== PROFILE_TYPE.SPOUSE
  );
  const spouseFedLoans = (debts || []).filter(
    (account) =>
      account.variable === "fed_loan" &&
      (account.whose || account.who) === PROFILE_TYPE.SPOUSE
  );
  const linkedAccounts = useSelector(getLinkedAccounts);

  const isLinkedAccount = (id: number) => {
    return linkedAccounts.findIndex((e) => e.id === id) > -1;
  };

  const matchedLinkedAccount =
    selected && isLinkedAccount(selected.id)
      ? linkedAccounts.find((item) => item.id === selected.id)
      : null;

  const isAssetBacked = (type?: string) => {
    return (
      type === "auto_loan" || type === "home_loan" || type === "property_loan"
    );
  };

  const isDebt = (type?: string) => {
    if (type) {
      return !!DEBT_TYPES[type];
    }
    return false;
  };

  const getAccountDetails = async (newSelected: any) => {
    if (newSelected) {
      const isSpouse =
        (newSelected.who || newSelected.whose) === PROFILE_TYPE.SPOUSE;
      const fedLoans = isSpouse ? spouseFedLoans : myFedLoans;
      const plan = isSpouse
        ? spouseProfileDebts.fed_repayment_plan
        : myProfileDebts.fed_repayment_plan;
      let months = moment().diff(
        moment(
          isSpouse
            ? spouseProfileDebts.fed_repayment_start
            : myProfileDebts.fed_repayment_start
        ),
        "months"
      );
      const years = Math.floor(months / 12);
      months = months % 12;

      const data: FormValues = await getAccountApi(newSelected.id);
      let newFormValues: any = {
        ...newSelected,
        carrying: data.carrying ? "y" : "n",
        type: newSelected.variable,
        months,
        years,
        fed_repayment_plan: plan,
        who: isSpouse ? PROFILE_TYPE.SPOUSE : PROFILE_TYPE.APPLICANT,
        fed_loans: fedLoans,
        manual: data.manual,
        payment: data.payment,
        rate: data.rate,
        balance: data.balance,
        balance_live: data.balance_live,
      };
      let promise = Promise.resolve();
      if (newSelected.variable === "fed_loan") {
        const promises = fedLoans.map((loan) => getAccountApi(loan.id));
        promise = Promise.all(promises).then((loanDetails: Account[]) => {
          let rateNumerator = 0;
          let rateDenominator = 0;
          let totalPayment = 0;
          loanDetails.forEach((loan: Account) => {
            rateNumerator += loan.rate || 0;
            rateDenominator += loan.balance || 0;
            totalPayment += loan.payment || 0;
          });
          newFormValues = {
            ...newFormValues,
            ...loanDetails[0],
            payment: totalPayment,
            rate: rateDenominator ? rateNumerator / rateDenominator : 0,
            fed_repayment_plan: plan,
            years,
            months,
            fed_loans: loanDetails.map((loan) => ({
              ...loan,
              annual: loan.payment * 12,
              monthly: loan.payment,
              variableLabel: "Federal Student Loan",
              variable: "fed_loan",
            })),
          };
        });
      }
      promise.then(() => setFormValues(newFormValues));
    } else {
      setFormValues(initialValues);
    }
  };

  useEffect(() => {
    if (open) {
      getAccountDetails(selected);
    }
  }, [open]);

  useEffect(() => {
    if (formValues.type === "fed_loan") {
      const isSpouse = formValues.who === PROFILE_TYPE.SPOUSE;
      const fedLoans = isSpouse ? spouseFedLoans : myFedLoans;
      let months = moment().diff(
        moment(
          isSpouse
            ? spouseProfileDebts.fed_repayment_start
            : myProfileDebts.fed_repayment_start
        ),
        "months"
      );
      const years = Math.floor(months / 12);
      months = months % 12;
      const plan = isSpouse
        ? spouseProfileDebts.fed_repayment_plan
        : myProfileDebts.fed_repayment_plan;
      if (fedLoans && fedLoans.length) {
        Promise.all(fedLoans.map((loan) => getAccountApi(loan.id))).then(
          (loanDetails: Account[]) => {
            let rateNumerator = 0;
            let rateDenominator = 0;
            let totalPayment = 0;
            loanDetails.forEach((loan: Account) => {
              rateNumerator += loan.rate || 0;
              rateDenominator += loan.balance || 0;
              totalPayment += loan.payment || 0;
            });
            const newFormValues = {
              ...formValues,
              ...loanDetails[0],
              payment: totalPayment,
              rate: rateDenominator ? rateNumerator / rateDenominator : 0,
              fed_repayment_plan: plan,
              years,
              months,
              fed_loans: loanDetails.map((loan) => ({
                ...loan,
                annual: loan.payment * 12,
                monthly: loan.payment,
                variableLabel: "Federal Student Loan",
                variable: "fed_loan",
              })),
            };
            setFormValues(newFormValues);
          }
        );
      } else {
        setFormValues({
          ...formValues,
          fed_repayment_plan: plan,
          years,
          months,
          fed_loans: [],
        });
      }
    }
  }, [formValues.who, formValues.type]);

  useEffect(() => {
    if (!loading && loaded && open) {
      // onClose();
    }
  }, [loading, loaded]);

  const handleChange = (prop: keyof FormValues) => (
    event: React.ChangeEvent<any>
  ) => {
    let value = event.target.value;
    const carrying = formValues.carrying;
    let newFormValues = { ...formValues, [prop]: value };
    if (prop === "type") {
      if (!selected) {
        newFormValues = { ...initialValues, type: value };
      }
      if (value === "credit_card") {
        newFormValues.carrying = "y";
      }
    } else if (
      new Set([
        "balance",
        "balance_live",
        "payment",
        "asset-balance",
        "rate",
      ]).has(prop)
    ) {
      value = Math.abs(value);
    } else if (prop === "carrying") {
      if (carrying === "y") {
        newFormValues.carrying = "n";
      } else {
        newFormValues.carrying = "y";
      }
    }

    setFormValues(newFormValues);
  };

  const handleSave = () => {
    if (formValues.type === "fed_loan") {
      const isSpouse = formValues.who === PROFILE_TYPE.SPOUSE;
      const fedLoans = isSpouse ? spouseFedLoans : myFedLoans;
      const actions: Array<FbAction<any>> = [];
      const newFedLoans: Array<Partial<MappedAccount>> =
        formValues.fed_loans || [];
      const totalBalance = newFedLoans.reduce(
        (result, loan) => result + (loan.balance || 0),
        0
      );
      const totalPayment = formValues.payment;
      let workingPayment = totalPayment;
      let workingBalance = totalBalance;
      newFedLoans.forEach((loan) => {
        if (loan.id && !loan?.manual?.payment) {
          workingPayment -= loan.monthly || 0;
          workingBalance -= loan.balance || 0;
        }
      });
      workingBalance = Math.max(workingBalance, 0);
      workingPayment = Math.max(workingPayment, 0);
      if (workingBalance) {
        newFedLoans.forEach((loan) => {
          if (!loan.id || loan?.manual?.payment) {
            loan.monthly =
              (workingPayment / workingBalance) * (loan.balance || 0);
          }
        });
      }
      const accountsAdd: Array<Partial<Account>> = newFedLoans
        .filter((a) => !a.id && a.balance)
        .map((a) => {
          const result = {
            type: "fed_loan",
            firm: formValues.firm,
            payment: a.monthly || 0,
            balance: a.balance || 0,
            rate: a.rate || 0,
            who: (formValues.who === "spouse" ? "spouse" : "applicant") as
              | "spouse"
              | "applicant",
          };
          if (
            (formValues.who === "spouse" && !spouseInSchool) ||
            (formValues.who !== "spouse" && !userInSchool)
          ) {
            result.payment = finance.PMT(
              (a.rate || 0) / 100 / 12,
              120,
              -(a.balance || 0)
            );
          }
          return result;
        });
      accountsAdd.forEach((input) =>
        actions.push(
          addAccount({
            account: input,
          })
        )
      );
      // delete or update
      const accountsUpdate: Array<Partial<Account> | null> = fedLoans
        .map((oldLoan) => {
          const loan = newFedLoans.find((newLoan) => newLoan.id === oldLoan.id);
          if (!loan) {
            removeLinkedAccountApi({ id: oldLoan.id }).then(() =>
              dispatch(removeAccount(oldLoan.id))
            );
            return null;
          }
          const result: Partial<Account> = {
            id: oldLoan.id,
          };
          if (
            loan?.manual?.payment &&
            ((formValues.who === "spouse" && !spouseInSchool) ||
              (formValues.who !== "spouse" && !userInSchool))
          ) {
            result.payment = loan.monthly;
          }
          if (loan?.manual?.balance) {
            result.balance = loan.balance;
          }
          if (loan?.manual?.rate) {
            result.rate = loan.rate;
          }
          return result;
        })
        .filter((loan) => loan && Object.keys(loan as any).length > 1);
      accountsUpdate.forEach((input) => {
        if (input) {
          actions.push(
            updateAccount({
              id: input.id || 0,
              update: input,
            })
          );
        }
      });
      // update profile
      const months =
        (formValues.years || 0) * 12 + (formValues.months || 0) * 1;
      const start = moment()
        .subtract(months, "months")
        .endOf("month")
        .format("YYYY-MM");
      actions.push(
        updateDebts({
          update: {
            ...(isSpouse ? spouseProfileDebts : myProfileDebts),
            fed_repayment_plan: formValues.fed_repayment_plan,
            fed_repayment_start: start,
          },
          who: isSpouse ? PROFILE_TYPE.SPOUSE : PROFILE_TYPE.APPLICANT,
        })
      );
      actions.forEach(dispatch);
      if (closeOnSave) {
        close();
      }
    } else if (selected) {
      const accountInput: Partial<Account> = {
        firm: formValues.firm,
        name: formValues.name,
      };
      if (!isLinked || selected?.manual?.rate) {
        accountInput.rate = formValues.rate;
      }
      if (!isLinked || selected?.manual?.payment) {
        accountInput.payment = formValues.payment;
      }
      if (!isLinked || selected?.manual?.balance) {
        if (selected.variable === "credit_card") {
          accountInput.balance_live = formValues.balance_live;
        } else {
          accountInput.balance = formValues.balance;
        }
      }
      if (selected.variable === "credit_card") {
        accountInput.carrying = formValues.carrying === "y" ? "y" : "n";
      }
      const payload: UpdateAccountPayload = {
        id: selected.id,
        update: accountInput,
      };
      dispatch(updateAccount(payload));
      if (isInvestmentType(formValues.type || "") && step === 0) {
        setStep(1);
      } else if (closeOnSave) {
        close();
      }
    } else {
      if (isInvestmentType(formValues.type || "")) {
        const newAccount: any = {
          firm: formValues.firm,
          name: formValues.name,
          type: formValues.type,
          balance: formValues.balance,
          rate: formValues.rate,
          payment: formValues.payment,
          who: formValues.who === "spouse" ? "spouse" : "applicant",
        };
        addAccountApi(newAccount).then((addAccountResult) => {
          dispatch(addAccountSuccess(addAccountResult));
          setSelected(addAccountResult);
          getAccountDetails(addAccountResult);
          setStep(1);
        });
      } else {
        const accountInputs: Array<Partial<Account>> = [];
        const actions: Array<FbAction<any>> = [];

        let generalInput: any = {};
        switch (formValues.type) {
          case "auto_loan":
            accountInputs.push({
              type: "auto_loan",
              firm: formValues.firm,
              name: formValues.name,
              balance: formValues.balance,
              payment: formValues.payment,
              rate: formValues.rate,
              who: formValues.who === "spouse" ? "spouse" : "applicant",
            });
            if (formValues.is_included !== "y" && formValues["asset-balance"]) {
              accountInputs.push({
                type: "auto_value",
                firm: formValues.firm,
                name: formValues.name,
                balance: formValues["asset-balance"],
                who: formValues.who === "spouse" ? "spouse" : "applicant",
              });
            }
            break;
          case "home_loan":
            accountInputs.push({
              type: "home_loan",
              firm: formValues.firm,
              name: formValues.name,
              balance: formValues.balance,
              payment: formValues.payment,
              rate: formValues.rate,
              who: formValues.who === "spouse" ? "spouse" : "applicant",
            });
            if (formValues.is_included !== "y" && formValues["asset-balance"]) {
              accountInputs.push({
                type: "home_value",
                firm: formValues.firm,
                name: formValues.name,
                balance: formValues["asset-balance"],
                who: formValues.who === "spouse" ? "spouse" : "applicant",
              });
            }
            break;
          case "property_loan":
            accountInputs.push({
              type: "property_loan",
              firm: formValues.firm,
              name: formValues.name,
              balance: formValues.balance,
              payment: formValues.payment,
              rate: formValues.rate,
              who: formValues.who === "spouse" ? "spouse" : "applicant",
            });
            if (formValues.is_included !== "y" && formValues["asset-balance"]) {
              accountInputs.push({
                type: "property_value",
                firm: formValues.firm,
                name: formValues.name,
                balance: formValues["asset-balance"],
                who: formValues.who === "spouse" ? "spouse" : "applicant",
              });
            }
            break;
          default:
            generalInput = {
              firm: formValues.firm,
              name: formValues.name,
              type: formValues.type,
              balance: formValues.balance,
              rate: formValues.rate,
              payment: formValues.payment,
              who: formValues.who === "spouse" ? "spouse" : "applicant",
            };
            if (formValues.type === "credit_card") {
              generalInput.carrying = formValues.carrying;
              generalInput.balance_live =
                formValues.balance_live || generalInput.balance;
              generalInput.balance =
                formValues.balance_live || generalInput.balance;
            }
            accountInputs.push(generalInput);
        }
        accountInputs.forEach((input) =>
          actions.push(
            addAccount({
              account: input,
            })
          )
        );
        actions.forEach(dispatch);
        if (closeOnSave) {
          close();
        }
      }
    }
  };

  const handleDelete = () => {
    if (formValues.type === "fed_loan") {
      const fedLoans =
        formValues.who === PROFILE_TYPE.SPOUSE ? spouseFedLoans : myFedLoans;
      return Promise.all(
        fedLoans.map((account) => removeLinkedAccountApi({ id: account.id }))
      ).then(() => {
        dispatch(getAccounts(true));
        setDeleteConfirmDialogVisible(false);
        close();
      });
    }
    if (selected) {
      setDeleteConfirmDialogVisible(false);
      removeLinkedAccountApi({ id: selected.id }).then(() => {
        dispatch(removeAccount(selected.id));
        close();
      });
    }
  };

  const isSpouse = formValues.who === PROFILE_TYPE.SPOUSE;
  const monthsSinceGraduation = isSpouse
    ? spouseMonthsSinceGraduation
    : myMonthsSinceGraduation;

  const renderAccountTypeList = () => {
    if (
      accountTypes === ASSET_TYPES ||
      accountTypes === DEBT_TYPES ||
      accountTypes === INVESTMENT_TYPES
    ) {
      return Object.keys(accountTypes).map((type) => (
        <MenuItem value={type} key={type}>
          {accountTypes[type]}
        </MenuItem>
      ));
    }
    return [
      <ListSubheader key="assets">Assets</ListSubheader>,
      ...Object.keys(ASSET_TYPES).map((type) => (
        <MenuItem value={type} key={type}>
          {ASSET_TYPES[type]}
        </MenuItem>
      )),
      <ListSubheader key="debts">Debts</ListSubheader>,
      ...Object.keys(DEBT_TYPES).map((type) => (
        <MenuItem value={type} key={type}>
          {DEBT_TYPES[type]}
        </MenuItem>
      )),
    ];
  };

  return (
    <>
      <CustomDialog
        title={
          step === 1
            ? "Edit Holdings"
            : selected
            ? "Edit Account"
            : "Add Manual Account"
        }
        size="sm"
        isOpen={open}
        onClose={(event: any, reason: string) => {
          if (reason !== "backdropClick") {
            close();
          }
        }}
      >
        <Box className={classes.container}>
          {step === 0 && (
            <>
              <FormLabel component="legend" className="mb-2 mt-4">
                Account Type
              </FormLabel>
              <Select
                variant="outlined"
                fullWidth
                value={formValues.type}
                disabled={loading || !!selected}
                onChange={handleChange("type")}
              >
                {renderAccountTypeList()}
              </Select>
              {isMarried && (
                <>
                  <FormLabel component="legend" className="mb-2 mt-4">
                    Owner
                  </FormLabel>
                  <Select
                    variant="outlined"
                    fullWidth
                    value={formValues.who}
                    disabled={loading || !!selected}
                    onChange={handleChange("who")}
                  >
                    <MenuItem
                      value={PROFILE_TYPE.APPLICANT}
                      key={PROFILE_TYPE.APPLICANT}
                    >
                      Mine
                    </MenuItem>
                    <MenuItem
                      value={PROFILE_TYPE.SPOUSE}
                      key={PROFILE_TYPE.SPOUSE}
                    >
                      Spouse
                    </MenuItem>
                  </Select>
                </>
              )}
              {formValues.type === "fed_loan" ? (
                <FedLoansForm
                  formValues={formValues}
                  setFormValues={setFormValues}
                  monthsSinceGraduation={monthsSinceGraduation}
                  loading={loading}
                  onChange={handleChange}
                  isLinked={isLinked}
                  selected={selected}
                  isLinkedAccount={isLinkedAccount}
                  myFedLoans={myFedLoans}
                  spouseFedLoans={spouseFedLoans}
                />
              ) : (
                <>
                  {!isRawAssetType(formValues.type || "") && (
                    <>
                      <FormLabel component="legend" className="mb-2 mt-4">
                        Financial Institution
                      </FormLabel>
                      <TextField
                        className="mb-0"
                        variant="outlined"
                        onChange={handleChange("firm")}
                        value={formValues.firm}
                        fullWidth
                      />
                    </>
                  )}
                  <FormLabel component="legend" className="mb-2 mt-4">
                    Account Name
                  </FormLabel>
                  <TextField
                    className="mb-0"
                    variant="outlined"
                    onChange={handleChange("name")}
                    value={formValues.name}
                    fullWidth
                  />
                  <FormLabel component="legend" className="mb-2 mt-4">
                    Balance
                  </FormLabel>
                  <DollarTextField
                    variant="outlined"
                    fullWidth
                    id="balance_input"
                    disabled={isLinked && !formValues?.manual?.balance}
                    onChange={handleChange(
                      formValues.type === "credit_card"
                        ? "balance_live"
                        : "balance"
                    )}
                    value={
                      formValues.type === "credit_card"
                        ? formValues.balance_live
                        : formValues.balance
                    }
                  />
                  {formValues.type === "credit_card" && (
                    <FormControlLabel
                      className="mt-4"
                      control={
                        <Checkbox
                          checked={formValues.carrying === "n"}
                          onChange={handleChange("carrying")}
                        />
                      }
                      label="I pay this off monthly"
                    />
                  )}
                  {isDebt(formValues.type) && (
                    <>
                      <FormLabel component="legend" className="mb-2 mt-4">
                        Interest Rate
                      </FormLabel>
                      <PercentTextField
                        variant="outlined"
                        onChange={handleChange("rate")}
                        value={formValues.rate}
                        disabled={isLinked && !formValues?.manual?.rate}
                        fullWidth
                      />
                      <FormLabel component="legend" className="mb-2 mt-4">
                        Monthly Payment
                      </FormLabel>
                      <DollarTextField
                        variant="outlined"
                        fullWidth
                        id="payment_input"
                        label=""
                        onChange={handleChange("payment")}
                        disabled={isLinked && !formValues?.manual?.payment}
                        value={formValues.payment}
                      />
                    </>
                  )}
                  {!selected && isAssetBacked(formValues.type) && (
                    <>
                      <FormLabel component="legend" className="mb-2 mt-4">
                        Estimated Market Value of Property
                      </FormLabel>
                      <DollarTextField
                        variant="outlined"
                        disabled={formValues.is_included === "y"}
                        fullWidth
                        id="est_market_value_input"
                        label=""
                        onChange={handleChange("asset-balance")}
                        value={formValues["asset-balance"]}
                      />
                      <FormControlLabel
                        className="mb-2 mt-4"
                        control={
                          <Checkbox
                            value={formValues.is_included === "y" ? "n" : "y"}
                            checked={formValues.is_included === "y"}
                            onChange={handleChange("is_included")}
                          />
                        }
                        label="I have already included this asset value in my profile"
                      />
                    </>
                  )}
                </>
              )}
            </>
          )}
          {showAllocations && step === 1 && (
            <InvestmentAllocations
              account={selected}
              linkedAccount={matchedLinkedAccount}
              setAccountBalance={(value: number) =>
                setFormValues((current) => ({ ...current, balance: value }))
              }
            />
          )}
          {step === 0 && (
            <Box className="flex justify-between mt-6">
              <Box>
                {!!selected && selected.variable !== "fed_loan" && (
                  <Button
                    fbColor="accent"
                    onClick={() => setDeleteConfirmDialogVisible(true)}
                  >
                    Delete
                  </Button>
                )}
              </Box>
              <Box className={classes.buttons}>
                <Button
                  className="mr-4"
                  fbColor="secondary"
                  onClick={() => onClose()}
                >
                  Cancel
                </Button>
                <Button
                  fbColor="primary"
                  onClick={handleSave}
                  disabled={loading}
                >
                  Save
                </Button>
              </Box>
            </Box>
          )}
          {step === 1 && (
            <Box className="flex justify-end mt-6">
              <Button
                className="mr-4"
                fbColor="secondary"
                onClick={() => onClose()}
              >
                Cancel
              </Button>
              <Button fbColor="primary" onClick={handleSave} disabled={loading}>
                Save
              </Button>
            </Box>
          )}
        </Box>
      </CustomDialog>
      <ConfirmDialog
        visible={deleteConfirmDialogVisible}
        title="Delete Account"
        message="Are you sure you want to delete the account?"
        onCancel={() => setDeleteConfirmDialogVisible(false)}
        onConfirm={handleDelete}
        cancelButton="No"
        confirmButton="Yes"
      />
    </>
  );
};

const useStyles = makeStyles({
  container: {
    padding: "20px 6px",
  },
  footer: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  buttons: {
    display: "flex",
    alignItems: "center",
  },
});

export default AddEditManualAccountDialog;
