import * as React from "react";
import { useDispatch } from "react-redux";
import { push } from "connected-react-router";
import { Route } from "react-router";
import { Switch, useRouteMatch } from "react-router-dom";
import { makeStyles } from "@material-ui/core";

import Button from "src/components/Button";
import DashboardLayout from "src/layouts/DashboardLayout";
import InvestmentsLayout from "src/layouts/InvestmentsLayout";
import { AdapterLink } from "src/utils";

import { Content } from "./Content";
import InvestmentRecommendations from "./InvestmentRecommendations";
import PayoffAnalysis from "./PayoffAnalysis";
import PayoffVsIDR from "./PayoffVsIDR";
import Refinance from "./Refinance";

const useStyles = makeStyles({
  mainContent: {
    flex: 1,
    height: "100%",
    padding: "10px 30px",
    backgroundColor: "transparent",
    position: "relative",
  },
  toolContainer: {
    marginTop: 100,
    padding: "0 20px",
  },
});

const Tools = () => {
  const dispatch = useDispatch();
  const { path } = useRouteMatch();
  const classes = useStyles();

  const backToTools = () => {
    dispatch(push("/investments"));
  };

  return (
    <Switch>
      <Route exact path={path}>
        <DashboardLayout pageTitle="Tools">
          <main className={classes.mainContent}>
            <Content />
          </main>
        </DashboardLayout>
      </Route>
      <Route path={`${path}/payoffAnalysis`}>
        <InvestmentsLayout
          headerText="Student Repayment Analysis"
          goBackTarget="/studentloans"
        >
          <main className={classes.toolContainer}>
            <PayoffAnalysis />
          </main>
        </InvestmentsLayout>
      </Route>
      <Route path={`${path}/payoffVsIDR`}>
        <InvestmentsLayout
          headerText="Should I Consider An Income-Driven Repayment Plan?"
          goBackTarget="/studentloans"
        >
          <main className={classes.toolContainer}>
            <PayoffVsIDR />
          </main>
        </InvestmentsLayout>
      </Route>
      <Route path={`${path}/refinance`}>
        <InvestmentsLayout
          headerText="Should I Refinance My Student Loans?"
          goBackTarget="/studentloans"
          headerContent={
            <Button
              component={AdapterLink}
              fbColor="secondary"
              to="/solutions?solution=refinance"
            >
              Check My Rates
            </Button>
          }
        >
          <main className={classes.toolContainer}>
            <Refinance />
          </main>
        </InvestmentsLayout>
      </Route>
      <Route path={`${path}/investment`}>
        <InvestmentsLayout
          headerText="Investment Recommendations"
          goBack={backToTools}
        >
          <main className={classes.toolContainer}>
            <InvestmentRecommendations />
          </main>
        </InvestmentsLayout>
      </Route>
    </Switch>
  );
};

export default Tools;
