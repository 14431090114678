import React from "react";
// import { useSelector } from "react-redux";

import {
  AppBar,
  Grid,
  IconButton,
  makeStyles,
  Toolbar,
  Typography,
} from "@material-ui/core";

import { headerStyles } from "src/theme";
import { AdapterLink } from "src/utils";
import Icon from "src/components/Icon";

interface CalculatorHeaderProps {
  headerTitle: string;
  goBack(): void;
}

const CalculatorHeader: React.FC<CalculatorHeaderProps> = ({
  headerTitle,
  goBack,
}: CalculatorHeaderProps) => {
  const classes = useStyles();
  return (
    <AppBar elevation={0} position="relative" className={classes.planBuilder}>
      <Toolbar>
        <Grid container spacing={3} className="pt-4 px-2">
          <Grid item xs={9} className="flex justify-between">
            <Typography component="h1" variant="h2" className="text-lg">
              {headerTitle}
            </Typography>
          </Grid>
          <Grid item xs={3}>
            <IconButton
              color="inherit"
              component={AdapterLink}
              className="absolute right-2"
              onClick={goBack}
            >
              <Icon iconName="fb-close" className="text-4xl" />
            </IconButton>
          </Grid>
        </Grid>
      </Toolbar>
    </AppBar>
  );
};

const useStyles = makeStyles(headerStyles);

export default CalculatorHeader;
